<template>
  <Filters
      :count="Object.values(filter).filter(i => i).length"
      @cancel="cancel"
      @apply="apply"
      @reset="reset"
  >
    <div class="h-row">
      <FieldDate :max="form.end" v-model="form.start" :label="$t('common.dateFrom')" />
      <span class="ml-1 mr-1">-</span>
      <FieldDate :min="form.start" v-model="form.end" :label="$t('common.dateTo')" />
    </div>
    <div class="h-row mt-2">
      <div class="f-helper" @click="setToday">{{$t('callog.filter1')}}</div>
      <div class="f-helper" @click="setYesterday">{{$t('callog.filter2')}}</div>
      <div class="f-helper" @click="setLastWeek">{{$t('callog.filter3')}}</div>
      <div class="f-helper" @click="setLastMonth">{{$t('callog.filter4')}}</div>
    </div>
  </Filters>
</template>

<script>

import Filters from '@/components/shared/Filters'
import FieldDate from '@/components/shared/FieldDate'
import cloneDeep from 'clone-deep'
import { formatDate } from '@/lib/utils'
import { subDays, addDays, subMonths } from 'date-fns'

export default {
  name: 'CallLogFilters',

  components: {
    FieldDate,
    Filters,
  },

  data() {
    return {
      filter: {
        start: '',
        end: '',
      },
      form: {
        start: '',
        end: '',
      },
    }
  },

  methods: {
    setToday() {
      const today = new Date()
      this.form.start = formatDate(today)
      this.form.end = formatDate(today)
    },

    setYesterday() {
      const yesterday = subDays(new Date(), 1)
      this.form.start = formatDate(yesterday)
      this.form.end = formatDate(yesterday)
    },

    setLastWeek() {
      const today = new Date()
      this.form.start = formatDate(subDays(today, 6))
      this.form.end = formatDate(today)
    },

    setLastMonth() {
      const today = new Date()
      this.form.start = formatDate(addDays(subMonths(today, 1), 1))
      this.form.end = formatDate(today)
    },

    reset() {
      this.form.start = ''
      this.form.end = ''
      this.filter = cloneDeep(this.form)
      this.$emit('change', this.filter)
    },

    cancel() {
      this.form = cloneDeep(this.filter)
    },

    apply() {
      this.filter = cloneDeep(this.form)
      this.$emit('change', this.filter)
    },
  },
}
</script>

<style lang="sass" scoped>
.h-row
  display: flex
  gap: 10px
  justify-content: space-between

.f-helper
  cursor: pointer
  border-radius: 4px
  padding: 8px 16px
  line-height: 1
  font-size: 0.8rem
  background: #eee
  transition: all 0.2s

  &:hover
    background: var(--v-primary-base)
    color: #fff
</style>

<template>
  <div>
    <v-data-table
        class="root row-hover"
        :item-key="'id'"
        :headers="headers"
        :items="state.scenarios.list.items"
        :options.sync="state.scenarios.list.options"
        :server-items-length="state.scenarios.list.total"
        :loading="state.scenarios.list.isLoading"
        :no-data-text="$t('common.empty')"
        :page.sync="state.scenarios.list.options.page"
        hide-default-footer
    >
      <!-- @click:row="($event, data) => !data.item.account_id && onEdit(data.item)" -->
      <template v-slot:[`item.actions`]="{ item }">
        <div class="list-actions">
          <AppListingAction
            v-if="!item.fax && !item.transfer"
            :title="$t('scenarios.editPlan')"
            @click="onEdit(item)"
          >
            {{icons.mdiPencil}}
          </AppListingAction>
          <AppListingAction
            v-if="!isReadOnly(item)"
            :title="$t('scenarios.deletePlan')"
            @click="onDelete(item)"
          >
            {{icons.mdiDelete}}
          </AppListingAction>
        </div>
      </template>
    </v-data-table>

    <v-pagination
      v-model="state.scenarios.list.options.page"
      @input="loadItems"
      :length="state.scenarios.list.totalPages"
      :total-visible="7"
    />

    <AppDialog ref="dialog">
      {{$t('scenarios.dialogDeleteText')}} {{deleteItem.name}}?
      <div v-if="reassignNumbers.length" class="mt-3">
        {{$t('scenarios.numbersReassign')}}:
        <div v-for="number in reassignNumbers" :key="number.number"><b>{{number.label}}</b></div>
      </div>
    </AppDialog>
  </div>
</template>

<script>

import { mdiPencil, mdiDelete } from '@mdi/js'
import { state } from '@/store/state'
import { appService } from '@/services/AppService'
import { scenarioService } from '@/services/ScenarioService'
import { PATH_PAGE_SCENARIOS } from '@/config/paths'
import { create } from '@/models/Scenario'
import { hasAccess } from '@/lib/access'

export default {
  name: 'ViewScenariosList',

  components: {
  },

  computed: {
    reassignNumbers() {
      return this.deleteItem.id
          ? this.state.dids.refs.filter(i => i.scenario_id === this.deleteItem.id)
          : []
    },
  },

  data() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
      state,
      headers: [
        {
          text: this.$t('common.name'),
          align: 'start',
          value: 'name',
          sortable: true,
        },
        {
          text: this.$t('common.description'),
          value: 'description',
          sortable: false,
        },
        {
          text: '',
          align: 'end',
          value: 'actions',
          sortable: false,
        },
      ],
      deleteItem: create(),
    }
  },

  mounted() {
    this.loadItems().catch(window.logger.error)
  },

  methods: {
    hasAccess,
    async loadItems(page) {
      await scenarioService.loadItems(page)
    },

    isReadOnly(item) {
      return item.id === state.company.default_scenario_id || item.system || !hasAccess('admin')
    },

    onEdit(item) {
      this.$router.history.push(`${PATH_PAGE_SCENARIOS}/${item.id}`)
    },

    async onDelete(item) {
      this.deleteItem = item
      const dialogSettings = {
        title: this.$t('scenarios.dialogDeleteTitle'),
        actionText: this.$t('common.delete'),
      }

      if (await this.$refs.dialog.show(dialogSettings)) {
        try {
          const { error } = await scenarioService.remove(item.id)
          if (!error) {
            appService.showSuccess()
            await this.loadItems()
          } else {
            appService.showError()
          }
        } catch (e) {
          window.logger.error(e)
          appService.showError()
        }
      }
    },
  },
}
</script>

<style lang="sass" scoped>
</style>

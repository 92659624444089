<template>
  <div class="root">
    <div class="blocks">
      <StatsBlock :label="$t('billing.statsAccounts')" :stat="stats.accounts" />
      <StatsBlock :label="$t('billing.statsDids')" :stat="stats.dids" />
      <StatsBlock :label="$t('billing.statsMinutes')" :stat="stats.minutes" />
      <StatsBlock :label="$t('billing.statsMinutesTollFree')" :stat="stats.minutes_toll_free" />
    </div>
  </div>
</template>

<script>

import StatsBlock from '@/components/views/viewHome/dashboard/stats/Block'
import { state } from '@/store/state'

export default {
  name: 'DashboardStats',

  components: {
    StatsBlock,
  },

  computed: {
    stats() { return this.state.billing.stats },
  },

  data() {
    return {
      state,
    }
  },
}
</script>

<style lang="sass" scoped>
.root
  .blocks
    display: flex
    align-items: center
    justify-content: center
</style>

<template>
  <AppLoader v-if="state.dids.detail.isLoading" />
  <AppForm v-else class="root pt-4" @submit="onSubmit">
    <AppFormRow v-if="!did.id">
      <v-select
          :items="originatorOptions"
          outlined
          hide-details
          item-text="label"
          item-value="value"
          :disabled="!!did.id || !hasAccess('admin')"
          :label="$t('dids.originator')"
          :value="did.originator"
          @input="setField('originator', $event)"
      ></v-select>
    </AppFormRow>
    <AppFormRow v-if="did.originator === 'external' || did.id">
      <v-text-field
          outlined
          hide-details
          :type="did.id ? 'text' : 'number'"
          :disabled="!!did.id || !hasAccess('admin')"
          :label="$t(did.originator === 'external' && did.id ? 'dids.sipUrl' : 'dids.number')"
          :error="$v.did.number.$anyError"
          :value="did.id && did.originator === 'external' ? `${did.number}@${domain}` : did.number"
          @input="setField('number', $event)"
      />
    </AppFormRow>
    <AppFormRow v-if="did.originator === 'internal' && !did.id">
      <NumberSelect
          :error="$v.did.number.$anyError"
          :disabled="!hasAccess('admin')"
          :value="did.number"
          @change="setField('number', $event)"
      />
    </AppFormRow>
    <AppFormRow>
      <v-switch
          inset
          hide-details
          class="ma-0 pa-0"
          color="blue"
          :label="$t('dids.dedicated')"
          v-model="dedicated"
          @change="setToggle('dedicated', $event)"
      ></v-switch>
    </AppFormRow>
    <AppFormRow v-if="did.originator === 'internal'">
      <v-switch
          inset
          hide-details
          class="ma-0 pa-0 mb-2"
          color="blue"
          :label="$t('dids.fax')"
          v-model="fax"
          @change="setToggle('fax', $event)"
      ></v-switch>
    </AppFormRow>
    <AppFormRow v-if="!dedicated && !fax">
      <v-select
          :items="scenarioOptions"
          :disabled="!hasAccess('admin')"
          outlined
          hide-details
          item-text="label"
          item-value="value"
          :label="$t('scenarios.selectPlan')"
          :value="did.scenario_id"
          @input="setField('scenario_id', $event)"
      ></v-select>
    </AppFormRow>
    <AppFormRow v-if="(dedicated || fax)">
      <v-select
          :items="userOptions"
          :disabled="!hasAccess('admin')"
          outlined
          hide-details
          item-text="label"
          item-value="value"
          :value="did.account_uid"
          :label="$t('dids.selectUser')"
          @input="setField('account_uid', $event)"
      ></v-select>
    </AppFormRow>

    <AppFormRow v-if="serverError">
      <AppError slot="bottom" :error="serverError" />
    </AppFormRow>

    <AppFormRow actions>
      <AppButton
          class="reset"
          type="gray"
          @click="$router.push(urlList)"
      >
        {{$t('common.cancel')}}
      </AppButton>
      <AppButton
          v-if="hasAccess('admin')"
          submit
          class="ml-2"
          type="primary"
          :disabled="!$v.$anyDirty || $v.$invalid"
          :isLoading="isLoading"
      >
        {{$t(did.id ? 'dids.updateButton' : 'dids.addButton')}}
      </AppButton>
    </AppFormRow>
  </AppForm>
</template>

<script>
import clone from 'clone'
import { state } from '@/store/state'
import { required, numeric } from 'vuelidate/lib/validators'
import { PATH_PAGE_NUMBERS } from '@/config/paths'
import { didService } from '@/services/DidService'
import { appService } from '@/services/AppService'
import { create as createDid } from '@/models/Did'
import { i18n } from '@/config/vue'
import { phone } from '@/lib/validate'
import { hasAccess } from '@/lib/access'
import NumberSelect from '@/components/shared/NumberSelect'

export default {
  name: 'ViewNumbersDetailForm',

  components: {
    NumberSelect,
  },

  computed: {
    urlList: () => PATH_PAGE_NUMBERS,
    domain() { return this.state.company.domain },
    scenarioOptions: function() { return this.state.scenarios.refs },
    userOptions: function () {
      return this.state.users.refs
          .filter(i => i.type === 'regular')
          .map(i => ({ value: i.id, label: `${i.profile.firstName} ${i.profile.lastName}`.trim() || i.email }))
    },
  },

  props: {
    id: { type: String, required: true },
  },

  data() {
    return {
      state,
      serverError: null,
      isLoading: false,
      did: createDid(),
      account_uid: '',
      dedicated: false,
      fax: false,
      originatorOptions: [
        { value: 'internal', label: i18n.t('dids.internal') },
        { value: 'external', label: i18n.t('dids.external') },
      ],
    }
  },

  validations() {
    const validations = {
      did: {
        number: {
        },
        originator: {
          required,
        },
        scenario_id: {
          numeric,
        },
      },
      account_uid: {
      },
      dedicated: {
      },
      fax: {
      },
    }

    if (!this.did.id) {
      validations.did.number.phone = phone
      validations.did.number.required = required
      if (this.dedicated) {
        validations.account_uid.required = required
      } else {
        validations.did.scenario_id.required = required
      }
    }

    return validations
  },

  mounted() {
    this.loadItem().catch(window.logger.error)
  },

  methods: {
    hasAccess,
    async loadItem() {
      this.id === 'new'
          ? await didService.loadItem()
          : await didService.loadItem(this.id)
      this.did = clone(this.state.dids.detail.item)
      this.dedicated = this.did?.transfer
      this.fax = this.did?.fax
    },

    setField(field, value) {
      if (field === 'account_uid') {
        this.account_uid = value
        this.$v.account_uid.$touch()
      } else {
        if (this.did[field] !== value) {
          this.$v.did[field].$touch()
        }
        this.did[field] = value
      }
      this.serverError = null
    },

    setToggle(field, value) {
      this[field] = value
      if (value && !this.account_uid) {
        this.account_uid = this.did?.account_uid
      }
      if (!value && field === 'dedicated') {
        this.account_uid = null
      }
      this.$v.account_uid.$touch()
    },

    async onSubmit() {
      this.$v.$touch()
      if (!this.$v.$anyError) {
        this.isLoading = true
        try {
          if (this.dedicated) {
            this.did.scenario_id = undefined
          } else {
            this.account_uid = undefined
          }
          const { error, did } = await didService.save(this.did, this.account_uid, this.fax)
          if (error || !did) {
            appService.showError(error)
          } else {
            this.did = clone(did)
            const message = this.id === 'new' ? this.$t('dids.numberCreated') : this.$t('dids.numberUpdated')
            appService.showSuccess(message)
            await this.$router.push(this.urlList)
          }
          this.$v && this.$v.$reset()

        } catch (e) {
          window.logger.error(e)
          appService.showError()
        }
        this.isLoading = false
      }
    },
  },

  watch: {
    dedicated: function (value) {
      if (!value) {
        this.fax = value
      }
    },
    fax: function (value) {
      if (value) {
        this.dedicated = value
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.root
  .actions
    display: flex
    justify-content: flex-end

</style>

<template>
  <div>
    <v-switch
        inset
        hide-details
        class="ma-0 pa-0 mb-7 text-no-wrap"
        color="blue"
        :label="$t(tollFreeAvailable ? 'dids.tollFreeNumber' : 'dids.tollFreeNumberNA')"
        :disabled="!tollFreeAvailable"
        v-model="tollFree"
        @change="onToggleTollFree"
    ></v-switch>

    <v-autocomplete
        v-if="!tollFree"
        class="mb-7"
        outlined
        hide-details
        clearable
        :disabled="numbersLoading || disabled"
        :label="$t('dids.area')"
        :items="areas"
        :value="area"
        @change="onAreaChange"
    />

    <v-autocomplete
        v-if="tollFree"
        class="mb-7"
        outlined
        hide-details
        clearable
        :disabled="numbersLoading || disabled"
        :label="$t('dids.tollFreePrefixChoose')"
        :items="prefixes"
        :value="area"
        @change="onAreaChange"
    />

    <v-autocomplete
        outlined
        hide-details
        clearable
        :disabled="numbersLoading || disabled"
        :error="error"
        :loading="numbersLoading"
        :label="numbersLoading ? loadingMessage() : $t('dids.select')"
        :items="numbers"
        :value="value"
        @change="onNumberChange"
    />

    <div v-if="tryCount > 0 && !numbersLoading" class="mt-4 dids-not-found">
      {{$t('dids.notFound')}}
    </div>
  </div>
</template>

<script>
import { request } from '@/lib/Request'
import { didService } from '@/services/DidService'
import { state } from '@/store/state'
import { delay, formatPhoneNumber } from '@/lib/utils'

const TRY_TOTAL = 2
const TRY_DELAY = 3000

export default {
  name: 'NumberSelect',

  components: {
  },

  props: {
    error: Boolean,
    disabled: Boolean,
    value: String,
  },

  computed: {
    tollFreeAvailable() { return this.state.billing.dataplan.cost_did_toll_free > 0 },
  },

  data() {
    return {
      state,
      area: '',
      areas: [],
      numbers: [],
      prefixes: [800, 833, 844, 855, 866, 877, 888],
      tollFree: false,
      tryCount: 0,
      numbersLoading: false,
    }
  },

  beforeMount() {
    this.loadAreaCodes()
  },

  methods: {
    loadAreaCodes() {
      request.get('/res/codes.json').then(({ data }) => {
        const areas = [];
        (data || []).forEach(i => {
          i.cities.forEach(c => {
            areas.push({ value: i.code, text: `${i.code} - ${c}` })
          })
        })
        this.areas = areas
      }).catch(window.logger.error)
    },

    async loadNumbers(count = 1) {
      this.numbers = []
      this.tryCount = count
      this.numbersLoading = true
      const area = this.area || '' // ? this.area.split(' - ')[0] : ''
      try {
        const numbers = await didService.search(this.tollFree, area)
        if (!numbers?.length && this.tryCount < TRY_TOTAL) {
          await delay(TRY_DELAY)
          await this.loadNumbers(this.tryCount + 1)
        } else {
          this.numbers = (numbers || []).map(i => ({ value: i, text: formatPhoneNumber(i) }))
          this.numbersLoading = false
          if (this.numbers.length > 0) {
            this.tryCount = 0
          }
        }
      } catch (e) {
        window.logger.error(e)
        this.numbersLoading = false
      }
      if (this.numbers?.length) {
        this.onNumberChange(this.numbers[0]?.value)
      }
    },

    loadingMessage() {
      return this.$t('dids.searching')
    },

    onAreaChange(area) {
      this.area = area
      this.$emit('change', '')
      this.loadNumbers()
    },

    onNumberChange(number) {
      const match = number && number.match(/^\+\d((\d){3})\d+$/)
      if (match && match[1]) {
        this.area = parseInt(match[1]) || ''
      }
      this.$emit('change', number)
    },

    onToggleTollFree() {
      this.area = ''
      this.$emit('change', '')
      this.loadNumbers()
    },
  },
}
</script>

<style lang="sass" scoped>
.dids-not-found
  color: red
</style>

<template>
  <v-dialog
      class="app-dialog"
      :value="open"
      :max-width="settings.maxWidth"
      @close="onClose"
      @click:outside="onClose"
      @keydown.enter="onAction"
      @keydown.esc="onClose"
  >
    <v-card>
      <v-card-title class="headline">
        {{settings.title}}
      </v-card-title>
      <v-card-text v-html="settings.html">
        {{settings.text}}
        <slot></slot>
      </v-card-text>
      <slot name="content"></slot>
      <v-card-actions class="pr-6 pl-6 pb-4">
        <v-spacer></v-spacer>
        <AppButton
            v-if="settings.showCancel"
            type="gray"
            @click="onClose"
        >
          {{settings.cancelText || $t('common.cancel') }}
        </AppButton>
        <AppButton
          type="primary"
          :isLoading="isLoading"
          @click="onAction"
        >
          {{settings.actionText || $t('common.ok')}}
        </AppButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

const defaultSettings = {
  title: '',
  text: '',
  html: '',
  cancelText: '',
  actionText: '',
  onAction: () => true,
}

export default {
  name: 'AppDialog',

  props: {
    maxWidth: { type: Number, default: 390 },
    title: { type: String },
    text: { type: String },
    html: { type: String },
    cancelText: { type: String },
    actionText: { type: String },
    showCancel: { type: Boolean, default: true },
    isLoading: { type: Boolean, default: false },
  },

  components: {
  },

  data() {
    return {
      open: false,
      settings: {
        maxWidth: this.maxWidth,
        title: this.title,
        text: this.text,
        html: this.html,
        cancelText: this.cancelText,
        showCancel: this.showCancel,
        actionText: this.actionText,
        onAction: () => true,
      },
      resolver: () => {},
    }
  },

  methods: {
    async show(settings) {
      this.settings = { ...this.settings, ...defaultSettings, ...settings }
      this.onOpen()
      return new Promise(r => this.resolver = r)
    },

    onOpen() {
      this.open = true
      this.$emit('open')
    },

    onClose() {
      this.open = false
      this.$emit('close')
    },

    async onAction() {
      if (await this.settings.onAction()) {
        this.onClose()
        this.resolver(true)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.app-dialog
  z-index: 10001
</style>

export const config = {
  apiUrl: window.params?.apiUrl || `${process.env.VUE_APP_BACKEND_URL}/api`,
  env: window.params?.env || 'local',
  sentryDsn: 'https://75ac38d317b842b88883e0be9f2264f2@o185680.ingest.sentry.io/5750426',
  minPasswordLength: 6,
  minBalanceAmount: 20,
  minBalanceTopUp: 20,
  insufficientFundsRemainDays: 3,
  blockly: {
    media: `${window.params?.staticUrl || `${process.env.VUE_APP_BACKEND_URL}/static`}/blockly/media/`,
  },
}

import Vue from 'vue'
import * as billingApi from '@/api/BillingApi'
import { IResponse, Request } from '@/lib/Request'
import { appService } from '@/services/AppService'
import { state } from '@/store/state'
import { deletePaymentMethod, IGetBillingSettings } from '@/api/BillingApi'
import { TPaymentMethod } from '@/models/PaymentMethod'

class BillingService {
  private stripeRequest = new Request()
  private interval: number | null = null
  public stripe: TObject | null = null // external lib
  public state = state

  /**
   */
  public async init() {
    if (!this.state.account.admin) {
      return
    }

    this.loadStats().catch(window.logger.error)
    if (!this.state.account.creator) {
      return
    }

    await Promise.all([
      this.loadStripePk().catch(window.logger.error),
      this.loadActiveDataplan().catch(window.logger.error),
      this.loadDataplans().catch(window.logger.error),
      this.loadBalance().catch(window.logger.error),
      this.loadPaymentMethods().catch(window.logger.error),
      this.loadSettings().catch(window.logger.error),
    ])

    this.loadInvoice().catch(window.logger.error)
    this.initStripe()
  }

  /**
   */
  public async loadStripePk(): Promise<{ error: string | null }> {
    const { error, data: { stripe_pk } } = await billingApi.getStripePk()
    this.state.billing.stripe_pk = stripe_pk
    error && window.logger.error(error)
    return { error }
  }

  /**
   */
  public loadStats = async (): Promise<{ error: string | null }> => {
    const { error, data: { stats } } = await billingApi.getStats()
    this.state.billing.stats = stats
    return { error }
  }

  /**
   */
  public loadBalance = async (): Promise<{ error: string | null }> => {
    const { error, data: { balance } } = await billingApi.getBalance()
    this.state.billing.balance = balance
    error && window.logger.error(error)
    return { error }
  }

  /**
   */
  public async loadActiveDataplan(): Promise<{ error: string | null }> {
    const { error, data: { dataplan } } = await billingApi.getActiveDataplan()
    if (dataplan) {
      this.state.billing.dataplan = dataplan
    }
    error && window.logger.error(error)
    return { error }
  }

  /**
   */
  public async loadDataplans(): Promise<{ error: string | null }> {
    const { error, data: { dataplans } } = await billingApi.getDataplans()
    if (dataplans) {
      this.state.billing.dataplans = dataplans
    }
    error && window.logger.error(error)
    return { error }
  }

  /**
   */
  public async changeDataplan(dataplanId: string): Promise<{ error: string | null }> {
    const { error, data: { dataplan } } = await billingApi.changeDataplan(dataplanId)
    if (dataplan?.id) {
      this.state.billing.dataplan = dataplan
      if (this.state.billing.settings.cancel_at_paid_till) {
        await this.saveSettings({ cancel_at_paid_till: false })
      }
    }
    this.loadDataplans().catch(window.logger.error)
    this.loadSettings().catch(window.logger.error)
    this.loadBalance().catch(window.logger.error)
    this.loadHistory().catch(window.logger.error)
    this.loadInvoice().catch(window.logger.error)
    return { error }
  }

  /**
   */
  public async renew(): Promise<{error: string | null }> {
    const dataplanId = this.state.billing.settings.next_dataplan_id || this.state.billing.dataplan.id
    return this.changeDataplan(dataplanId)
  }

  /**
   */
  public async loadPaymentMethods(): Promise<{ error: string | null }> {
    const { error, data: { items, default_payment_method } } = await billingApi.getPaymentMethods()
    if (default_payment_method) {
      const defaultItem = items.find(i => i.id === default_payment_method)
      if (defaultItem) {
        defaultItem.isDefault = true
      }
    }

    // workaround for duplicate card remove
    const filteredItems = items.filter(i => i.isDefault)
    for (const item of items) {
      const dup = filteredItems.find(i => i.hash === item.hash)
      if (dup !== item) {
        if (dup) {
          await deletePaymentMethod(item.id)
        } else {
          filteredItems.push(item)
        }
      }
    }

    this.state.billing.paymentMethods = filteredItems
    error && window.logger.error(error)
    return { error }
  }

  /**
   */
  public async addPaymentMethod(cardToken: TObject): Promise<{ error: string | null, source?: TPaymentMethod }> {
    const { error, data: { source } } = await billingApi.addPaymentMethod(cardToken)
    return { error, source }
  }

  /**
   */
  public async deletePaymentMethod(id: string): Promise<{ error: string | null }> {
    if (this.state.billing.paymentMethods.length <= 1) {
      return { error: 'Delete last card unavailable' }
    }
    const { error } = await billingApi.deletePaymentMethod(id)
    return { error }
  }

  /**
   */
  public async defaultPaymentMethod(id: string): Promise<{ error: string | null }> {
    const { error } = await billingApi.defaultPaymentMethod(id)
    return { error }
  }

  /**
   */
  public async addFunds(amount: number, cardId?: string): Promise<{ error: string | null }> {
    const { error } = await billingApi.addFunds(amount, cardId)
    if (!error) {
      this.loadSettings().catch(window.logger.error)
      this.loadInvoice().catch(window.logger.error)
    }
    return { error }
  }

  /**
   */
  public async loadSettings(): Promise<{ error: string | null }> {
    const response = await billingApi.getBillingSettings()
    return this.setBilling(response)
  }

  /**
   */
  public async loadInvoice(): Promise<{ error: string | null }> {
    if (!this.state.billing.dataplan || this.state.billing.trial) {
      return { error: null }
    }

    const { error, data: { invoice } } = await billingApi.getInvoice()
    if (!error) {
      this.state.billing.invoice = invoice
    }
    return { error }
  }

  /**
   */
  public async saveSettings(formData: Partial<IBillingSettings>): Promise<{ error: string | null }> {
    const settings = { ...this.state.billing.settings, ...formData }
    const response = await billingApi.saveBillingSettings(settings)
    if (!response.error) { // workaround to update balance after top-up, because of WebSocket absence
      setTimeout(() => this.loadBalance(), 3000)
    }
    return this.setBilling(response)
  }

  /**
   */
  public async loadHistory(filter?: IBillingHistoryFilter): Promise<void> {
    this.state.billing.history.isLoading = true

    if (filter) {
      this.state.billing.history.filter = filter
      this.state.billing.history.options.page = 1
    }

    try {
      const { error, data: { items, total } } = await billingApi.getHistory(
        this.state.billing.history.filter,
        this.state.billing.history.options.page,
        this.state.billing.history.options.itemsPerPage,
        this.state.billing.history.options.sortBy[0],
        this.state.billing.history.options.sortDesc[0] ? 'desc' : 'asc',
      )
      if (error) {
        appService.showError(error)
      } else {
        this.state.billing.history.items = items
        this.state.billing.history.total = total
        this.state.billing.history.totalPages = Math.ceil(total / this.state.billing.history.options.itemsPerPage)
      }
    } catch (e) {
      window.logger.error(e)
      appService.showError()
    }
    this.state.billing.history.isLoading = false
  }

  /**
   */
  public async loadCallLog(filter?: ICallLogFilter): Promise<void> {
    this.state.billing.callLog.isLoading = true

    if (filter) {
      this.state.billing.callLog.filter = filter
      this.state.billing.callLog.options.page = 1
    }

    try {
      const { error, data: { items, total, stats } } = await billingApi.getCallLog(
        this.state.billing.callLog.filter,
        this.state.billing.callLog.options.page,
        this.state.billing.callLog.options.itemsPerPage,
        this.state.billing.callLog.options.sortBy[0],
        this.state.billing.callLog.options.sortBy[0]
          ? this.state.billing.callLog.options.sortDesc[0] ? 'desc' : 'asc' : undefined,
      )
      if (error) {
        appService.showError(error)
      } else {
        this.state.billing.callLog.stats = stats
        this.state.billing.callLog.items = items
        this.state.billing.callLog.total = total
        this.state.billing.callLog.totalPages = Math.ceil(total / this.state.billing.callLog.options.itemsPerPage)
      }
    } catch (e) {
      window.logger.error(e)
      appService.showError()
    }
    this.state.billing.callLog.isLoading = false
  }

  /**
   */
  private setBilling(response: IResponse<IGetBillingSettings>): { error: string | null } {
    const { error, data: { billing: { payment, settings, trial, paid_till, dev } } } = response
    Vue.set(this.state.billing, 'stripe', payment.stripe)
    Vue.set(this.state.billing, 'settings', settings)
    this.state.billing.trialFingerprint = payment.trial?.fingerprint || ''
    this.state.billing.trial = trial
    this.state.billing.paid_till = paid_till || null
    this.state.billing.serverError = error
    this.state.billing.dev = dev
    return { error }
  }

  /**
   */
  private initStripe = () => {
    if (this.state.billing.stripe_pk) {
      this.stripe = window.Stripe(this.state.billing.stripe_pk)
      this.stripeRequest.setHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${this.state.billing.stripe_pk}`,
      })
    } else {
      window.console.error('Stripe token not found')
    }
  }

  /**
   */
  private initIntervalUpdate = async () => {
    if (!this.interval) {
      this.interval = setInterval(() => {
        if (this.state.account.id) {
          this.loadStats().catch(window.logger.error)
          this.loadBalance().catch(window.logger.error)
        }
      }, 60000)
    }
  }
}

const billingService = new BillingService()
export { billingService, BillingService }

export interface IBillingSettings {
  auto_recharge: boolean
  trigger_amount: number
  topup_amount: number
  notify: string
  next_dataplan_id?: string
  cancel_at_paid_till: boolean
}

export interface IBillingHistoryFilter {
  start?: string
  end?: string
}

export interface ICallLogFilter {
  start?: string
  end?: string
}

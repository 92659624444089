var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"root row-hover",attrs:{"item-key":'email',"headers":_vm.headers,"items":_vm.state.users.list.items,"options":_vm.state.users.list.options,"server-items-length":_vm.state.users.list.total,"no-data-text":_vm.$t('common.empty'),"loading":_vm.state.users.list.isLoading,"page":_vm.state.users.list.options.page,"hide-default-footer":""},on:{"update:options":function($event){return _vm.$set(_vm.state.users.list, "options", $event)},"update:page":function($event){return _vm.$set(_vm.state.users.list.options, "page", $event)}},scopedSlots:_vm._u([{key:"item.profile.number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPhoneNumber(item.profile.number))+" ")]}},{key:"item.admin",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"switch",attrs:{"inset":"","dense":"","hide-details":"","color":"blue","disabled":item.email === _vm.currentUser.email || _vm.loading || item.id === _vm.company.created_by,"input-value":item && item.admin},on:{"change":function($event){return _vm.onToggleAdmin(item)}}})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('UserStatus',{attrs:{"user":item}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.email !== _vm.currentUser.email)?[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsHorizontal))])],1)]}}],null,true)},[_c('v-list',[(item.type === 'pending')?_c('v-list-item',{attrs:{"disabled":_vm.state.users.list.inviteSent.includes(item.email)},on:{"click":function($event){return _vm.onReinvite(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('users.reinvite')))])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.onDelete(item)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t(item.type === 'pending' ? 'users.cancelInvite' : 'common.delete'))+" ")])],1)],1)],1)]:_vm._e()]}}],null,true)}),_c('v-pagination',{attrs:{"length":_vm.state.users.list.totalPages,"total-visible":7},on:{"input":_vm.loadItems},model:{value:(_vm.state.users.list.options.page),callback:function ($$v) {_vm.$set(_vm.state.users.list.options, "page", $$v)},expression:"state.users.list.options.page"}}),_c('AppDialog',{ref:"dialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { parsePhoneNumberFromString, parsePhoneNumber, AsYouType } from 'libphonenumber-js'
import { format } from 'date-fns'

export const parseDate = (value: number | string | null): Date | null => {
  if (value === null || value === 0) {
    return null
  }
  const time = isNaN(value as number) ? Date.parse(value as string) : value
  return isNaN(time as number) ? null : new Date(time)
}

export const validateEmail = (email: string): boolean => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const delay = async (ms: number): Promise<void> => new Promise(r => setTimeout(r, ms))

export function setCursorPosition(el: HTMLInputElement, pos: number): void {
  if (el.setSelectionRange) {
    el.focus()
    el.setSelectionRange(pos, pos)
  }
}

export function formatDate(date: Date, withTime = false): string {
  return format(date, withTime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd')
}

export function formatTime(date: Date): string {
  return format(date, 'HH:mm')
}

export function getUTCStartOfDate(date: Date): Date {
  const result = new Date(date.getTime())
  result.setUTCHours(0)
  result.setUTCMinutes(0)
  result.setUTCSeconds(0)
  result.setUTCMilliseconds(0)
  return result
}

export function getUTCEndOfDate(date: Date): Date {
  const result = new Date(date.getTime())
  result.setUTCHours(23)
  result.setUTCMinutes(59)
  result.setUTCSeconds(59)
  result.setUTCMilliseconds(999)
  return result
}

export function formatMoneyUsd(value: number): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value)
}

export function decodeJwt(token: string): TObject {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

export function normalizePhoneNumber(phone: string): string {
  let result = `${phone.replace(/^\+/, '').replace(/[\s\-]/g, '')}`
  if (result.length === 10) { result = `1${result}` }
  return `+${result}`
}

export const formatPhoneNumber = (str: string): string => {
  if (!str) {
    return str
  }

  let value = str
  if (typeof parseInt(str) === 'number') {
    value = `+${value.replace('+', '').replace(/[\D]/g, '')}`
  }

  const matchUS = value.match(/^\+1(\d{3})(\d{3})(\d{2})(\d{2})$/)
  if (matchUS) {
    return `+1 (${matchUS[1]}) ${matchUS[2]}-${matchUS[3]}${matchUS[4]}`
  }

  const phone = parsePhoneNumberFromString(value)
  return phone ? phone.formatInternational() : str
}

export const createPhoneNumber = (str: string): TObject => {
  if (typeof str !== 'string') {
    return str
  }

  try {
    const number = str.replace(/[^0-9]/g, '')
    let national = false
    let _str = ''

    if (number?.length < 11) {
      national = true
      _str = number
    } else {
      _str = `+${number}`
    }

    const parsed = new AsYouType('US')
    parsed.input(_str)
    const phone = parsePhoneNumber(String(parsed?.getNumber()?.number))

    let formatted
    if (phone) {
      if (national || phone.countryCallingCode === '1') {
        formatted = `+${phone.countryCallingCode} ${phone.format('NATIONAL')}`
      } else {
        formatted = phone.format('INTERNATIONAL')
      }
    }

    return { phone, formatted }
  } catch (err) {
    return {}
  }
}

import { validateFields } from '@/lib/validate'

const initialData = {
  id: '',
  number: '',
  scenario_id: '',
  originator: 'internal' as 'internal' | 'external',
  scenario_name: null as null | string,
  scenario_description: null as null | string,
  account_uid: null as null | string,
  account_profile: null as null | any,
  system: false,
  fax: false,
  transfer: false,
  toll_free: false,
  is_active: false,
}

export type TDid = typeof initialData

export const create = (data?: Partial<TDid>): TDid => {
  return { ...initialData, ...data }
}

export const map = (data: TObject): TDid => {
  validateFields(data, [
    'id',
    'company_id',
    'scenarios_in_id',
    'number',
    'originator',
    'is_active',
    'toll_free',
    'created_at',
    'updated_at',
    'scenario_name',
    'scenario_description',
    'system',
    'fax',
    'transfer',
    'account_uid',
    'account_profile',
  ])

  return {
    ...initialData,
    id: `${data.id}` || initialData.id,
    number: data.number || initialData.number,
    scenario_id: data.scenarios_in_id || initialData.scenario_id,
    originator: data.originator || initialData.originator,
    scenario_name:  data.scenario_name || initialData.scenario_name,
    scenario_description:  data.scenario_description || initialData.scenario_description,
    account_uid: data.account_uid || initialData.account_uid,
    account_profile: data.account_profile || initialData.account_profile,
    system: !!data.system,
    fax: !!data.fax,
    transfer: !!data.transfer,
    toll_free: !!data.toll_free,
    is_active: !!data.is_active,
  }
}

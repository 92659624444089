<template>
  <div class="root pa-5 pb-0">
    <BillingCard :title="$t('billing.plan')">
      <template v-slot:content>
        <div v-if="!isExpired">
          {{$t('billing.activePlan')}} <b>{{activePlan.name}}</b>
          <span class="text--disabled"> till {{tillString}}</span>
        </div>
        <div class="mt-2" v-if="!isExpired && nextPlanId && !state.billing.settings.cancel_at_paid_till">
          <v-alert
              type="success"
              dense
              text
              color="teal"
              border="left"
              width="380"
          >
            {{$t('billing.nextPlan')}} <b>{{nextPlan.name}}</b> {{$t('billing.willStart')}}
            <span class="till">{{tillString}}</span>
          </v-alert>
        </div>
      </template>
      <template v-slot:actions>
        <ActionButton
            v-if="(trial || !isExpired) && !devAccount"
            @click="planDialog = true"
        >
          {{$t(trial ? 'billing.selectPlan' : 'billing.changePlan')}}
        </ActionButton>

        <v-alert
            class="dev"
            v-if="devAccount"
            border="right"
            type="warning"
            text
            :icon="false"
        >
          {{$t('billing.dev.changePlan')}}
        </v-alert>

        <AppButton
            v-if="!trial && isExpired"
            class="root__renew"
            type="warning"
            :isLoading="loading"
            @click="renew"
        >
          {{$t('billing.renew')}}
        </AppButton>

        <v-dialog
            class="plan__dialog"
            v-model="planDialog"
            fullscreen
            hide-overlay
        >
            <v-card class="plan__dialog__content">
              <BillingPlans
                  hideTrial
                  :allowAll="isExpired"
                  @select="onSelect"
              />
              <v-btn
                  class="btn__close"
                  fab
                  absolute
                  top
                  right
                  color="primary"
                  small
                  @click="planDialog = false"
              >
                <v-icon color="white">
                  {{icons.mdiClose}}
                </v-icon>
              </v-btn>
            </v-card>
        </v-dialog>
      </template>
    </BillingCard>
    <AppDialog ref="dialog" />
  </div>
</template>

<script>
import { state } from '@/store/state'
import { config } from '@/config/config'
import { appService } from '@/services/AppService'
import { billingService } from '@/services/BillingService'
import { formatDate, formatMoneyUsd } from '@/lib/utils'
import BillingPlans from '@/components/shared/BillingPlans'
import BillingCard from '@/components/shared/BillingCard'
import { mdiClose } from '@mdi/js'

export default {
  name: 'Plan',

  components: {
    BillingPlans,
    BillingCard,
  },

  computed: {
    trial() { return this.state.billing.trial },
    expiration() { return this.state.billing.paid_till },
    isExpired() { return this.expiration && this.expiration.getTime() < Date.now() },
    activePlan() { return this.state.billing.dataplan },
    nextPlanId() { return this.state.billing.settings.next_dataplan_id },
    devAccount() { return this.state.billing.dev },
    nextPlan() { return this.state.billing.dataplans.find(i => i.id == this.nextPlanId) || {} },
    tillDate() { return this.state.billing.paid_till },
    tillString() { return this.tillDate && formatDate(this.tillDate, true) },
    balance() { return this.state.billing.balance },
  },

  data() {
    return {
      state,
      loading: false,
      planDialog: false,
      icons: {
        mdiClose,
      },
    }
  },

  methods: {
    formatMoneyUsd,
    async onSelect(item) {
      this.planDialog = false

      if (item.charge > this.balance) {
        return this.addFundsAndChangePlan(item)
      }

      const confirmDialogText = item.cost > this.activePlan.cost
          ? this.$t('billing.confirmExpensive', { name: item.name })
          : this.$t('billing.confirmCheaper', { name: item.name, date: formatDate(this.tillDate, true) })

      const confirmDialogSettings = {
        title: this.$t(this.trial ? 'billing.selectPlan' : 'billing.changePlan'),
        html: confirmDialogText,
        actionText: this.$t('common.confirm'),
      }

      if (this.isExpired) {
        const { error } = await billingService.changeDataplan(item.id)
        if (error) { appService.showError(error) }

      } else if (await this.$refs.dialog.show(confirmDialogSettings)) {
        const { error } = await billingService.changeDataplan(item.id)
        if (error) { appService.showError(error) }
      }
    },

    async addFundsAndChangePlan(item) {
      const addValue = Math.round((item.charge - this.balance) * 100) / 100
      const confirmDialogSettings = {
        title: this.$t(this.trial ? 'billing.selectPlan' : 'billing.changePlan'),
        html: this.$t('billing.confirmAddAndChangePlan', { value: addValue, topUp: config.minBalanceTopUp }),
        actionText: this.$t('common.confirm'),
      }

      if (await this.$refs.dialog.show(confirmDialogSettings)) {
        const { error } = await billingService.addFunds(addValue < config.minBalanceTopUp ? config.minBalanceTopUp : addValue)
        if (error) {
          appService.showError(error)
        } else {
          const { error } = await billingService.changeDataplan(item.id)
          if (error) { appService.showError(error) }
        }
      }
    },

    async renew() {
      if (this.isExpired) {
        this.planDialog = true
        return
      }

      this.loading = true
      try {
        const { error } = await billingService.renew()
        if (error) {
          appService.showError(error)
        }
      } catch (e) {
        appService.showError(e.message || this.$t('common.systemError'))
      }
      this.loading = false
    },
  },
}
</script>

<style lang="sass" scoped>
.root
  .v-alert
    font-size: 0.9rem
    padding: 5px 15px

  .next-plan
    margin-top: 2px
    display: inline-block

  &__main
    min-height: 50px

  &__change
    width: 130px
    justify-content: center
    align-items: center
    margin-right: 10px

  &__renew
    width: 70px
    justify-content: center
    align-items: center

  .expired
    color: red
    font-weight: bold

  .actions
    display: flex
    align-items: center

.link
  cursor: pointer
  color: #006ea4
  border-bottom: 1px dashed #006ea4
  &.disabled
    cursor: not-allowed
    color: #777
    border-color: #777

.plan__dialog
  &__content
    display: flex
    align-items: center
    justify-content: center
    background: $main-background-color

    .btn__close
      position: fixed
      bottom: 0
      margin: 40px 20px 0
</style>

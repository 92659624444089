<template>
  <LayoutAuth width="1024px" :title="$t('common.terms')">
  </LayoutAuth>
</template>

<script>
import LayoutAuth from '@/components/shared/layout/Auth.vue'

export default {
  name: 'ViewTerms',

  components: {
    LayoutAuth,
  },

  data() {
    return {
    }
  },
}
</script>

<style lang="sass" scoped>
</style>

import { state } from '@/store/state'

export const hasAccess = (type?: 'admin' | 'creator') => {
  if (type === 'admin') {
    return state.account.admin
  }
  if (type === 'creator') {
    return state.account.creator
  }
  return true
}

<template>
  <div :class="['root', show && 'show']">
    <v-progress-circular
        :size="50"
        :width="7"
        color="secondary"
        indeterminate
    />
  </div>
</template>

<script>

export default {
  name: 'AppLoader',

  components: {
  },

  data() {
    return {
      show: false,
    }
  },

  mounted() {
    setTimeout(() => this.show = true, 500)
  },
}
</script>

<style lang="sass" scoped>
.root
  width: 100%
  padding: 200px 0
  display: flex
  justify-content: center
  opacity: 0

  &.show
    opacity: 1
</style>

import { helpers } from 'vuelidate/lib/validators'
import { config } from '@/config/config'

export function validateResponse(object: TObject, fields: string[]): boolean {
  return !!(object.code && object.message) || validateFields(object, fields)
}

export function validateFields(object: TObject, fields: string[]): boolean {
  if (fields.some(i => object[i] === undefined)) {
    const message = 'Wrong data structure'
    if (config.env === 'local') {
      alert(message)
    }
    window.logger.error(message, { object, fields })
    return false
  }
  return true
}

export const phone = (value: string) => /^\+?\d{11,15}$/.test(value) || /^\d{10}$/.test(value)

export const isTrue = (value: any) => !!value

export const name = helpers.regex('name', /^[a-zA-Z\s'\-"]*$/)

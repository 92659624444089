<template>
  <v-card elevation="0" class="root">
    <div class="pa-5" style="width: 100%">
      <div class="header mb-5">
        <h2 v-if="title" class="title heading font-weight-bold">{{title}}</h2>
        <div class="spacer" />
        <slot name="actions" />
      </div>
      <slot />
    </div>
  </v-card>
</template>

<script>

export default {
  name: 'AppCard',

  props: {
    title: String,
  },

  components: {
  },

  data() {
    return {
    }
  },
}
</script>

<style lang="sass" scoped>
.root
  .header
    display: flex
    align-items: center
    padding-bottom: 5px

    .title
      flex: 0 0 auto
      white-space: nowrap

    .spacer
      flex: 1 1 auto
      width: 100%
</style>

<template>
  <div class="root">
    <p class="text-justify">
      {{$t('onboarding.textDidNumber')}}
    </p>

    <AppError :error="serverError" />

    <v-select
        class="root__input mt-7"
        :items="originatorOptions"
        outlined
        hide-details
        item-text="label"
        item-value="value"
        :label="$t('dids.originator')"
        v-model="originator"
        @input="setField('originator', $event)"
    ></v-select>

    <v-text-field
        class="root__input mt-7"
        v-if="originator === 'external'"
        outlined
        hide-details
        type="text"
        :label="$t('dids.number')"
        :error="$v.number.$anyError"
        v-model.trim="formattedNumber"
        @input="setPhoneNumber($event)"
    />

    <NumberSelect
        class="mt-7"
        v-if="originator === 'internal'"
        :error="$v.number.$anyError"
        :value="number"
        @change="setField('number', $event)"
    />

    <v-card-actions class="mt-7 pa-0 ma">
      <AppButton
          block
          type="primary"
          :disabled="$v.$invalid"
          :isLoading="isLoading"
          @click="onClick"
      >
        {{$t('common.continue')}}
      </AppButton>
      <v-spacer></v-spacer>
    </v-card-actions>
  </div>
</template>

<script>

import { state } from '@/store/state'
import { required } from 'vuelidate/lib/validators'
import { appService } from '@/services/AppService'
import { didService } from '@/services/DidService'
import { create as createDid } from '@/models/Did'
import { i18n } from '@/config/vue'
import { phone } from '@/lib/validate'
import NumberSelect from '@/components/shared/NumberSelect'
import { createPhoneNumber } from '@/lib/utils'

export default {
  name: 'OnboardingStepDidNumber',

  components: {
    NumberSelect,
  },

  data() {
    return {
      state,
      isLoading: false,
      serverError: null,
      number: '',
      formattedNumber: '',
      originator: 'internal',
      originatorOptions: [
        { value: 'internal', label: i18n.t('dids.internal') },
        { value: 'external', label: i18n.t('dids.external') },
      ],
    }
  },

  validations() {
    return {
      number: {
        phone,
        required,
      },
      originator: {
        required,
      },
    }
  },

  methods: {
    createPhoneNumber,

    setField(field, value) {
      this[field] = value
      this.$v[field].$touch()
      this.serverError = null
    },

    setPhoneNumber(value) {
      const phone = createPhoneNumber(value)
      this.setField('number', phone?.phone?.number || value)
    },

    async onClick() {
      this.$v.$touch()
      if (!this.$v.$anyError) {
        this.isLoading = true
        try {
          const scenarioId = this.state.scenarios.refs[0]?.value
          const did = createDid({
            number: this.number,
            originator: this.originator,
            scenario_id: scenarioId,
          })
          const { error } = await didService.save(did)
          if (error) {
            this.serverError = error
            appService.showError(error)
          } else {
            this.$emit('next', did.originator === 'external' ? 'sipUrl' : 'finish')
          }

        } catch (e) {
          window.logger.error(e)
          appService.showError()
        }
        this.isLoading = false
        this.$v.$reset()
      }
    },
  },

  watch: {
    number: function(val) {
      this.formattedNumber = this.createPhoneNumber(val)?.formatted || val
    },
  },
}
</script>

<style lang="sass" scoped>
</style>

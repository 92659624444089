<template>
  <LayoutAuth class="root" :title="$t('signUp.title')">
    <CodeVerify
        v-if="verify"
        :isLoading="isLoading"
        :verifyError="verifyError"
        :buttonLabel="$t('signUp.title')"
        @cancel="verify = false"
        @verify="onVerify"
    />

    <FormSignUp
        v-else
        :isLoading="isLoading"
        :authError="authError"
        :cancelUrl="paths.PATH_PAGE_SIGN_IN"
        :buttonLabel="$t('signUp.title')"
        @submit="onSubmit"
    />
  </LayoutAuth>
</template>

<script>
import LayoutAuth from '@/components/shared/layout/Auth.vue'
import CodeVerify from '@/components/shared/CodeVerify.vue'
import FormSignUp from '@/components/shared/FormSignUp'
import { authService } from '@/services/AuthService'
import * as paths from '@/config/paths'

export default {
  name: 'ViewSignUp',

  components: {
    LayoutAuth,
    CodeVerify,
    FormSignUp,
  },

  data() {
    return {
      paths,
      formData: {},
      authError: null,
      verify: false,
      verifyError: false,
      isLoading: false,
    }
  },

  mounted() {
    const prePlan = (this.$router.history.current.query.plan || '').toLowerCase()
    localStorage.setItem('prePlan', prePlan)
    window.logger.info({ prePlan })
  },

  methods: {
    async onSubmit(formData) {
      this.isLoading = true
      this.formData = formData
      const { error } = await authService.signUp(
          formData.email,
          formData.password,
          formData.firstName,
          formData.lastName,
          formData.number,
      )
      this.isLoading = false
      this.authError = error
      this.verify = !error
    },

    async onVerify(code) {
      this.isLoading = true
      this.verifyError = !await authService.signUpVerify(this.formData.email, code)
      this.isLoading = false
    },
  },
}
</script>

<style lang="sass" scoped>
</style>

import { config } from '@/config/config'

export const PATH_PAGE_SIGN_IN = '/sign-in'
export const PATH_PAGE_SIGN_UP = config.env === 'stage' ? '/dev-sign-up' : '/sign-up'
export const PATH_PAGE_FORGOT = '/forgot'
export const PATH_PAGE_INVITED = '/invited'
export const PATH_PAGE_HOME = '/'
export const PATH_PAGE_CALLSTATS = '/callstats'
export const PATH_PAGE_BILLING = '/billing'
export const PATH_PAGE_USERS = '/users'
export const PATH_PAGE_NUMBERS = '/numbers'
export const PATH_PAGE_SCENARIOS = '/scenarios'
export const PATH_PAGE_PROFILE = '/profile'
export const PATH_PAGE_TERMS = '/terms'
export const PATH_PAGE_PRIVACY = '/privacy'
export const PATH_PAGE_FAXES = '/faxes'

<template>
  <div class="root">
    <div v-if="items.length" lass="log">
      <CallLogFilters @change="loadItems" />

      <!-- <div class="stats mt-4">
        <div class="mb-2">{{$t('callog.totalMin')}}: <b>{{stats.duration_sum}}</b></div>
        <div>{{$t('callog.totalTFMin')}}: <b>{{stats.duration_toll_free_sum}}</b></div>
      </div> -->

      <v-data-table
          class="mt-5"
          :item-key="'id'"
          :headers="headers"
          :items="items"
          :options.sync="state.billing.callLog.options"
          :server-items-length="state.billing.callLog.total"
          :loading="state.billing.callLog.isLoading"
          :no-data-text="$t('common.empty')"
          @update:options="loadItems()"
          :page.sync="state.billing.callLog.options.page"
          hide-default-footer
      >
        <template v-slot:[`item.created_at`]="{ item }">
          {{ formatDate(item.created_at, true) }}
        </template>
        <template v-slot:[`item.begin`]="{ item }">
          {{ formatDate(item.begin, true) }}
        </template>
        <template v-slot:[`item.end`]="{ item }">
          {{ formatTime(item.end) }}
        </template>
        <template v-slot:[`item.direction`]="{ item }">
          <v-chip
            :color="(item.direction === 'inbound') ? 'green' : 'orange'"
            text-color="white"
            small
          >
            {{item.direction}}
          </v-chip>
        </template>
        <template v-slot:[`item.scenario_id`]="{ item }">
          {{ (scenarioRefs.find(i => i.value === `${item.scenario_id}`) || {}).label || item.scenario_id }}
        </template>
        <template v-slot:[`item.fromNumber`]="{ item }">
          {{createPhoneNumber(item.fromNumber).formatted || item.fromNumber}}
        </template>
        <template v-slot:[`item.toNumber`]="{ item }">
          {{createPhoneNumber(item.toNumber).formatted || item.toNumber}}
        </template>
        <template v-slot:[`item.transfer_account_uid`]="{ item }">
          {{ getTransferAccountName(item.transfer_account_uid) }}
        </template>
      </v-data-table>
      <v-pagination
        v-model="state.billing.callLog.options.page"
        @input="loadPage"
        :length="state.billing.callLog.totalPages"
        :total-visible="7"
      />
    </div>
    <div v-else class="d-flex justify-center align-center ">
      <img class="ma-2" :src="icons.logIcon" />
      <h2 class="ma-2 empty-message">{{$t('callog.noLogs')}}</h2>
    </div>
  </div>
</template>

<script>

import { state } from '@/store/state'
import { billingService } from '@/services/BillingService'
import { formatDate, formatPhoneNumber, createPhoneNumber, formatTime } from '@/lib/utils'
import CallLogFilters from '@/components/shared/callLog/Filters'
import logIcon from '@/assets/log.png'

export default {
  name: 'CallLog',

  components: {
    CallLogFilters,
  },

  computed: {
    scenarioRefs() { return this.state.scenarios.refs },
    // stats() { return this.state.billing.callLog.stats || {} },
    items() {
      const items = state.billing.callLog.items.map(i => Object.assign({}, i, {
        hangup_cause: this.$te(`callog.hangups.${i.hangup_cause}`)
          ? this.$t(`callog.hangups.${i.hangup_cause}`) : i.hangup_cause,
      }))
      return items
    },

  },

  data() {
    return {
      state,
      icons: {
        logIcon,
      },
      headers: [
        {
          text: this.$t('callog.begin'),
          value: 'begin',
          sortable: true,
        },
        {
          text: this.$t('callog.end'),
          value: 'end',
          sortable: true,
        },
        {
          text: this.$t('callog.duration'),
          align: 'center',
          value: 'duration',
          sortable: true,
        },
        {
          text: this.$t('callog.direction'),
          align: 'center',
          value: 'direction',
          sortable: true,
        },
        {
          text: this.$t('callog.fromNumber'),
          value: 'fromNumber',
          sortable: true,
        },
        {
          text: this.$t('callog.toNumber'),
          value: 'toNumber',
          sortable: true,
        },
        {
          text: this.$t('callog.scenario'),
          value: 'scenario_id',
          sortable: true,
        },
        {
          text: this.$t('callog.redirectNumber'),
          value: 'transfer_account_uid',
          sortable: true,
        },
        {
          text: this.$t('callog.hangupCause'),
          value: 'hangup_cause',
          sortable: true,
        },
      ],
    }
  },

  methods: {
    formatPhoneNumber,
    createPhoneNumber,
    formatDate,
    formatTime,

    async loadItems(filter) {
      await billingService.loadCallLog(filter)
    },

    async loadPage(page) {
      this.state.billing.callLog.options.page = page || 1
      this.loadItems()
    },

    getTransferAccountName(id) {
      if (id) {
        const user = this.state.users.refs.find(i => i.id === id)
        return user ? `${user.profile.firstName} ${user.profile.lastName}` : this.$t('callog.userDeleted')
      }
      return ''
    },
  },
}
</script>

<style lang="sass" scoped>
</style>

import { state } from '@/store/state'
import { authService } from '@/services/AuthService'

export enum EMessageType {
  info = 0,
  success,
  error,
}

export interface IMessageColor {
  [key: number]: string
}

export interface IMessageType {
  [key: number]: string
}

const KEEP_ALIVE = 60000

class AppService {
  public state = state
  private timeout: null | number = null
  private readonly defaultMessages = {
    [EMessageType.info]: '',
    [EMessageType.success]: 'Success',
    [EMessageType.error]: 'Error',
  }
  private readonly messageColors: IMessageColor = {
    [EMessageType.info]: 'light-green darken-2',
    [EMessageType.success]: 'light-blue darken-3',
    [EMessageType.error]: 'red darken-3',
  }

  /**
   */
  public async init() {
    return Promise.all([
      this.subscribeResize(),
    ]).catch(window.logger.error)
  }

  /**
   */
   private showSnackBar(message?: string, type: EMessageType = EMessageType.success, timeout = 5000) {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    const _message = message || this.defaultMessages[type]
    this.state.layout.snackbar.color = this.messageColors[type] || 'primary'
    this.state.layout.snackbar.message = _message

    this.timeout = setTimeout(() => {
      this.state.layout.snackbar.message = ''
    }, timeout)
  }

  /**
   */
  public showInfo(message?: string, timeout?: number) {
    this.showSnackBar(message || this.defaultMessages[EMessageType.info], EMessageType.info, timeout)
  }

  /**
   */
  public showSuccess(message?: string, timeout?: number) {
    this.showSnackBar(message || this.defaultMessages[EMessageType.success], EMessageType.success, timeout)

  }

  /**
   */
  public showError(message?: string, timeout?: number) {
    this.showSnackBar(message || this.defaultMessages[EMessageType.error], EMessageType.error, timeout)
  }

  /**
   */
  private async subscribeResize() {
    this.state.screen.width = window.innerWidth
    this.state.screen.height = window.innerHeight
    window.addEventListener('resize', () => {
      this.state.screen.width = window.innerWidth
      this.state.screen.height = window.innerHeight
    })
  }

  /**
   */
  private async keepAlive() {
    setInterval(async () => {
      if (this.state.auth) {
        if(!await authService.loadAccount()) {
          authService.logout().catch(window.logger.error)
        }
      }
    }, KEEP_ALIVE)
  }
}

const appService = new AppService()
export { appService, AppService }

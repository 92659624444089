<template>
  <div class="root">
    <v-dialog
        v-model="dialog"
        max-width="380"
    >
      <template v-slot:activator="{ on, attrs }">
        <ActionButton
          v-if="hasAccess('admin')"
          extended
          :label="$t('billing.titleBalance')"
          :value="`$${state.billing.balance}`"
          v-bind="attrs"
          v-on="on"
        />
      </template>

      <v-card>
        <v-card-title class="headline">
          {{$t('billing.addFunds')}}
        </v-card-title>
        <v-form @submit.prevent="onSubmit" novalidate="true">
          <v-card-text>
            <AppError class="mt-5 mb-0" :error="serverError" />

            <v-select
                v-if="cardOptions.length > 1"
                class="mt-2 mb-7"
                :items="cardOptions"
                outlined
                hide-details
                :label="$t('billing.selectCard')"
                item-text="label"
                item-value="value"
                v-model="cardId"
            ></v-select>

            <v-text-field
                outlined
                autofocus
                :label="$t('billing.labelValueUsd')"
                type="number"
                v-model.trim="value"
                :error="$v.value.$anyError"
                :error-messages="errorMessage"
                @input="setField('value', $event)"
            />
          </v-card-text>
          <v-card-actions class="pr-4 pl-4 pb-4">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="dialog = false"
            >
              {{$t('common.cancel')}}
            </v-btn>
            <v-btn
                :disabled="$v.$invalid || isLoading"
                color="primary"
                type="submit"
            >
              {{$t(isLoading ? 'common.loading' : 'billing.addFunds')}}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { formatMoneyUsd } from '@/lib/utils'
import { required, minValue, maxValue } from 'vuelidate/lib/validators'
import { config } from '@/config/config'
import { state } from '@/store/state'
import { appService } from '@/services/AppService'
import { billingService } from '@/services/BillingService'
import { hasAccess } from '@/lib/access'

const MIN_AMOUNT = config.minBalanceTopUp

export default {
  name: 'Balance',

  components: {
  },

  computed: {
    balance() { return formatMoneyUsd(state.billing.balance) },
    errorMessage() {
      if (!this.$v.value.minValue) {
        return `${this.$t('billing.minAmount')}: $${MIN_AMOUNT}`
      }
      return ''
    },
    cardOptions() {
      return this.state.billing.paymentMethods.map(i => ({
        value: i.id, label: `${i.brand} xxxx-${i.last4} ${i.isDefault ? '(default)' : ''}`,
      }))
    },
  },

  mounted () {
    if (this.hasAccess('admin')) {
      billingService.loadBalance().catch(window.logger.error)
    }
  },

  data() {
    return {
      state,
      hasAccess,
      minAmount: MIN_AMOUNT,
      isLoading: false,
      dialog: false,
      cardId: (state.billing.paymentMethods.find(i => i.isDefault) || {}).id || '',
      value: '',
      serverError: null,
    }
  },

  validations: {
    value: {
      required,
      minValue: minValue(MIN_AMOUNT),
      maxValue: maxValue(10000),
    },
  },

  methods: {
    setField(field, value) {
      this[field] = value
      this.$v[field].$touch()
    },

    async onSubmit() {
      const amount = parseFloat(this.value) || 0
      if (amount) {
        this.isLoading = true
        try {
          const { error } = await billingService.addFunds(amount, this.cardId)
          if (error) {
            appService.showError(error)
          } else {
            await billingService.loadBalance()
            billingService.loadHistory().catch(window.logger.error)
            appService.showSuccess()
          }
          this.$v.$reset()

        } catch(e) {
          appService.showError()
        }
      }
      this.isLoading = false
      this.dialog = false
    },
  },
}
</script>

<style lang="sass" scoped>
.root

  &__main
    min-height: 100px
    overflow: hidden

  &__value
    font-size: 2rem
    font-weight: 500
</style>

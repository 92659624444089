<template>
  <div :class="['statsBlock', stat.limit && stat.count >= stat.limit && 'limit']">
    <div class="value">{{stat.count}}</div>
    <div class="label">{{label}}</div>
  </div>
</template>

<script>

export default {
  name: 'StatsBlock',

  components: {
  },

  props: {
    label: { type: String, required: true },
    stat: { type: Object, required: true },
  },

  data() {
    return {
    }
  },
}
</script>

<style lang="sass" scoped>
.statsBlock
  display: flex
  flex-direction: column
  justify-content: center
  border-radius: 5px
  width: 200px
  height: 94px
  position: relative
  overflow: hidden
  background: #F2F5F7

  & + &
    margin-left: 20px

  .label,
  .value
    text-align: center

  .label
    font-size: 12px
    font-weight: bold
    text-transform: uppercase
    color: #999

  .value
    line-height: 1
    font-size: 30px
    font-weight: 500
    text-transform: uppercase

</style>

import { validateFields } from '@/lib/validate'

let INC = 0

const initialData = {
  id: '',
  name: '',
  description: '',
  scheme: '',
}

export type TScenarioTemplate = typeof initialData

export const create = (data?: Partial<TScenarioTemplate>): TScenarioTemplate => {
  return { ...initialData, ...data }
}

export const map = (data: TObject): TScenarioTemplate => {
  validateFields(data, ['name', 'description', 'scheme'])
  INC += 1

  return {
    ...initialData,
    id: `${INC}`,
    name: data.name || initialData.name,
    description: data.description || initialData.description,
    scheme: data.scheme || initialData.scheme,
  }
}

<template>
  <v-app light class="root">
    <div class="back">
      <Background />
    </div>
    <v-main>
      <v-container class="container">
        <div class="plan">
          <div class="title">
            <span>{{$t('onboarding.titlePlansShort')}}</span>
            <div class="title__border"></div>
          </div>
          <div class="plan__box">
            <slot />
          </div>
        </div>
      </v-container>
    </v-main>
    <CookieBanner />
  </v-app>
</template>

<script>

import { state } from '@/store/state'
import CookieBanner from '@/components/shared/CookieBanner'
import Background from '@/assets/bg.svg'

export default {
  name: 'LayoutPlan',

  components: {
    CookieBanner,
    Background,
  },

  data() {
    return {
      state,
    }
  },

  props: {
    width: String,
  },
}
</script>

<style lang="sass" scoped>
.root
  position: relative
  background: #f8f8f8 !important

.back
  position: fixed
  left: 0
  width: 100%
  height: 100%
  text-align: center

.title
  display: flex
  flex-direction: column
  align-items: flex-end
  margin: 90px 0 45px 0
  font-weight: 700 !important
  font-style: normal !important
  font-size: 42px !important
  line-height: 51px !important
  text-align: center
  letter-spacing: 0.06em
  text-transform: uppercase
  color: #FFFFFF
  text-shadow: 0px 20px 30px rgba(12, 37, 74, 0.2)

  &__border
    width: 65px
    height: 4px
    background: #00F0FF
    border-radius: 2px

.container
  width: auto
  max-width: none

.plan
  display: flex
  flex-direction: column
  align-items: center

  &__box
    max-width: calc(100% - 10px)
</style>

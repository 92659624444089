<template>
  <v-form @submit.prevent="onSubmit" class="root">
    <div class="input">
      <v-text-field
          outlined
          hide-details
          :label="$t('users.inviteLabel')"
          v-model.trim="emails"
          @input="onInput"
      />
      <div v-if="touched" class="total">
        {{$t('users.emailsParsed')}}: <b class="ml-1">{{total}}</b>
        <div v-if="total" class="clear" @click="onClear">{{$t('common.clear')}}</div>
      </div>
    </div>

    <div class="controls">
      <AppButton
        @click="onClickUpload"
        class="upload"
      >
        <v-icon>
          {{icons.mdiFileUploadOutline}}
        </v-icon>
        <input
            :key="fileKey"
            ref="file"
            type="file"
            accept=".csv"
            @change="onUpload"
            hidden
        />
      </AppButton>

      <v-switch
          dense
          inset
          hide-details
          class="switch"
          color="blue"
          v-model="admin"
          :label="$t('users.inviteAsAdmin')"
      ></v-switch>

      <AppButton submit type="primary" class="btn" :disabled="!total" :isLoading="isLoading">
        {{$t('users.inviteBtn')}}
      </AppButton>
    </div>
  </v-form>
</template>

<script>

import { mdiFileUploadOutline } from '@mdi/js'
import { validateEmail } from '@/lib/utils'
import { appService } from '@/services/AppService'
import { userService } from '@/services/UserService'

export default {
  name: 'ViewUsersInvite',

  components: {
  },

  computed: {
    total: function() { return this.valuesInput.length + this.valuesCsv.length },
  },

  data() {
    return {
      icons: { mdiFileUploadOutline },
      isLoading: false,
      admin: false,
      emails: '',
      valuesInput: [],
      valuesCsv: [],
      fileKey: 1,
      touched: false,
    }
  },

  methods: {
    onInput(value) {
      this.valuesInput = value.split(',').map(i => i.trim()).filter(i => validateEmail(i))
      this.touched = true
    },

    onClickUpload() {
      if (this.$refs.file) {
        this.$refs.file.click()
      }
    },

    onClear() {
      this.emails = ''
      this.valuesInput = []
      this.valuesCsv = []
    },

    onUpload() {
      if (this.$refs.file) {
        const file = this.$refs.file.files[0]
        const reader = new FileReader()
        reader.addEventListener('load', e => {
          const valuesCsv = e.target.result.split('\n')
              .map(i => i.replace(',', '').trim())
              .filter(i => validateEmail(i))

          this.valuesCsv = [...new Set([...valuesCsv])]
          this.fileKey += 1
          this.touched = true
        })
        reader.readAsText(file)
      }
    },

    async onSubmit() {
      this.isLoading = true
      try {
        const emails = [...new Set([...this.valuesInput, ...this.valuesCsv])]
        const { error } = await userService.invite(emails, this.admin)
        if (!error) {
          userService.loadItems().catch(window.logger.error)
        } else {
          appService.showError(error)
        }
      } catch (e) {
        window.logger.error(e)
        appService.showError()
      }
      this.isLoading = false
      this.touched = false
      this.onClear()
    },
  },
}
</script>

<style lang="sass" scoped>
  .root
    display: flex
    flex-wrap: wrap

    .input
      position: relative
      flex-grow: 1

      .total
        position: absolute
        font-size: .8rem
        bottom: -23px
        right: 4px
        display: flex
        align-items: center

        .clear
          margin-left: 8px
          cursor: pointer
          display: inline-flex
          align-items: center
          padding: 1px 4px
          background: var(--v-info-base)
          color: #fff
          border-radius: 2px
          text-transform: lowercase
          opacity: 0.5
          &:hover
            opacity: 1

    .controls
      display: flex
      gap: 20px

    .upload
      margin-left: 16px

    .switch
      padding: 0
      margin: 0
</style>

<style lang="sass">
  .root
    .switch
      .v-input__control
        .v-input__slot
          display: flex
          flex-direction: column-reverse !important
          .v-label
            margin-bottom: 5px
            text-transform: unset !important
</style>

<template>
  <div :class="['root', user.type]">
    {{user.type === 'regular' ? 'active' : user.type}}
  </div>
</template>

<script>

export default {
  name: 'UserStatus',

  components: {
  },

  props: {
    user: { type: Object, required: true },
  },

  data() {
    return {
    }
  },
}
</script>

<style lang="sass" scoped>
  .root
    display: inline-flex
    align-items: center
    padding: 2px 12px
    background: #aaa
    border-radius: 2px
    font-size: .9rem
    color: #fff

    &.pending
      background: var(--v-blue-base)

    &.regular
      background: #05ac70
</style>

<template>
  <AppLoader v-if="state.scenarios.detail.isLoading || !this.isInitialized" />
  <AppForm v-else class="root">
    <v-row>
      <v-col cols="4">
        <v-text-field
            outlined
            hide-details
            autofocus
            type="text"
            :label="$t('common.name')"
            :disabled="!hasAccess('admin') || this.isTransferScenario"
            :error="$v.scenario.name.$anyError"
            v-model.trim="scenario.name"
            @input="setField('name', $event)"
        />
      </v-col>
      <v-col cols="8">
        <v-text-field
          outlined
          hide-details
          type="text"
          :disabled="!hasAccess('admin') || this.isTransferScenario"
          :label="$t('common.description')"
          :error="$v.scenario.description.$anyError"
          v-model.trim="scenario.description"
          @input="setField('description', $event)"
        />
      </v-col>
    </v-row>
    <template slot="bottom">
      <Blockly
          ref="blockly"
          class="blockly"
          :scheme="scenario.scheme"
          :meta="scenario.meta"
          :options="blocklyOptions"
          :changed="$v.$anyDirty"
          @meta="onMeta"
          @submit="onSubmit"
      />

      <AppError :error="serverError" />
    </template>
    <AppDialog ref="appWarning"
      :showCancel=false
      :maxWidth=480
    />
  </AppForm>
</template>

<script>
import clone from 'clone'
import { state } from '@/store/state'
import { config } from '@/config/config'
import { required } from 'vuelidate/lib/validators'
import { PATH_PAGE_SCENARIOS } from '@/config/paths'
import { scenarioService } from '@/services/ScenarioService'
import { appService } from '@/services/AppService'
import { create as createScenario } from '@/models/Scenario'
import Blockly from '@/components/shared/blockly/Blockly.vue'
import { hasAccess } from '@/lib/access'

export default {
  name: 'ViewScenariosDetailForm',

  components: {
    Blockly,
  },

  computed: {
    urlList: () => PATH_PAGE_SCENARIOS,
  },

  props: {
    id: { type: String, required: true },
  },

  data() {
    return {
      state,
      isInitialized: false,
      serverError: null,
      scenario: createScenario(),
      blocklyOptions: config.blockly,
    }
  },

  validations: {
    scenario: {
      name: {
        required,
      },
      description: {
      },
      meta: {
      },
    },
  },

  mounted() {
    scenarioService.loadBlocks().then(() => {
      this.loadItem().catch(window.logger.error)
    }).catch(window.logger.error)
  },

  methods: {
    hasAccess,
    async loadItem() {
      this.id === 'new'
        ? await scenarioService.loadItem()
        : await scenarioService.loadItem(this.id)
      this.scenario = clone(this.state.scenarios.detail.item)
      this.isTransferScenario = !!this.state.scenarios.detail.item.account_id
      this.isInitialized = true
    },

    setField(field, value) {
      this.scenario[field] = value
      this.$v.scenario[field].$touch()
      this.serverError = null
    },

    showAppError(dialogSettings) {
      this.$refs.appWarning.show(dialogSettings)
    },

    async onSubmit() {
      if (!this.$v.$anyError) {
        const blockly = this.$refs['blockly']
        try {
          const title = this.$t('scenarios.savingError')
          const actionText = this.$t('common.ok')
          const valid = blockly.isValidWorkspace()
          if (!valid?.ok) {
            const message = valid?.message || this.$t('scenarios.invalidDialPlan')
            return this.showAppError({
              title,
              actionText,
              html: `<span class="text-no-wrap">${message}</span>`,
            })
          }

          this.state.scenarios.detail.isSaving = true
          this.scenario.scheme = blockly.getXml()
          const { error, scenario } = await scenarioService.save(this.scenario)
          if (error || !scenario) {
            this.showAppError({
              title,
              actionText,
              html: `<span class="text-no-wrap">${error}</span>`,
            })
          } else {
            this.scenario = clone(scenario)
            blockly.clearUndoRedoStack()
            appService.showSuccess()
          }
        } catch (e) {
          window.logger.error(e)
          appService.showError()
        }
        this.state.scenarios.detail.isSaving = false
        this.$v.$reset()
      }
    },

    onMeta(data) {
      this.scenario.meta = {
        ...this.scenario.meta,
        ...data,
      }
      this.$v.$touch()
    },
  },
}
</script>

<style lang="sass" scoped>
.root
  .blockly
    width: 100%
    min-height: 650px
    height: calc(100vh - 650px)
    border: 1px solid #aaa
    margin-bottom: 16px
</style>

import { state } from '@/store/state'
import { remove, save, getById, getItems, getBlocks, getTemplates } from '@/api/ScenarioApi'
import { appService } from '@/services/AppService'
import { create as createScenario, TScenario } from '@/models/Scenario'

class ScenarioService {
  public state = state
  public blocklyBlocks: TObject[] = []

  /**
   */
  public async init() {
    if (state.account.admin) {
      this.loadBlocks().catch(window.logger.error)
      this.loadRefs().catch(window.logger.error)
      this.loadTemplates().catch(window.logger.error)
    }
  }

  /**
   */
  public remove = async (id: string): Promise<{ error: null | string }> => {
    const { error } = await remove(id)
    this.loadRefs().catch(window.logger.error)
    return { error }
  }

  /**
   */
  public save = async (item: TScenario): Promise<{ error: null | string, scenario: null | TScenario }> => {
    const { error, data: { scenario } } = await save(item)
    this.loadRefs().catch(window.logger.error)
    return { error, scenario }
  }

  /**
   */
  public loadItem = async (id?: string) => {
    this.state.scenarios.detail.isLoading = true
    if (id) {
      const { data: { scenario } } = await getById(id)
      this.state.scenarios.detail.item = scenario || createScenario({})
    } else {
      this.state.scenarios.detail.item = createScenario({})
    }
    this.state.scenarios.detail.isLoading = false
  }

  /**
   */
  public async loadItems(page?: number, size?: number): Promise<void> {
    this.state.scenarios.list.isLoading = true
    if (page) { this.state.scenarios.list.options.page = page }
    if (size) { this.state.scenarios.list.options.itemsPerPage = size }
    try {
      const sortField = this.state.scenarios.list.options.sortBy[0]
      const sortDir = this.state.scenarios.list.options.sortDesc[0]
      const { error, data: { items, total } } = await getItems(
        this.state.scenarios.list.options.page,
        this.state.scenarios.list.options.itemsPerPage,
        sortField,
        sortDir ? 'desc' : 'asc',
      )
      if (error) {
        appService.showError(error)
      } else {
        this.state.scenarios.list.items = items
        this.state.scenarios.list.total = total
        this.state.scenarios.list.totalPages = Math.ceil(total / this.state.scenarios.list.options.itemsPerPage)
      }
    } catch (e) {
      window.logger.error(e)
      appService.showError()
    }
    this.state.scenarios.list.isLoading = false
  }

  /**
   */
  public async loadBlocks(): Promise<void> {
    const { data: { blocks } } = await getBlocks()
    this.blocklyBlocks = blocks.data
  }

  /**
   */
  public async loadRefs(): Promise<void> {
    const { data: { items } } = await getItems(1, 100)
    if (items) {
      this.state.scenarios.refs = items.map(i => ({
        value: i.id,
        label: i.name,
        description: i.description,
        account_uid: i.account_uid,
        account_profile: i.account_profile,
      }))
    }
  }

  /**
   */
  private async loadTemplates(): Promise<void> {
    const { data: { templates } } = await getTemplates()
    this.state.scenarios.templates = templates
  }
}

const scenarioService = new ScenarioService()
export { scenarioService, ScenarioService }

import { parseDate } from '@/lib/utils'
import { validateFields } from '@/lib/validate'

const initialData = {
  id: '',
  uid: '',
  amount: 0,
  type: '',
  details: {},
  date: null as null | Date,
}

export type THistoryItem = typeof initialData

export const map = (data: TObject): THistoryItem => {
  validateFields(data, ['id', 'uid', 'amount', 'type', 'details', 'timestamp'])

  return {
    ...initialData,
    details: { ...initialData.details },
    ...data,
    amount: parseFloat(data.amount) || 0,
    date: parseDate(data.timestamp),
  }
}

<template>
  <LayoutAuth class="root" :title="$t('forgot.recoverPassword')">
    <CodeVerify
        v-if="verify"
        :isLoading="isLoading"
        :verifyError="verifyError"
        :buttonLabel="$t('forgot.recoverPassword')"
        @cancel="verify = false"
        @verify="onVerify"
    />

    <v-form v-else @submit.prevent="onSubmit" novalidate="true">
      <v-text-field
          outlined
          dense
          hide-details
          autofocus
          :label="$t('common.email')"
          type="text"
          class="root__input"
          :error="$v.email.$anyError"
          v-model.trim="email"
          @change="setField('email', $event)"
      />

      <v-text-field
          outlined
          dense
          hide-details
          :label="$t('common.newPassword')"
          type="password"
          class="root__input"
          autocomplete="new-password"
          :error="$v.password.$anyError || !!authError"
          v-model.trim="password"
          @change="setField('password', $event)"
      />

      <v-alert
          v-if="!!authError"
          border="left"
          colored-border
          type="error"
          elevation="2"
      >
        {{authError}}
      </v-alert>

      <AppButton
          block
          submit
          type="primary"
          :disabled="$v.$invalid"
          :isLoading="isLoading"
          :dense="false"
      >
        {{$t('forgot.recoverPassword')}}
      </AppButton>
      <AppButton
          block
          class="root__cancel"
          type="gray"
          :dense="false"
          @click="$router.push(paths.PATH_PAGE_SIGN_IN)"
      >
        {{$t('common.cancel')}}
      </AppButton>
    </v-form>
  </LayoutAuth>
</template>

<script>
import LayoutAuth from '@/components/shared/layout/Auth.vue'
import CodeVerify from '@/components/shared/CodeVerify.vue'
import { required, email, minLength } from 'vuelidate/lib/validators'
import { authService } from '@/services/AuthService'
import { config } from '@/config/config'
import * as paths from '@/config/paths'

export default {
  name: 'ViewForgot',

  components: {
    LayoutAuth,
    CodeVerify,
  },

  data() {
    return {
      paths,
      authError: null,
      verify: false,
      verifyError: false,
      email: '',
      password: '',
      isLoading: false,
    }
  },

  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(config.minPasswordLength),
    },
  },

  methods: {
    setField(field, value) {
      this[field] = value
      this.$v[field].$touch()
      this.authError = null
    },

    async onSubmit() {
      this.$v.$touch()
      if (!this.$v.$anyError) {
        this.isLoading = true
        const { error } = await authService.recoverPassword(this.email)
        this.isLoading = false
        this.authError = error
        this.verify = !error
        this.$v.$reset()
      }
    },

    async onVerify(code) {
      this.isLoading = true
      this.verifyError = !await authService.recoverPasswordVerify(this.email, code, this.password)
      this.isLoading = false
    },
  },
}
</script>

<style lang="sass" scoped>
.root
  &__input.root__input
    margin-bottom: 16px

  &__cancel
    margin-top: 8px
</style>

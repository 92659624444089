export const messages = {
  en: {
    common: {
      email: 'EMail',
      password: 'Password',
      newPassword: 'New Password',
      save: 'Save',
      edit: 'Edit',
      reset: 'Reset',
      delete: 'Delete',
      cancel: 'Cancel',
      apply: 'Apply',
      verify: 'Verify',
      search: 'Search',
      clear: 'Clear',
      create: 'Create',
      update: 'Update',
      admin: 'Administrator',
      adminShort: 'Admin',
      user: 'User',
      home: 'Home',
      id: 'Id',
      ok: 'OK',
      date: 'Date',
      name: 'Name',
      number: 'Number',
      searchNumber: 'Search Number',
      filters: 'Filters',
      dateFrom: 'From Date',
      dateTo: 'To Date',
      accountNumber: 'Your Direct Number',
      personalNumber: 'Direct Number',
      description: 'Description',
      firstName: 'First Name',
      lastName: 'Last Name',
      verifyText: 'We have emailed you a verification code. Please check your inbox and enter the code below.',
      verifyLimit: 'Verification attempts limit exceeded',
      loading: 'Loading…',
      empty: 'Empty',
      trial: 'Trial',
      trialUntil: 'Expiration at {time} on {date}',
      trialExpired: 'Warning! Trial Mode expired.',
      continue: 'Continue',
      finish: 'Finish',
      confirm: 'Confirm',
      buy: 'Buy',
      timezone: 'Timezone',
      timeformat: 'Time Format',
      systemError: 'System Error',
      terms: 'Terms And Conditions',
      privacy: 'Privacy Policy',
      back: 'Back',
      variableName: 'Variable Name',
      type: 'Type',
      copiedToClipboard: 'Copied to clipboard!',
      download: 'Download',
      more: 'More',
      upload: 'Upload',
      uploadFileTooBig: 'File is too big. Please provide image under {size}MB',
      scope: 'Scope',
      callerID: 'Preferred Caller ID',
    },
    notFound: {
      subHeading: 'The page you\'re trying to access doesn\'t exist.',
      backHome: 'Back to Home',
    },
    signIn: {
      title: 'Sign In',
      newAccount: 'New User',
    },
    signUp: {
      title: 'Sign Up',
    },
    forgot: {
      title: 'Forgot Password',
      recoverPassword: 'Reset Password',
    },
    invited: {
      title: 'Invite',
      join: 'Join',
    },
    onboarding: {
      titleWelcome: 'Welcome aboard!',
      textWelcome: 'Now we need your credit card information. Next, you will be able to start your 7 day free Trial or select a billing plan for your organization.',
      titleDataplan: 'Select Billing Plan',
      titlePlansShort: 'Plans',
      titleDidNumber: 'Main Auto-Attendant Phone Number',
      textDidNumber: 'Select either a phone number provided by us or use your own SIP-enabled number. Choose our phone number if unsure.',
      titleSipUrl: 'External SIP-enabled phone number',
      textSipUrl: 'Use the SIP URL below to forward calls. Generally, you should copy and paste this URL to your SIP phone number provider settings.',
      selectDataplan: 'Select Billing Plan',
      notEnough: 'Insufficient funds',
      addCard: 'Add Card',
      anotherCard: 'Enter another card',
    },
    navigation: {
      dashboard: 'Dashboard',
      home: 'Home',
      numbers: 'Numbers',
      scenarios: 'Dial Plans',
      billing: 'Billing',
      callHistory: 'Call History',
      faxes: 'Faxes',
      users: 'Users',
      settings: 'Profile',
      logout: 'Logout',
    },
    billing: {
      titleAddPayment: 'Add Payment',
      addPaymentText: 'Please enter your preferred payment method below. You can use a credit or a debit card, all major cards accepted.',
      paymentMethod: 'Payment Method',
      addCard: 'Add Card',
      expires: 'Expires',
      default: 'default',
      setDefault: 'Set as default',
      delete: 'Delete',
      titleBalance: 'Balance',
      addFunds: 'Add Funds',
      plan: 'Billing Plan',
      activePlan: 'Current Plan',
      changePlan: 'Change Plan',
      selectPlan: 'Select Plan',
      renew: 'Renew',
      notEnough: 'Insufficient funds',
      confirmExtraMinutes: 'Are you sure you want to buy extra minutes?',
      confirmExpensive: 'Confirm you want to select the <b>{name}</b> plan.',
      confirmCheaper: 'Confirm your plan change to <b>{name}</b>. The new plan will start on {date}.',
      confirmCancel: 'Your subscription ends on {date}, at which time you will no longer have access to the service and Callthem.app provided numbers could be released. <b>You may not be able to return them.</b><br /><br />If you will decide to renew your subscription, you must do so prior to the expiration date provided above.',
      confirmAddAndChangePlan: 'Your balance is ${value} short. Confirm you want to add the missing amount (minimum ${topUp}) and change your plan.',
      cancelSubscription: 'Cancel Subscription',
      nextPlan: 'Upcoming Plan',
      willStart: 'will start at',
      minAmount: 'Minimum amount',
      selectCard: 'Select a card',
      labelValueUsd: 'Value, USD',
      titleSettings: 'Billing Settings',
      enableText: 'Enable auto top-up',
      enableSubsText: 'Enable auto-renewal of the subscription',
      triggerText: 'When my balance falls below',
      topupText: 'Automatically top-up my account with',
      notifyText: 'Notify me by email, in case the default card cannot be charged',
      notifyEmail: 'Notify me by email',
      notifyPhone: 'Notify me by call',
      notifySms: 'Notify me by sms',
      titleHistory: 'Billing History',
      fieldAmount: 'Amount',
      fieldType: 'Operation Type',
      transactionId: 'Transaction ID',
      limitAccounts: 'Users limit',
      limitMinutesIn: 'Inbound Minutes limit',
      limitMinutesOut: 'Outbound Minutes limit',
      limitCalls: 'Incoming Calls limit',
      limitDids: 'Numbers limit',
      stats: 'Billing Plan Statistics',
      statsAccounts: 'Users',
      statsMinutes: 'Minutes',
      statsMinutesTollFree: 'Toll-Free Minutes',
      statsDids: 'Numbers',
      callHistory: 'Call History',
      dev: {
        changePlan: 'Developer account',
      },
    },
    users: {
      inviteLabel: 'Invite by email (comma delimited) or upload CSV', // not too long to fit the input label
      inviteBtn: 'Invite',
      inviteAsAdmin: 'Invite as Administrator',
      emailsParsed: 'Emails parsed',
      addedAt: 'Added at',
      reinvite: 'Reinvite',
      cancelInvite: 'Cancel Invite',
      fieldStatus: 'Status',
      dialogDeleteTitle: 'Delete User',
      dialogDeleteText: 'will be removed but their history will remain and will not be deleted.',
    },
    dids: {
      titleDetail: 'Number Detail',
      addButton: 'Add Number',
      updateButton: 'Update Number',
      dialogDeleteTitle: 'Delete Number',
      numberCreated: 'New number created',
      numberUpdated: 'Number updated',
      numberDeleted: 'Number deleted',
      dialogDeleteText: 'Are you sure you want to delete the number:',
      originator: 'Type',
      sipUrl: 'SIP URL',
      sipUrlTitle: 'SIP URL for {number}',
      number: 'Phone #',
      internal: 'Callthem.app provided number',
      external: 'Your SIP phone number',
      area: 'Area Code',
      select: 'Select a number',
      selectUser: 'Select a user',
      tollFree: 'Toll-free',
      tollFreeNumber: 'Toll-free number',
      tollFreeNumberNA: 'Toll-free number (Not Available in Trial)',
      tollFreePrefixChoose: 'Choose your toll-free prefix',
      dedicated: 'Direct number',
      changeDialPlan: 'Change Dial Plan',
      deleteNumber: 'Delete Phone Number',
      searching: 'Searching...',
      try: 'try {count} of {total}',
      notFound: 'No available numbers were found. Please try again later or select another number prefix.',
      fax: 'Fax',
      assignedTo: 'Assigned To',
      main: 'Main',
      direct: 'Direct',
      byCallthemApp: 'by Callthem.app',
      local: 'Local',
      callTreatment: 'Call Treatment',
      transferTo: 'Transfer to',
      faxTo: 'Fax to',
    },
    scenarios: {
      titleDetail: 'Dial Plan Detail',
      scenarioDescription: 'Dial Plan Description',
      addButton: 'Add Dial Plan',
      dialogDeleteTitle: 'Delete Dial Plan',
      dialogDeleteText: 'Are you sure you want to delete the dial plan:',
      scenario: 'Dial Plan',
      numbersReassign: 'The following numbers will be reassigned to the default dial plan',
      editPlan: 'Edit Dial Plan',
      deletePlan: 'Delete Dial Plan',
      error: 'Dial Plan Error',
      selectPlan: 'Select Dial Plan',
      invalidDialPlan: 'Invalid Dial Plan',
      saving: 'Dial Plan Saving',
      savingError: 'Can\'t save this dial plan',
      createNewVariable: 'Create new variable',
      renameVariable: 'Rename variable',
      messages: {
        blocksConnectedError: 'Please connect all blocks into one flow',
        notClosedError: 'Hang up, transfer or restart block required',
        notOpenedError: 'Dial plan should start with the answer block',
      },
      scopeOptions: {
        title: 'Scope Options',
        promptsLabel: 'Select Prompts Language',
        timeZone: 'Select Dial Plan Timezone',
        runtimeVariable: 'Reserved variables',
        recordCalls: 'Record transferred calls',
      },
      nameOrDescription: 'Name or description',
      uploadFileTooBig: 'Please provide file under {size}',
    },
    faxes: {
      received: 'Received',
      from_number: 'From',
      to_number: 'To',
      direction: 'Direction',
      page_count: 'Page Count',
      download: 'Download Fax',
      delete: 'Delete Fax',
      dialogDeleteTitle: 'Delete Fax',
      dialogDeleteText: 'Are you sure you want to delete the fax from: ',
      noFaxes: 'You have no faxes',
    },
    settings: {
      titleProfile: 'Profile',
    },
    dataplan: {
      title: 'Select your Billing Plan',
      monthly: 'Monthly',
      annual: 'Annually',
      trialHint: 'Restrictions applied. 7 days for the Trial.',
      accounts: 'Employees connected',
      dids: 'Inbound phone numbers',
      calls: 'Simultaneous incoming calls',
      minutes: 'Minutes',
      minutesPerMonth: 'Minutes per month',
      monthPerUser: 'month per user',
      minutesIn: 'Inbound minutes',
      minutesOut: 'Outbound minutes',
      current: 'Current',
      custom: 'Custom',
      price: 'Price',
      callUs: 'Call Us',
      select: 'Select',
      expired: 'Your subscription has expired',
      expiredTrial: 'Your Trial has expired',
      addFunds: 'Add funds',
      expireSoonAndNoFunds: 'Your subscription will be expired soon. Callthem.app provided numbers could be released if the subscription is not renewed. Please add funds to your account.',
      day: 'day',
      days: 'days',
      week: 'week',
      month: 'month',
      opt1: 'Unlimited users',
      opt2: 'Unlimited IVR',
      opt3: '24x7 email support',
      opt4: 'Mobile application',
      opt5: 'High-definition (HD) audio',
      opt6: 'Receive unlimited faxes',
      opt7: 'Unlimited VoIP calls',
      opt8: 'Intelligent Auto Attendant',
      callMinutes: 'Calls within US/CA',
      freeMinutes: 'Free minutes',
      sms: 'SMS',
      unlimitedMinutes: 'Unlimited calls within US/CA',
      unlimitedSMS: 'Unlimited SMS',
      minutesShort: 'min',
    },
    callog: {
      date: 'Date',
      scenario: 'Dial Plan',
      begin: 'Call Start',
      end: 'Call End',
      duration: 'Duration (min)',
      direction: 'Direction',
      hangupCause: 'Call Outcome',
      fromNumber: 'From Number',
      toNumber: 'To Number',
      redirectNumber: 'Transferred to',
      userDeleted: 'User deleted',
      totalMin: 'Total minutes',
      totalTFMin: 'Total toll-free minutes',
      filter1: 'Today',
      filter2: 'Yesterday',
      filter3: 'Last week',
      filter4: 'Last month',
      hangups: {
        NORMAL_CLEARING: 'Caller Disconnected',
        NORMAL_UNSPECIFIED: 'Caller Disconnected',
        ORIGINATOR_CANCEL: 'Caller Disconnected',
        USER_BUSY: 'Busy',
        DESTINATION_OUT_OF_ORDER: 'No Answer',
      },
      noLogs: 'You have no calls',
      record: 'Call record',
    },
    cookie: {
      banner: 'We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services. You consent to our cookies if you continue to use our website.',
    },
  },
}

import { TPrompt } from '@/models/Prompt'
import { state } from '@/store/state'
import { getItems, remove, upload } from '@/api/PromptApi'
import { appService } from '@/services/AppService'

class PromptService {
  public state = state

  /**
   */
  public async loadItems(page?: number, size?: number): Promise<void> {
    this.state.prompts.list.isLoading = true
    if (page) { this.state.prompts.list.options.page = page }
    if (size) { this.state.prompts.list.options.itemsPerPage = size }
    try {
      const sortField = this.state.prompts.list.options.sortBy[0]
      const sortDir = this.state.prompts.list.options.sortDesc[0]
      const { error, data: { items, total } } = await getItems(
        this.state.prompts.list.options.page,
        this.state.prompts.list.options.itemsPerPage,
        sortField,
        sortDir ? 'desc' : 'asc',
        this.state.prompts.list.options.search,
      )
      if (error) {
        appService.showError(error)
      } else {
        this.state.prompts.list.items = items
        this.state.prompts.list.total = total
        this.state.prompts.list.totalPages = Math.ceil(total / this.state.prompts.list.options.itemsPerPage)
      }
    } catch (e) {
      window.logger.error(e)
      appService.showError()
    }
    this.state.prompts.list.isLoading = false
  }

  public async deleteItem(id: string): Promise<{error: null | string}> {
    let error = null
    this.state.prompts.list.isLoading = true
    try {
      const result = await remove(id)
      error = result.error
    } catch (e) {
      window.logger.error(e)
      appService.showError()
    }
    this.state.prompts.list.isLoading = false
    return { error }
  }

  public async uploadItem(formdata: HTMLFormElement, onUploadProgress?: void):
    Promise<{error: null | string, prompt: null | TPrompt}> {
      this.state.prompts.list.isLoading = true
      const { error, data: { prompt } }  = await upload(formdata, onUploadProgress)
      this.state.prompts.list.isLoading = false
      return { error, prompt }
  }

}

const promptService = new PromptService()
export { promptService, PromptService }

<template>
  <div :class="['blocklyRoot', full && 'full']">
    <div class="blocklyDiv" ref="blocklyDiv">
      <div
        id="blocklyOverlay"
        v-if="overlay"
        @click="closeExtension"
      >
        <AppLoader v-if="saving" />
      </div>
      <FlowSpeak
        class="extension"
        v-if="this['extension:audiolib']"
        :top="extensionTop"
        :left="extensionLeft"
        :extensionBlock="extensionBlock"
        @exit="closeExtension('extension:audiolib')"
      />
    </div>
    <xml ref="blocklyToolbox" style="display:none">
      <slot></slot>
    </xml>
    <div class="expCol">
      <AppButton
          class="d-btn"
          type="gray"
          @click="toggle"
      >
        <v-icon>{{full ? icons.mdiArrowCollapseAll : icons.mdiArrowExpandAll}}</v-icon>
      </AppButton>
      <AppButton
          class="ml-2 reset"
          :type="scopeOptions ? 'primary' : 'gray'"
          @click="onOptions"
      >
        {{$t('common.scope')}}
      </AppButton>
      <v-expand-transition>
        <div
          v-if="scopeOptions"
          id="scope-menu"
        >
        <v-card elevation="0">
          <v-card-text>
            <div class="d-flex justify-space-between mb-5">
              <span class="text-h6">{{$t('scenarios.scopeOptions.title')}}</span>
              <v-icon @click="onCloseScopeOptions">{{icons.mdiClose}}</v-icon>
            </div>
          <v-list-item two-line>
            <v-list-item-content>
              <v-select
                v-model="promptLangSelect"
                :items="languages"
                class="mt-2"
                outlined
                hide-details
                item-text="label"
                item-value="value"
                :label="$t('scenarios.scopeOptions.promptsLabel')"
                @input="setPromptLang"
                @click="clearPromptLang"
              >
              </v-select>
              <v-autocomplete
                outlined
                hide-details
                clearable
                class="mt-2"
                :label="$t('scenarios.scopeOptions.timeZone')"
                :items="timezones"
                item-text="label"
                item-value="value"
                v-model="meta.timeZone"
                @input="$emit('meta', { timeZone: $event })"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-switch
                class="ml-3 mt-0"
                inset
                :label="$t('scenarios.scopeOptions.recordCalls')"
                v-model="meta.recordSession"
                @change="$emit('meta', { recordSession: $event })"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>
                {{$t('scenarios.scopeOptions.runtimeVariable')}}:
              </v-list-item-title>
              <v-chip-group column>
                <v-chip class="ma-2 chip-var" @click="createVariable('callerNumber')">callerNumber</v-chip>
                <v-chip class="ma-2 chip-var" @click="createVariable('dialedNumber')">dialedNumber</v-chip>
                <v-chip class="ma-2 chip-var" @click="createVariable('areaCode')">areaCode</v-chip>
                <v-chip class="ma-2 chip-var" @click="createVariable('lastInput')">lastInput</v-chip>
              </v-chip-group>
            </v-list-item-content>
          </v-list-item>
          </v-card-text>
        </v-card>
        </div>
      </v-expand-transition>
      <AppButton
          class="ml-2 reset d-btn"
          type="gray"
          :disabled="undoStack.length === 0"
          @click="undo()"
      >
        <v-icon>{{icons.mdiUndo}}</v-icon>
      </AppButton>
      <AppButton
          class="ml-2 reset d-btn"
          type="gray"
          :disabled="redoStack.length === 0"
          @click="undo(true)"
      >
        <v-icon>{{icons.mdiRedo}}</v-icon>
      </AppButton>
      <AppButton
          class="ml-2"
          type="primary"
          submit
          @click="onSubmit()"
          v-if="hasAccess('admin')"
          :disabled="!changed && undoStack.length === 0 || !!state.scenarios.detail.item.account_id"
          :isLoading="saving"
      >
      {{$t('common.save')}}
      </AppButton>
      <AppDialog ref="createVariable">
        <v-text-field
            class="ml-4 mr-4"
            slot="content"
            outlined
            hide-details
            autofocus
            type="text"
            :label="$t('common.variableName')"
            v-model.trim="userVariableName"
        />
      </AppDialog>
    </div>
  </div>

</template>

<script>
import Blockly from 'blockly'
import 'blockly-field-date'
import 'blockly-field-text-box'
import 'blockly-field-player'
import FlowSpeak from './extensions/flowSpeak/FlowSpeak.vue'
import { mdiArrowExpandAll, mdiArrowCollapseAll, mdiUndo, mdiRedo, mdiClose } from '@mdi/js'
import { scenarioService } from '@/services/ScenarioService'
import { state } from '@/store/state'
import { PATH_PAGE_SCENARIOS } from '@/config/paths'
import { hasAccess } from '@/lib/access'

window.Blockly = Blockly

export default {
  name: 'Blockly',

  components: {
    FlowSpeak,
  },

  props: {
    scheme: String,
    meta: Object,
    options: { type: Object, required: true },
    changed: Boolean,
  },

  data() {
    return {
      state,
      full: false,
      workspace: null,
      userVariableName: null,
      undoStack: [],
      redoStack: [],
      startBlock: { x: 0, y: 0, id: null },
      startPoint: Object.freeze({ x: 20, y: 20 }),
      scopeOptions: false,
      icons: {
        mdiArrowExpandAll,
        mdiArrowCollapseAll,
        mdiUndo,
        mdiRedo,
        mdiClose,
      },
      hideScrollBars: false,
      promptLangSelect: null,
      extensionTop: 0,
      extensionLeft: 0,
      extensionBlock: null,
      'extension:audiolib': null,
      extensions: Object.freeze([
        'extension:audiolib',
      ]),
    }
  },

  beforeMount() {
    this.full = localStorage.getItem('builder:fullscreen') === 'true'
  },

  mounted() {
    this.render()
    if (this.scheme) {
      this.setXml(this.scheme)
    }
  },

  beforeDestroy() {
    if (this.workspace) {
      this.workspace.dispose()
    }
  },

  computed: {
    urlList: () => PATH_PAGE_SCENARIOS,
    languages() { return this.state.languages.map(i => ({ label: i[0], value: i[1] })) },
    timezones() { return this.state.timezones.map(i => ({ value: i, label: i })) },
    saving() { return this.state.scenarios.detail.isSaving },
    overlay() { return this.extensions.filter(i => this[i]).length },
  },

  methods: {
    hasAccess,

    getXml() {
      const xml = Blockly.Xml.workspaceToDom(this.workspace)
      return Blockly.Xml.domToText(xml)
    },

    setXml(xml) {
      const xmlDom = Blockly.Xml.textToDom(xml)
      Blockly.Xml.domToWorkspace(xmlDom, this.workspace)
    },

    searchParent(block, type) {
      if (!block || !type) {
          return
      }
      let _block = block
      while (_block) {
          _block = _block.getSurroundParent()
          if (_block && _block.type === type) {
              return _block
          }
      }
    },

    searchParents (block, type) {
      if (!block || !type) {
          return
      }
      const parents = []
      let _block = block
      while (_block) {
          _block = this.searchParent(_block, type)
          _block && parents.push(_block)
      }
      return parents
    },

    render() {
      if (this.workspace) {
        this.workspace.dispose()
      }

      const options = {
        ...this.options,

        // @see https://github.com/google/blockly/blob/master/core/theme/classic.js
        theme: {
          'blockStyles': {
            'colour_blocks': {
              'colourPrimary': '20',
            },
            'list_blocks': {
              'colourPrimary': '260',
            },
            'logic_blocks': {
              'colourPrimary': '#06B8BA',
            },
            'loop_blocks': {
              'colourPrimary': '120',
            },
            'math_blocks': {
              'colourPrimary': '230',
            },
            'procedure_blocks': {
              'colourPrimary': '290',
            },
            'text_blocks': {
              'colourPrimary': '260',
            },
            'variable_blocks': {
              'colourPrimary': '#06B8BA',
            },
            'variable_dynamic_blocks': {
              'colourPrimary': '#06B8BA',
            },
            'hat_blocks': {
              'colourPrimary': '330',
              'hat': 'cap',
            },
          },
          'fontStyle': {
            'family': 'Montserrat',
            'size': '10',
            'weight': '500',
          },
          'componentStyles': {
            'toolboxBackgroundColour': '#FFF',
            'workspaceBackgroundColour': '#FFF',
            'flyoutBackgroundColour': '#F0F4FA',
          },
        },
        disable: false,
      }

      if (!options.toolbox) {
        options.toolbox = this.$refs['blocklyToolbox']
      }
      const el = this.$refs['blocklyDiv']
      const blocks = scenarioService.blocklyBlocks || []

      // @see https://developers.google.com/blockly/guides/configure/web/toolbox

      blocks.forEach(i => {
        if (!i['serialized']) {
          Blockly.Blocks[i.type] = {
            init: function() {
              this.jsonInit(i)
            },
          }
        } else {
          Blockly.Blocks[i.type] = eval(`(${i.serialized})`)
          Blockly.Blocks[i.type].category = i.category
        }
      })

      Blockly.prompt = (text) => {
        const varName = (text.match(/(['])(.*?)\1/) || [])[2]
        this.$refs.createVariable.show({
          title: varName ? this.$t('scenarios.renameVariable') :
            this.$t('scenarios.createNewVariable'),
          actionText: this.$t('common.ok'),
          onAction: () => {
            if (this.userVariableName) {
              if (varName) {
                const _var = this.workspace.getVariable(varName) || {}
                this.workspace.renameVariableById(_var.id_, this.userVariableName)
              } else {
                this.workspace.createVariable(this.userVariableName)
              }
                this.userVariableName = null
            }
            this.$refs.createVariable.onClose()
          },
        })
      }

      const categories = {}
      for (const block of blocks) {
        if (block.hidden) {
          continue
        }
        if (!categories[block.category]) {
          categories[block.category] = {
            'kind': 'category',
            'name': block.category,
            'colour': block.colour,
            'contents': [],
          }
        }
        categories[block.category].contents.push({
          'kind': 'block',
          'type': block.type,
        })
      }

      const order = [
        'Call Flow',
        'Audio Prompt',
        'Keypad Input',
        'Time & Date',
      ]

      const blocksOrder = {
        'Audio Prompt': ['flow_speak', 'flow_language'],
      }

      for (const [i, j] of Object.entries(blocksOrder)) {
        if (categories[i]) {
          categories[i].contents.sort((a, b) => j.indexOf(a.type) - j.indexOf(b.type))
        }
      }

      options.toolbox = {
        'kind': 'categoryToolbox',
        'contents': [
          ...Object.values(categories).sort((a, b) =>
            order.indexOf(a.name) - order.indexOf(b.name)),
          {
            'kind': 'category',
            'name': 'Logic',
            'colour': '#06B8BA',
            'contents': [
              { 'kind': 'block', 'type': 'controls_if' },
              { 'kind': 'block', 'type': 'logic_compare' },
              { 'kind': 'block', 'type': 'logic_operation' },
              { 'kind': 'block', 'type': 'logic_negate' },
              { 'kind': 'block', 'type': 'logic_boolean' },
            ],
          },
          {
            'kind': 'category',
            'name': 'Value',
            'colour': '260',
            'contents': [
              { 'kind': 'block', 'type': 'text' },
            ],
          },
          {
            'kind': 'category',
            'name': 'Variables',
            'colour': '#06B8BA',
            'custom': 'VARIABLE',
          },
          {
            'kind': 'sep',
          },
          {
            'kind': 'category',
            'name': 'Templates',
            'colour': '#5577EE',
            'contents': (this.state.scenarios.templates || []).filter(i => i.scheme).map(i => ({
              'kind': 'category',
              'name': i.name,
              'contents': [
                {
                  'kind': 'block',
                  'blockxml': i.scheme.replace(/<.?xml.*?>/g, ''),
                },
              ],
            })),
            'cssConfig': {
              'selected': 'selectedTemplates',
              'openicon': 'toolBoxOpenIcon',
              'closedicon': 'toolBoxClosedIcon',
            },
          },
        ],
      }
      options.renderer = 'zelos'

      options.move = {
        scrollbars: {
          horizontal: true,
          vertical: true,
        },
        drag: true,
        wheel: false,
      }

      options.zoom = {
        controls: true,
        wheel: true,
        startScale: 1.0,
        maxScale: 3,
        minScale: 0.3,
        scaleSpeed: 1.2,
        pinch: true,
      }

      options.grid = {
        colour: '#F0F4FA',
      }

      this.workspace = Blockly.inject(el, options)
      this.workspace.scrollbar.setContainerVisible(!this.hideScrollBars)
      this.workspace.addChangeListener((event) => {
        if (event.type === Blockly.Events.FINISHED_LOADING) {
          this.workspace.clearUndo()
          this.resetView()
        }

        if (event.recordUndo) {
          this.undoStack = this.workspace.getUndoStack()
          this.redoStack = this.workspace.getRedoStack()
        }

        if (event.type.indexOf('extension:') > -1) {
          if (this[event.type] !== undefined) {
            const { field, bound } = event.detail
            const { top, left } = this.$refs['blocklyDiv'].getBoundingClientRect()
            this.extensionBlock = field.getSourceBlock()
            this.extensionTop = Math.abs(top - (bound?.top || 0))
            this.extensionLeft = Math.abs(left - (bound?.left || 0))
            this[event.type] = true
          }
        }

        if (event.type === 'click') {
          this.extensions.forEach(i => this[i] = false)
        }

        const { blockId } = event
        const block = this.workspace.getBlockById(blockId)

        if (block) {
          if (
            event.type === Blockly.Events.BLOCK_CHANGE &&
            block.type === 'flow_ivr' &&
            event.name === 'tag_label'
          ) {
            this.workspace.undoStack_.pop()
          }

          if (event.type === Blockly.Events.BLOCK_DRAG && !event.isStart) {
              const ivrs = event.blocks.filter(i => i.type === 'flow_ivr')
              const restarts = event.blocks.filter(i => i.type === 'flow_restart')
              const firstRestart = restarts[0] && restarts[0]
              if (ivrs.length || restarts.length) {
                  const parents = this.searchParents(block, 'flow_ivr')
                  if (parents.length) {
                      parents.find(i => i.tag) && ivrs.forEach(i => i.clearTag())
                      firstRestart && firstRestart.validateTree()
                  }
                  if (firstRestart && firstRestart.getParent()) {
                      restarts.forEach(i => i.reshape(!!this.searchParent(i, 'flow_ivr')))
                  }
              }
          }

          if (block.type === 'flow_restart') {
              if (event.type === Blockly.Events.BLOCK_CHANGE) {
                  block.validateTree()
              }
              if (
                event.type === Blockly.Events.BLOCK_MOVE &&
                !event.oldParentId &&
                event.newParentId
              ) {
                  const extended = !!this.searchParent(block, 'flow_ivr')
                  block.reshape(extended)
              }
          }
        }
      })
    },

    resetView() {
      const topBlocks = this.workspace.getTopBlocks()
      this.startBlock.id = null
      for (const block of topBlocks) {
        const { x, y } = block.getRelativeToSurfaceXY()
        if (!this.startBlock.id || x < this.startBlock.x && y < this.startBlock.y) {
          this.startBlock.x = x
          this.startBlock.y = y
          this.startBlock.id = block.id
        }
      }

      if (this.startBlock.id) {
        const block = this.workspace.getBlockById(this.startBlock.id)
        if (block) {
          const { x, y } = block.getRelativeToSurfaceXY()
          this.workspace.scroll(-x + this.startPoint.x, -y + this.startPoint.y)
        }
      }
    },

    toggle() {
      this.full = !this.full
      localStorage.setItem('builder:fullscreen', this.full)
      this.$nextTick(() => {
        Blockly.svgResize(this.workspace)
        Blockly.DropDownDiv.hideWithoutAnimation()
      })
    },

    undo(redo = false) {
      if (this.workspace) {
        this.workspace.undo(redo)
      }
    },

    isValidWorkspace() {
      const topBlocks = this.workspace.getTopBlocks() || []
      if (topBlocks.length !== 1) {
        return { ok: false, message: this.$t('scenarios.messages.blocksConnectedError') }
      }
      let block = topBlocks[0], _block
      while (block) {
        _block = block.getNextBlock()
        if (!_block && block.nextConnection !== null) {
          const endBlock = this.workspace.newBlock('flow_hangup')
          endBlock.initSvg()
          endBlock.render()
          block.nextConnection.connect(endBlock.previousConnection)
        }
        block = _block
      }

      const blocks = this.workspace.getAllBlocks() || []
      for (const block of blocks) {
        if (block.previousConnection && !block.parentBlock_) {
          return { ok: false, message: this.$t('scenarios.messages.notOpenedError') }
        }
      }

      return { ok: true }
    },

    onSubmit() {
      this.$emit('submit')
    },

    onOptions() {
      this.scopeOptions = !this.scopeOptions
    },

    onCloseScopeOptions() {
      this.scopeOptions = false
    },

    setPromptLang(val) {
      const prompts = this.workspace.getAllBlocks().filter(i => i.type === 'flow_speak')
      prompts.forEach(i => i.setFieldValue(val, 'lang'))
    },

    clearPromptLang() {
      this.promptLangSelect = null
    },

    createVariable(name) {
      const block = this.workspace.newBlock('variables_get')
      const id = block.getFieldValue('VAR')
      this.workspace.renameVariableById(id, name)
      this.workspace.cleanUp()
      block.initSvg()
      block.render()
    },

    clearUndoRedoStack() {
      this.undoStack = []
      this.redoStack = []
    },

    closeExtension(ext) {
      if (ext && this[ext] !== undefined) {
        this[ext] = false
      } else {
        this.extensions.forEach(i => this[i] = false)
      }
    },
  },
}
</script>

<style lang="sass">
.blocklyTreeLabel
  font: 500 13px "Montserrat", sans-serif

.toolBoxClosedIcon,
.toolBoxOpenIcon
  margin: 0 10px
  background-image: url('../../../assets/arrow.png')
  transition: 0.2s

.selectedTemplates
  background-color: inherit !important

.toolBoxClosedIcon
  transform: rotate(180deg)

.blocklyWidgetDiv
  .blocklyMenu
    overflow: hidden !important
    padding: 0
    border: 0
    .blocklyMenuItem
      padding: 12px 18px

.blocklyFlyoutButton
  fill: #FFF
  cursor: pointer

  &:hover
    fill: #06B8BA
    .blocklyText
      fill: #EFEFEF !important

  .blocklyFlyoutButtonBackground
    height: 40px
    width: 125px

  .blocklyFlyoutButtonShadow
    display: none

  .blocklyText
    transform: translate(3px, 10px)
    fill: #333 !important

.blockly
  border: none !important

.blocklyRoot
  position: relative
  overflow: hidden

  &.full
    position: fixed
    top: 0
    left: 0
    width: 99vw !important
    height: 100vh !important
    min-height: auto
    z-index: 998

  .blocklyDiv
    height: 100%
    width: 100%
    text-align: left

  .expCol
    position: absolute
    top: 20px
    right: 20px
    padding: 2px
    cursor: pointer

  #blocklyOverlay
    width: 100%
    height: 100%
    opacity: 0.7
    position: absolute
    z-index: 9999
    top: 0px
    left: 0px
    background-color: #FFF

  .blocklyToolboxDiv
    box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 35px 0px
    border: 1px solid #EBEAE4
    padding: 0
    width: 157px !important

  .blocklyToolboxCategory
    cursor: pointer
    height: 40px

    .blocklyTreeRow,
    .blocklyTreeRowTemplates
      height: 100%
      display: flex
      align-items: center

  .blocklyToolboxCategory:hover
    background: #EBEAE4

.expCol
  .v-btn
    border-radius: 3px
    background: #FFF !important
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px !important
  .d-btn
    min-width: 40px !important

.extension
  cursor: default
  position: absolute
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px
  border-radius: 5px
  z-index: 9999

#scope-menu
  cursor: default
  position: absolute
  left: 1px
  top: 70px
  width: 380px
  z-index: 9998
  border: 1px solid #EBEAE4
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px
  border-radius: 5px

  .v-card
    background: #FFF
    opacity: 0.8

    .v-list-item__title
      font-weight: 500
      font-size: 13px

    .chip-var
      background: #06B8BA
      border: 1px solid #058A8C
      color: #FFF
      font-weight: 500
      font-size: 13px

  .v-select
    .v-label
      margin-left: 0 !important

</style>

<template>
  <v-snackbar
      bottom
      :color="state.layout.snackbar.color"
      :value="!!state.layout.snackbar.message"
      :timeout="-1"
  >
    {{state.layout.snackbar.message}}
    <template v-slot:action="{ attrs }">
      <v-btn
          text
          color="white"
          v-bind="attrs"
          @click="close"
      >
        {{ $t('common.ok') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>

import { state } from '@/store/state'
import { mdiClose } from '@mdi/js'

export default {
  name: 'MainSnackbar',

  data() {
    return {
      state,
      icons: {
        mdiClose,
      },
    }
  },
  methods: {
    close() {
      this.state.layout.snackbar.message = ''
    },
  },
}
</script>

<style lang="sass">
  .v-snack__wrapper
    margin-bottom: 20px !important
</style>

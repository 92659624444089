import axios, { AxiosInstance, AxiosRequestConfig, Method } from 'axios'

export interface IResponse<T extends TObject = TObject> {
  status: number
  error: null | string
  data: T
}

export class Request {
  public headers: TObject = {}
  private readonly client: AxiosInstance

  public constructor() {
    this.client = axios.create()
    this.setHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
    })
  }

  public async get<T = TObject>(url: string, payload = {}, options?: Record<string, unknown>): Promise<IResponse<T>> {
    return this.do<T>('GET', url, payload, { ...options })
  }

  public async post<T = TObject>(url: string, payload = {}, options?: Record<string, unknown>): Promise<IResponse<T>> {
    return this.do<T>('POST', url, payload, { ...options })
  }

  public async put<T = TObject>(url: string, payload = {}): Promise<IResponse<T>> {
    return this.do<T>('PUT', url, payload)
  }

  public async delete<T = TObject>(url: string, payload = {}): Promise<IResponse<T>> {
    return this.do<T>('DELETE', url, payload)
  }

  public setHeaders(headers: TObject): void {
    this.headers = { ...this.headers, ...headers }
  }

  public async do<T>(method: Method, url: string, payload: TObject, _options?: Record<string, unknown>): Promise<IResponse<T>> {
    const options: AxiosRequestConfig = {
      method,
      url,
      headers: this.headers,
      params: {},
      data: {},
      withCredentials: true,
      ... _options,
    }

    method === 'GET' ? options.params = payload : options.data = payload

    const result: IResponse<T> = {
      status: 200,
      error: null,
      data: {} as T,
    }

    try {
      const { data } = await this.client(options)
      result.data = data

    } catch (error: any) {
      window.logger.error(method, url, error)
      result.data = error.response?.data || {}
      result.status = error.response?.status || 0
      result.error = error.response?.data?.message || error.response?.statusText || 'Server Error'
    }

    return result
  }
}

const request = new Request()
export { request }

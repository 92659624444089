import { parseDate } from '@/lib/utils'
import { validateFields } from '@/lib/validate'

export enum TDirectionType {
  TYPE_INBOUND = 'inbound',
  TYPE_OUTBOUND = 'outbound'
}

const initialData = {
  id: '',
  company_id: '',
  account_id: null as null | string,
  direction: TDirectionType.TYPE_INBOUND,
  from_number: '',
  to_number: '',
  page_count: 0,
  location: null as null | string,
  created_at: null as null | Date,
  updated_at: null as null | Date,
}

export type TFax = typeof initialData

export const create = (data?: Partial<TFax>): TFax => {
  return { ...initialData, ...data }
}

export const map = (data: TObject): TFax => {
  validateFields(data, [
    'id',
    'company_id',
    'account_id',
    'direction',
    'from_number',
    'to_number',
    'page_count',
    'location',
    'created_at',
    'updated_at',
  ])

  return {
    ...initialData,
    id: data.id || initialData.id,
    company_id: data.company_id || initialData.company_id,
    account_id: data.account_id || initialData.account_id,
    direction: data.direction || initialData.direction,
    from_number: data.from_number || initialData.from_number,
    to_number: data.to_number || initialData.to_number,
    page_count: data.page_count || initialData.page_count,
    location: data.location || initialData.location,
    created_at: parseDate(data.created_at),
    updated_at: null,
  }
}

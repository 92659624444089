var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"root row-hover",attrs:{"item-key":'id',"headers":_vm.headers,"items":_vm.state.dids.list.items,"options":_vm.state.dids.list.options,"server-items-length":_vm.state.dids.list.total,"loading":_vm.state.dids.list.isLoading,"no-data-text":_vm.$t('common.empty'),"page":_vm.state.dids.list.options.page,"hide-default-footer":""},on:{"update:options":function($event){return _vm.$set(_vm.state.dids.list, "options", $event)},"update:page":function($event){return _vm.$set(_vm.state.dids.list.options, "page", $event)}},scopedSlots:_vm._u([{key:"item.account_profile",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUserFullName(item))+" ")]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPhoneNumber(item.number))+" ")]}},{key:"item.toll_free",fn:function(ref){
var item = ref.item;
return [(item.toll_free)?_c('v-icon',{staticStyle:{"width":"1.2rem"}},[_vm._v(_vm._s(_vm.icons.mdiCheck))]):_vm._e()]}},{key:"item.sip",fn:function(ref){
var item = ref.item;
return [(item.originator === 'external')?_c('div',[_vm._v(_vm._s(item.number)+"@"+_vm._s(_vm.domain))]):_vm._e()]}},{key:"item.scenario_id",fn:function(ref){
var item = ref.item;
return [(_vm.state.account.admin && !item.fax && !item.transfer)?_c('a',{staticClass:"link",attrs:{"href":("/scenarios/" + (item.scenario_id)),"title":item.scenario_description},on:{"click":function($event){$event.preventDefault();return _vm.$router.history.push(("/scenarios/" + (item.scenario_id)))}}},[_vm._v(" "+_vm._s(_vm.$t('scenarios.scenario'))+": "+_vm._s(item.scenario_name)+" ")]):(item.fax)?_c('span',[_vm._v(" "+_vm._s(((_vm.$t('dids.faxTo')) + " " + (_vm.getUserFullName(item))))+" ")]):(item.transfer)?_c('span',[_vm._v(" "+_vm._s(((_vm.$t('dids.transferTo')) + " " + (_vm.getUserFullName(item))))+" ")]):_vm._e()]}},{key:"item.originator",fn:function(ref){
var item = ref.item;
return [(item.originator === 'internal')?_c('div',[_vm._v(" "+_vm._s(item.toll_free ? _vm.$t('dids.tollFree') : _vm.$t('dids.local'))+" ")]):(item.originator === 'external')?_c('div',[(item.originator === 'external' && _vm.hasAccess('admin'))?_c('a',{attrs:{"title":_vm.$t('dids.sipUrl')},on:{"click":function($event){return _vm.showSipUrl(item)}}},[_vm._v(" "+_vm._s(_vm.$t('dids.sipUrl'))+" ")]):_vm._e()]):_vm._e(),_c('div')]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"list-actions"},[(_vm.hasAccess('admin'))?_c('AppListingAction',{attrs:{"title":_vm.$t('dids.changeDialPlan')},on:{"click":function($event){return _vm.onEdit(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]):_vm._e(),(_vm.hasAccess('admin'))?_c('AppListingAction',{attrs:{"title":_vm.$t('dids.deleteNumber')},on:{"click":function($event){return _vm.onDelete(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")]):_vm._e()],1)]}}],null,true)}),_c('v-pagination',{attrs:{"length":_vm.state.dids.list.totalPages,"total-visible":7},on:{"input":_vm.loadItems},model:{value:(_vm.state.dids.list.options.page),callback:function ($$v) {_vm.$set(_vm.state.dids.list.options, "page", $$v)},expression:"state.dids.list.options.page"}}),_c('AppDialog',{ref:"dialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
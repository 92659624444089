<template>
  <LayoutMain class="root" :title="$t('navigation.faxes')">
    <AppCard>
      <ViewFaxesList />
    </AppCard>
  </LayoutMain>
</template>

<script>
import LayoutMain from '@/components/shared/layout/Main.vue'
import ViewFaxesList from '@/components/views/viewFaxes/List.vue'

export default {
  name: 'ViewFaxes',

  components: {
    LayoutMain,
    ViewFaxesList,
  },

  data() {
    return {
    }
  },
}
</script>

<style lang="sass" scoped>
</style>

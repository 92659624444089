<template>
  <div @click="$emit('click')" class="root">
    <v-icon
      :class="['icon', active && 'active']"
      small
    >
      {{icons.mdiChevronDown}}
    </v-icon>
  </div>
</template>

<script>
import { mdiChevronDown } from '@mdi/js'

export default {
  name: 'NavToggle',

  components: {
  },

  props: {
    active: Boolean,
  },

  data() {
    return {
      icons: { mdiChevronDown },
    }
  },
}
</script>

<style lang="sass" scoped>
.root
  cursor: pointer
  min-width: 24px
  height: 50px
  display: flex
  align-items: center
  justify-content: center
  transition: all 0.2s
  background: $default-grey
  position: relative
  border-radius: 0 30px 30px 0

  .icon
    color: #000
    transform: rotate(-90deg)
    transition: none

    &.active
      transform: rotate(90deg)
</style>

<template>
  <v-alert
      v-if="!!error"
      type="error"
  >
    {{error}}
  </v-alert>
</template>

<script>

export default {
  name: 'AppError',

  props: {
    error: String,
  },
}

</script>

<style lang="sass" scoped>
</style>

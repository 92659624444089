import { validateFields } from '@/lib/validate'

const initialData = {
  id: '',
  brand: '',
  expireMonth: '',
  expireYear: '',
  last4: '',
  isDefault: false,
  hash: '',
}

export type TPaymentMethod = typeof initialData

export const map = (data: TObject): TPaymentMethod => {
  validateFields(data, ['id', 'card'])
  validateFields(data.card || {}, ['brand', 'exp_month', 'exp_year', 'last4'])

  const item = {
    ...initialData,
    id: data.id || initialData.id,
    brand: `${data.card?.brand || initialData.brand}`.toLowerCase(),
    expireMonth: data.card?.exp_month || initialData.expireMonth,
    expireYear: data.card?.exp_year || initialData.expireYear,
    last4: data.card?.last4 || initialData.last4,
  }
  item.hash = `${item.brand}:${item.last4}:${item.expireMonth}:${item.expireYear}`
  return item
}

<template>
  <AppButton
    v-if="!extended"
    class="simple-btn"
    @click="$emit('click', $event)"
  >
    {{label}}
    <slot />
  </AppButton>

  <div
    v-else
    @click="$emit('click', $event)"
    class="extended wb"
  >
    <div class="cap">
      <div class="label">{{label}}</div>
      <div class="value">{{value}}</div>
    </div>
    <div class="btn">
      <v-icon small class="icon">{{icons.mdiPlus}}</v-icon>
    </div>
  </div>
</template>

<script>
import { mdiPlus } from '@mdi/js'

export default {
  name: 'ActionButton',

  props: {
    extended: { type: Boolean },
    disabled: Boolean,
    label: String,
    value: String,
  },

  data() {
    return {
      icons: { mdiPlus },
    }
  },
}
</script>

<style lang="sass" scoped>
.extended
  display: flex
  align-items: center
  justify-content: space-between
  height: 40px
  background: $default-grey
  border-radius: 3px
  z-index: 9
  cursor: pointer
  transition: all 0.2s

  .cap
    text-transform: uppercase
    font-size: 12px
    letter-spacing: 0.04em
    line-height: 18px
    color: #999
    font-weight: 500
    padding: 0 20px 0 20px

    .value
      color: #000

  .btn
    display: flex
    align-items: center
    justify-content: center
    width: 40px
    height: 38px
    background: var(--v-anchor-base)
    border-radius: 3px
    transition: all 0.2s

  .icon
    color: #FFF

.wb:hover
  box-shadow: none

  .btn
    background: var(--v-primary-lighten1) !important

.simple-btn
  display: flex
  align-items: center !important
  justify-content: center !important
  width: 135px
  height: 40px !important
  text-transform: none
  font-weight: 500
  font-size: 14px
  line-height: 17px
</style>

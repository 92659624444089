import clone from 'clone'
import { create as createAccount, TAccount } from '@/models/Account'
import { create as createCompany } from '@/models/Company'
import { TPaymentMethod } from '@/models/PaymentMethod'
import { THistoryItem } from '@/models/HistoryItem'
import { create as createScenario, TScenario } from '@/models/Scenario'
import { create as createDid, TDid } from '@/models/Did'
import { create as createDataplan, TDataplan } from '@/models/Dataplan'
import { TDidOption } from '@/services/DidService'
import { TScenarioTemplate } from '@/models/ScenarioTemplate'
import { createStats } from '@/models/Stats'
import { TCallLogItem } from '@/models/CallLogItem'
import { IBillingHistoryFilter, IBillingSettings, ICallLogFilter } from '@/services/BillingService'
import { TInvoice } from '@/models/Invoice'
import { TFax } from '@/models/Fax'
import { TPrompt } from '@/models/Prompt'
import { TRecord } from '@/models/Record'

const initialState = {
  isInitializing: false,
  auth: false,
  screen: {
    width: 0,
    height: 0,
  },
  account: createAccount(),
  company: createCompany(),
  timezones: [] as string[],
  timezonesLoaded: false,
  languages: [] as string[],
  languagesLoaded: false,
  layout: {
    snackbar: {
      message: '',
      color: '',
    },
    },
  billing: {
    serverError: null as null | string,
    trialFingerprint: '',
    stripe_pk: '',
    stripe: {
      customerId: '',
    },
    trial: false,
    dev: false,
    dataplan: createDataplan(),
    dataplans: [] as TDataplan[],
    paid_till: null as null | Date,
    invoice: null as null | TInvoice,
    stats: createStats(),
    balance: 0,
    paymentMethods: [] as TPaymentMethod[],
    settings: {
      auto_recharge: true,
      trigger_amount: 0,
      topup_amount: 0,
      notify: '',
      cancel_at_paid_till: false,
    } as IBillingSettings,
    history: {
      isLoading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['timestamp'],
        sortDesc: [true],
      },
      filter: {} as IBillingHistoryFilter,
      items: [] as THistoryItem[],
      total: 0,
      totalPages: 0,
    },
    callLog: {
      isLoading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
      },
      filter: {} as ICallLogFilter,
      items: [] as TCallLogItem[],
      stats: null as null | { duration_sum: number, duration_toll_free_sum: number },
      total: 0,
      totalPages: 0,
    },
  },
  users: {
    refs: [] as TAccount[],
    list: {
      isLoading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['created_at'],
        sortDesc: [true],
        search: '',
      },
      items: [] as TAccount[],
      inviteSent: [] as string[],
      total: 0,
      totalPages: 0,
    },
  },
  dids: {
    refs: [] as TDidOption[],
    list: {
      isLoading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['created_at'],
        sortDesc: [true],
      },
      items: [] as TDid[],
      total: 0,
      totalPages: 0,
    },
    detail: {
      isLoading: false,
      item: createDid(),
    },
  },
  scenarios: {
    refs: [] as TOption[],
    list: {
      isLoading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['created_at'],
        sortDesc: [true],
      },
      items: [] as TScenario[],
      total: 0,
      totalPages: 0,
    },
    detail: {
      isLoading: false,
      isSaving: false,
      item: createScenario(),
    },
    templates: [] as TScenarioTemplate[],
  },
  faxes: {
    list: {
      isLoading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['created_at'],
        sortDesc: [true],
        search: '',
      },
      items: [] as TFax[],
      total: 0,
      totalPages: 0,
    },
  },
  prompts: {
    list: {
      isLoading: false,
      options: {
        page: 1,
        itemsPerPage: 100,
        sortBy: ['created_at'],
        sortDesc: [true],
        search: '',
      },
      items: [] as TPrompt[],
      total: 0,
      totalPages: 0,
    },
  },
  records: {
    list: {
      isLoading: false,
      options: {
        page: 1,
        itemsPerPage: 100,
        sortBy: ['created_at'],
        sortDesc: [true],
        search: '',
      },
      items: [] as TRecord[],
      total: 0,
      totalPages: 0,
    },
  },
}

export type TState = typeof initialState

export const createState = (state: Partial<TState> = {}): TState => ({
  ...clone(initialState),
  ...state,
})

export const state = createState()

export interface ICountLimit {
  count: number
  limit: number
}

export interface TStats {
  accounts: ICountLimit
  minutes: ICountLimit
  dids: ICountLimit
  minutes_toll_free: ICountLimit
}

export function createStats(): TStats {
  return {
    accounts: { count: 0, limit: 0 },
    minutes: { count: 0, limit: 0 },
    dids: { count: 0, limit: 0 },
    minutes_toll_free: { count: 0, limit: 0 },
  }
}

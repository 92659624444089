<template>
  <v-card elevation="0">
    <v-card-text class="root">
      <div class="header">
        <div class="title">{{title}}</div>
        <slot name="controls" />
      </div>
      <div :class="['content', flex && ['flex', 'pa-5']]">
        <slot name="content" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'Widget',

  props: {
    title: String,
    flex: { type: Boolean, default: false },
  },

  data() {
    return {}
  },
}
</script>

<style lang="sass" scoped>
.root
  padding: 0
  border: 1px solid #DAE0EB
  border-radius: 5px

  .header
    display: flex
    align-items: center
    justify-content: space-between
    border-bottom: 1px solid #DAE0EB
    padding: 10px 20px 10px 20px
    margin: 0

    .title
      color: #000
      font-size: 16px
      line-height: 20px
      letter-spacing: 0.02em

.flex
  display: flex
  flex-wrap: wrap
  gap: 20px
</style>

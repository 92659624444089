<template>
  <LayoutMain class="root" :title="$t('navigation.numbers')">
    <span slot="controls">
      <v-btn
        fab
        small
        color="primary"
        elevation="0"
        v-if="hasAccess('admin')"
        slot="actions"
        @click="$router.push(urlCreate)"
      >
        <v-icon small>
          {{icons.mdiPlus}}
        </v-icon>
      </v-btn>
    </span>
    <AppCard>
      <ViewNumbersList />
    </AppCard>
  </LayoutMain>
</template>

<script>
import LayoutMain from '@/components/shared/layout/Main.vue'
import ViewNumbersList from '@/components/views/viewNumbers/List'
import { PATH_PAGE_NUMBERS } from '@/config/paths'
import { hasAccess } from '@/lib/access'
import { mdiPlus } from '@mdi/js'

export default {
  name: 'ViewNumbers',

  components: {
    LayoutMain,
    ViewNumbersList,
  },

  computed: {
    urlCreate: () => `${PATH_PAGE_NUMBERS}/new`,
  },

  data() {
    return {
      icons: {
        mdiPlus,
      },
    }
  },

  methods: {
    hasAccess,
  },
}
</script>

<style lang="sass" scoped>
</style>

<template>
  <BillingPlans
      :prePlan="prePlan"
      :hideTrial="hideTrial"
      :loading="loading"
      @select="onSelect"
  >
    <AppError v-if="serverError" class="error" :error="serverError" />
    <AppButton
        v-if="serverError"
        type="outlined"
        class="btn"
        @click="$emit('next', 'welcome')"
    >
      {{$t('onboarding.anotherCard')}}
    </AppButton>
  </BillingPlans>
</template>

<script>

import BillingPlans from '@/components/shared/BillingPlans'
import { billingService } from '@/services/BillingService'
import { authService } from '@/services/AuthService'
import { config } from '@/config/config'
import { state } from '@/store/state'

export default {
  name: 'OnboardingStepDataplan',

  components: {
    BillingPlans,
  },

  computed: {
    hideTrial() { return this.state.billing.trialFingerprint !== '' },
  },

  data() {
    return {
      state,
      prePlan: localStorage.getItem('prePlan') || 'pro',
      loading: false,
      serverError: '',
    }
  },

  methods: {
    async onSelect(item) {
      this.serverError = ''

      const price = item.cost
      try {
        this.loading = true
        let error = ''
        if (price) {
          const response = await billingService.addFunds(price < config.minBalanceTopUp ? config.minBalanceTopUp : price)
          error = response.error
        }

        if (error) {
          this.serverError = error
          window.logger.error(error)

        } else {
          const { error } = await billingService.changeDataplan(item.id)
          if (error) {
            this.serverError = error
            window.logger.error(error)

          } else {
            billingService.loadBalance().catch(window.logger.error)
            billingService.loadHistory().catch(window.logger.error)
            authService.loadCompany().catch(window.logger.error)
            this.$emit('next', 'didNumber')
          }
        }

      } catch (e) {
        window.logger.error(e)
      }
      this.loading = false
    },
  },
}
</script>

<style lang="sass" scoped>
.error
  width: 100%
  margin-top: 20px
  justify-self: flex-start

.btn
  text-transform: none
</style>

import { parseDate } from '@/lib/utils'
import { validateFields } from '@/lib/validate'

export enum TAccountType {
  TYPE_UNCONFIRMED = 'unconfirmed',
  TYPE_REGULAR = 'regular'
}

export type TAvatar = {
  location: string
}

const initialData = {
  id: '',
  email: '',
  type: TAccountType.TYPE_UNCONFIRMED,
  creator: false,
  admin: false,
  profile: {
    avatar: '',
    country: '',
    city: '',
    address: '',
    holder: '',
    firstName: '',
    lastName: '',
    number: '',
    timeZone: '',
    timeFormat: 'AM/PM',
    onboarding: '',
    callerID: '',
  },
  company_id: '',
  created_at: null as null | Date,
  updated_at: null as null | Date,
}

export type TAccount = typeof initialData
export type TAccountProfile = typeof initialData.profile

export type TAccountNumbers = {
  id: number,
  number: string,
  toll_free: boolean,
  created_at: Date | null,
  updated_at: Date | null,
}

export const create = (data?: Partial<TAccount>): TAccount => {
  return { ...initialData, profile: { ...initialData.profile }, ...data }
}

export const map = (data: TObject): TAccount => {
  validateFields(data, ['uid', 'email', 'type', 'profile', 'admin', 'company_id'])

  return {
    ...initialData,
    id: data.uid || initialData.id,
    email: data.email || initialData.email,
    type: data.type || initialData.type,
    profile: {
      avatar: data.profile?.avatar || initialData.profile.avatar,
      country: data.profile?.country || initialData.profile.country,
      city: data.profile?.city || initialData.profile.city,
      address: data.profile?.address || initialData.profile.address,
      holder: data.profile?.holder || initialData.profile.holder,
      firstName: data.profile?.firstName || initialData.profile.firstName,
      lastName: data.profile?.lastName || initialData.profile.lastName,
      number: data.profile?.number || initialData.profile.number,
      timeZone: data.profile?.timeZone || initialData.profile.timeZone,
      timeFormat: data.profile?.timeFormat || initialData.profile.timeFormat,
      onboarding: data.profile?.onboarding || initialData.profile.onboarding,
      callerID: data.profile?.callerID || initialData.profile.callerID,
    },
    admin: !!data.admin,
    company_id: data.company_id,
    created_at: parseDate(data.created_at),
    updated_at: parseDate(data.updated_at),
  }
}

<template>
  <LayoutMain class="root" :title="$t('navigation.billing')">
    <template slot="controls">
      <Balance />
    </template>
    <v-card class="mb-2" elevation="0">
      <div class="content">
        <Plan />
        <ViewBillingAddPayment />
        <ViewBillingSettings />
        <ViewBillingHistory />
      </div>
    </v-card>
  </LayoutMain>
</template>

<script>
import { state } from '@/store/state'
import { hasAccess } from '@/lib/access'
import LayoutMain from '@/components/shared/layout/Main.vue'
import ViewBillingAddPayment from '@/components/views/viewBilling/AddPayment.vue'
import Plan from '@/components/views/viewBilling/balanceAndPlan/Plan.vue'
import ViewBillingSettings from '@/components/views/viewBilling/Settings.vue'
import ViewBillingHistory from '@/components/views/viewBilling/History.vue'
import { billingService } from '@/services/BillingService'
import Balance from '@/components/views/viewBilling/balanceAndPlan/Balance.vue'

export default {
  name: 'ViewBilling',

  components: {
    LayoutMain,
    ViewBillingAddPayment,
    Plan,
    ViewBillingSettings,
    ViewBillingHistory,
    Balance,
  },

  beforeMount() {
    billingService.loadBalance().catch(window.logger.error)
  },

  data() {
    return {
      state,
      hasAccess,
    }
  },
}
</script>

<style lang="sass" scoped>
.content
  display: flex
  flex-direction: column
</style>

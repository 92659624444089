<template>
  <div class="block-row">
    <v-chip v-if="value" class="chip" small label>
      {{value}}
    </v-chip>
    <v-icon class="icon">{{icons.mdiCheck}}</v-icon>
    <div class="label">
      <span>{{label}}</span>
    </div>
  </div>
</template>

<script>
import { mdiCheck } from '@mdi/js'

export default {
  name: 'BlockRow',

  components: {
  },

  props: {
    label: String,
    value: [String, Number],
  },

  data() {
    return {
      icons: { mdiCheck },
    }
  },
}
</script>

<style lang="sass" scoped>
.block-row
  display: flex
  align-items: center
  gap: 10px
  height: 35px
  font-size: 13px
  line-height: 34px
  letter-spacing: 0.02em
  font-weight: 500
  position: relative

  .label
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

  .value
    margin-left: 5px

  .icon
    width: 12px
    color: var(--v-primary-base)

  .chip
    background: var(--v-primary-base) !important
    color: #FFF
    font-size: 0.8rem
    font-weight: 600
    box-shadow: 0px 7px 10px rgba(12, 37, 74, 0.15)
    position: absolute
    padding: 5px
    right: 202px
    z-index: 999
</style>


<style lang="sass">
.block:hover,
.preselected
  .icon
    color: #FFF !important
  .chip
    background: #FFF !important
    color: #3AB9D3 !important
</style>

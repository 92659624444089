<template>
  <LayoutMain class="root" :title="$t('navigation.settings')">
    <ViewSettingsProfile />
  </LayoutMain>
</template>

<script>
import LayoutMain from '@/components/shared/layout/Main.vue'
import ViewSettingsProfile from '@/components/views/viewSettings/Profile.vue'

export default {
  name: 'ViewProfile',

  components: {
    LayoutMain,
    ViewSettingsProfile,
  },

  data() {
    return {
    }
  },
}
</script>

<style lang="sass" scoped>
</style>

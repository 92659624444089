<template>
  <LayoutAuth class="root" :title="$t('signIn.title')">
    <v-form @submit.prevent="onSubmit" novalidate="true">
      <v-text-field
          outlined
          autofocus
          hide-details
          type="text"
          :label="$t('common.email')"
          class="root__input"
          :error="$v.email.$anyError || !!authError"
          v-model.trim="email"
          @change="setField('email', $event)"
      />

      <v-text-field
          outlined
          hide-details
          :label="$t('common.password')"
          type="password"
          class="root__input"
          autocomplete="new-password"
          :error="$v.password.$anyError || !!authError"
          v-model.trim="password"
          @change="setField('password', $event)"
      />

      <v-alert
          v-if="!!authError"
          border="left"
          colored-border
          type="error"
          elevation="2"
      >
        {{authError}}
      </v-alert>

      <AppButton
          block
          submit
          type="primary"
          :isLoading="isLoading"
          :dense="false"
      >
        {{$t('signIn.title')}}
      </AppButton>
      <AppButton
          v-if="showSignUp"
          block
          class="root__new"
          type="outlined"
          :dense="false"
          @click="$router.push(paths.PATH_PAGE_SIGN_UP)"
      >
        {{$t('signIn.newAccount')}}
      </AppButton>
    </v-form>

    <div class="root__forgot">
      <router-link :to="paths.PATH_PAGE_FORGOT">{{$t('forgot.title')}}</router-link>
    </div>
  </LayoutAuth>
</template>

<script>
import LayoutAuth from '@/components/shared/layout/Auth.vue'
import { required, minLength, email } from 'vuelidate/lib/validators'
import { authService } from '@/services/AuthService'
import { config } from '@/config/config'
import { state } from '@/store/state'
import * as paths from '@/config/paths'

export default {
  name: 'ViewSignIn',

  components: {
    LayoutAuth,
  },

  data() {
    return {
      state,
      paths,
      authError: null,
      email: '',
      password: '',
      isLoading: false,
      showSignUp: config.env !== 'stage',
    }
  },

  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(config.minPasswordLength),
    },
  },

  methods: {
    setField(field, value) {
      this[field] = value
      this.$v[field].$touch()
    },

    async onSubmit() {
      this.$v.$touch()
      if (!this.$v.$anyError) {
        if (this.state.account.id) {
          await authService.logout(false).catch(window.logger.error)
        }
        this.isLoading = true
        const { error } = await authService.signIn(this.email, this.password)
        this.isLoading = false
        this.authError = error
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.root

  &__new
    margin-top: 8px

  &__forgot
    display: flex
    align-items: center
    justify-content: center
    margin: 16px 0 0 0
    font-weight: 700

.root__input
    margin-bottom: 25px !important
</style>

import { request, IResponse } from '@/lib/Request'
import { config } from '@/config/config'
import { map as mapAccount, TAccount, TAccountNumbers, TAvatar } from '@/models/Account'
import { map as mapCompany, TCompany } from '@/models/Company'
import { validateResponse } from '@/lib/validate'

const { apiUrl } = config
const UNKNOWN_ERROR = 'Unknown Error'

interface IGetAccount {
  account: null | TAccount
}

interface IGetCompany {
  company: null | TCompany
}

interface IAvatar {
  avatar: null | TAvatar
}

interface IAccountNumbers {
  numbers: TAccountNumbers[],
}

/**
 */
export const getAccount = async (): Promise<IResponse<IGetAccount>> => {
  const response = await request.get(`${apiUrl}/account`, {})
  validateResponse(response.data, ['account'])

  const result: IResponse<IGetAccount> = {
    status: response.status,
    error: response.error,
    data: {
      account: response.data.account?.email ? mapAccount(response.data.account || {}) : null,
    },
  }

  if (!result.data.account?.email) {
    result.error = result.error || UNKNOWN_ERROR
  }

  return result
}

/**
 */
export const getCompany = async (): Promise<IResponse<IGetCompany>> => {
  const response = await request.get(`${apiUrl}/account/company`, {})
  validateResponse(response.data, ['company'])

  const result: IResponse<IGetCompany> = {
    status: response.status,
    error: response.error,
    data: {
      company: mapCompany(response.data.company || {}),
    },
  }

  if (!result.data.company?.id) {
    result.error = result.error || UNKNOWN_ERROR
  }

  return result
}

interface IInvite {
  success: string[]
  failed: string[]
}

/**
 */
export const invite = async (emails: string[], admin: boolean): Promise<IResponse<IInvite>> => {
  const response = await request.post<IInvite>(`${apiUrl}/account/invite`, { emails, admin })
  validateResponse(response.data, ['success', 'failed'])
  return response
}

/**
 */
export const suspend = async (uid: string): Promise<IResponse> => {
  return await request.delete(`${apiUrl}/account`, { uid })
}

/**
 */
export const cancelInvite = async (email: string): Promise<IResponse> => {
  return await request.delete(`${apiUrl}/account/invite`, { emails: [email] })
}

interface IUpdate {
  account: TAccount
}

/**
 */
export const update = async (id: string, payload: TObject): Promise<IResponse<IUpdate>> => {
  const response = await request.put<IUpdate>(`${apiUrl}/account`, { uid: id, ...payload })
  validateResponse(response.data, ['account'])

  response.data.account = mapAccount(response.data.account)
  return response as IResponse<IUpdate>
}

interface IGetItems {
  items: TAccount[]
  total: number
}

export const getItems = async (page = 1, size = 20, sortField = 'created_at', sortDir: 'asc' | 'desc' = 'desc', search?: string): Promise<IResponse<IGetItems>> => {
  const payload: TObject = {
    page,
    limit: size,
    order_by: sortField,
    order: sortDir,
  }
  if (search) {
    payload.search = search
  }

  const response = await request.get(`${apiUrl}/account/users`, payload)
  validateResponse(response.data, ['users'])
  validateResponse(response.data.users || {}, ['data'])
  validateResponse(response.data.users?.pagination || {}, ['total'])

  const rawItems: TObject[] = response.data.users?.data || []
  const total = parseInt(response.data.users?.pagination?.total) || 0

  return {
    status: response.status,
    error: response.error,
    data: {
      total,
      items: rawItems.map(mapAccount),
    },
  }
}

interface IGetTimezones {
  timezones: string[]
}

export const getTimezones = async (): Promise<IResponse<IGetTimezones>> => {
  return request.get<IGetTimezones>(`${apiUrl}/account/vars/tz`)
}

interface IGetLanguages {
  languages: string[]
}

export const getLanguages = async (): Promise<IResponse<IGetLanguages>> => {
  return request.get<IGetLanguages>(`${apiUrl}/account/vars/languages`)
}

/**
 */
 export const avatar = async (formdata: HTMLFormElement): Promise<IResponse<IAvatar>> => {
  return request.post<IAvatar>(`${apiUrl}/account/avatar`, formdata)
}

/**
 */
 export const getAccountNumbers = async (): Promise<IResponse<IAccountNumbers>> => {
  return request.get<IAccountNumbers>(`${apiUrl}/account/numbers`)
}

<template>
  <div class="root">
    <div class="content">
      <h3 class="title mb-2">{{title}}</h3>
      <div class="text"><slot name="content" /></div>
    </div>
    <div class="actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'BillingCard',

  props: {
    title: String,
  },

  data() {
    return {
    }
  },
}
</script>

<style lang="sass" scoped>
.root
  display: flex
  flex-direction: row
  justify-content: space-between
  border: 1px solid rgba(0, 0, 0, 0.12)
  border-radius: 5px
  padding: 20px 25px

  .content
    width: 100%

@media (max-width: 630px)
  .root
    flex-direction: column

    .actions
     margin-top: 10px

  .content
    .title
      font-size: 16px
    .text
      font-size: 13px
</style>

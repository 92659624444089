<template>
  <div class="root">
    <v-container>
      <v-row v-if="!['xs', 'sm'].includes($vuetify.breakpoint.name)">
        <v-col>
          <DashboardStats />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <Widget :title="$t('navigation.users')" :flex="true">
            <template slot="controls">
              <ActionButton @click="$router.push('/users')">
                {{$t('common.more')}}
              </ActionButton>
            </template>
            <template slot="content">
              <UserCard
                v-for="item in users"
                :key="item.id"
                :admin="item.admin"
                :firstName="item.profile.firstName"
                :lastName="item.profile.lastName"
                :avatar="item.profile.avatar"
                :email="item.email"
                :number="formatPhoneNumber(item.profile.number)"
              />
            </template>
          </Widget>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <Widget :title="$t('navigation.numbers')">
            <template slot="controls">
              <ActionButton @click="$router.push('/numbers')">
                {{$t('common.more')}}
              </ActionButton>
            </template>
            <template slot="content">
              <v-data-table
                class="row-hover header"
                :item-key="'id'"
                :headers="headersDIDs"
                :items="state.dids.list.items"
                :options.sync="state.dids.list.options"
                :server-items-length="state.dids.list.total"
                :no-data-text="$t('common.empty')"
                :disable-sort="true"
                :loading="state.dids.list.isLoading"
                :loading-text="$t('common.loading')"
                hide-default-footer
              >
                <template v-slot:[`item.type`]="{ item }">
                  {{(item.transfer || item.fax) ? $t('dids.direct') : $t('dids.main')}}
                </template>
                <template v-slot:[`item.number`]="{ item }">
                  {{formatPhoneNumber(item.number)}}
                </template>
                <template v-slot:[`item.scenario_id`]="{ item }">
                  {{getScenarioName(item)}}
                </template>
              </v-data-table>
            </template>
          </Widget>
        </v-col>
        <v-col>
          <Widget :title="$t('navigation.scenarios')">
            <template slot="controls">
              <ActionButton @click="$router.push('/scenarios')">
                {{$t('common.more')}}
              </ActionButton>
            </template>
            <template slot="content">
              <v-data-table
                class="row-hover header"
                :item-key="'id'"
                :headers="headersScenarios"
                :items="state.scenarios.list.items"
                :options.sync="state.scenarios.list.options"
                :server-items-length="state.scenarios.list.total"
                :no-data-text="$t('common.empty')"
                :disable-sort="true"
                :loading="state.scenarios.list.isLoading"
                :loading-text="$t('common.loading')"
                hide-default-footer
              >
              </v-data-table>
            </template>
          </Widget>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <Widget :title="$t('navigation.callHistory')">
            <template slot="controls">
              <ActionButton @click="$router.push('/callstats')">
                {{$t('common.more')}}
              </ActionButton>
            </template>
            <template slot="content">
              <v-data-table
                class="row-hover header"
                :item-key="'id'"
                :headers="headersCallLog"
                :items="logItems"
                :options.sync="state.billing.callLog.options"
                :server-items-length="state.billing.callLog.total"
                :no-data-text="$t('common.empty')"
                :disable-sort="true"
                :loading="state.billing.callLog.isLoading"
                :loading-text="$t('common.loading')"
                hide-default-footer
              >
                <template v-slot:[`item.created_at`]="{ item }">
                  {{ formatDate(item.created_at, true) }}
                </template>
                <template v-slot:[`item.begin`]="{ item }">
                  {{ formatDate(item.begin, true) }}
                </template>
                <template v-slot:[`item.record_id`]="{ item }">
                  <template v-if="item.record_id">
                    <img
                      class="record-play-icon"
                      :src="icons.playIcon"
                      @click="downloadRecordItem(item.record_id)"
                    />
                  </template>
                </template>
                <template v-slot:[`item.duration`]="{ item }">
                  {{ item.duration }}
                </template>
                <template v-slot:[`item.direction`]="{ item }">
                  <v-chip
                    :color="(item.direction === 'inbound') ? 'green' : 'orange'"
                    text-color="white"
                    small
                  >
                    {{item.direction}}
                  </v-chip>
                </template>
                <template v-slot:[`item.scenario_id`]="{ item }">
                  {{ (scenarioRefs.find(i => i.value === `${item.scenario_id}`) || {}).label || item.scenario_id }}
                </template>
                <template v-slot:[`item.fromNumber`]="{ item }">
                  {{createPhoneNumber(item.fromNumber).formatted || item.fromNumber}}
                </template>
                <template v-slot:[`item.toNumber`]="{ item }">
                  {{createPhoneNumber(item.toNumber).formatted || item.toNumber}}
                </template>
                <template v-slot:[`item.transfer_account_uid`]="{ item }">
                  {{ getTransferAccountName(item.transfer_account_uid) }}
                </template>
              </v-data-table>
            </template>
          </Widget>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import { state } from '@/store/state'
import { userService } from '@/services/UserService'
import { didService } from '@/services/DidService'
import { scenarioService } from '@/services/ScenarioService'
import { billingService } from '@/services/BillingService'
import { recordService } from '@/services/RecordService'
import DashboardStats from '@/components/views/viewHome/dashboard/Stats'
import Widget from '@/components/views/viewHome/dashboard/Widget'
import UserCard from '@/components/views/viewHome/dashboard/widgets/UserCard'
import { formatDate, formatPhoneNumber, createPhoneNumber, formatTime } from '@/lib/utils'
import playIcon from '@/assets/play.png'

export default {
  name: 'Dashboard',

  components: {
    DashboardStats,
    Widget,
    UserCard,
  },

  data() {
    return {
      state,
      icons: {
        playIcon,
      },
      headersDIDs: [
        {
          text: this.$t('common.type'),
          value: 'type',
        },
        {
          text: this.$t('common.number'),
          value: 'number',
        },
        {
          text: this.$t('scenarios.scenario'),
          value: 'scenario_id',
        },
      ],
      headersScenarios: [
        {
          text: this.$t('common.name'),
          value: 'name',
        },
        {
          text: this.$t('common.description'),
          value: 'description',
        },
      ],
      headersCallLog: [
        {
          text: this.$t('callog.begin'),
          value: 'begin',
        },
        {
          text: this.$t('callog.duration'),
          align: 'center',
          value: 'duration',
        },
        {
          text: this.$t('callog.direction'),
          align: 'center',
          value: 'direction',
        },
        {
          text: this.$t('callog.fromNumber'),
          value: 'fromNumber',
        },
        {
          text: this.$t('callog.toNumber'),
          value: 'toNumber',
        },
        {
          text: this.$t('callog.record'),
          align: 'center',
          value: 'record_id',
        },
        {
          text: this.$t('callog.scenario'),
          value: 'scenario_id',
        },
        {
          text: this.$t('callog.redirectNumber'),
          value: 'transfer_account_uid',
        },
        {
          text: this.$t('callog.hangupCause'),
          value: 'hangup_cause',
        },
      ],
    }
  },

  mounted() {
    (async () => {
      await scenarioService.loadRefs()
      await userService.loadRefs()
      await billingService.loadCallLog()
      await this.loadUsers()
      await this.loadDIDs()
      await this.loadScenarios()
    })().catch(window.logger.error)
  },

  methods: {
    formatPhoneNumber,
    createPhoneNumber,
    formatDate,
    formatTime,

    async loadUsers() {
      await userService.loadItems()
    },

    async loadDIDs() {
      await didService.loadItems()
    },

    async loadScenarios() {
      await scenarioService.loadItems()
    },

    getScenarioName(item) {
      return (state.scenarios.refs.find(i => i.value === item.scenario_id) || {}).label
    },

    getScenarioAccount(item) {
      return (state.scenarios.refs.find(i => i.value === item.scenario_id) || {}).account_id
    },

    getTransferAccountName(id) {
      if (id) {
        const user = this.state.users.refs.find(i => i.id === id)
        return user ? `${user.profile.firstName} ${user.profile.lastName}` : this.$t('callog.userDeleted')
      }
      return ''
    },

    async downloadRecordItem(id) {
      await recordService.downloadItem(id)
    },
  },

  computed: {
    users: function() {
      return state.users.list.items.filter((i) => i.type !== 'pending')
    },
    logItems() {
      const items = state.billing.callLog.items.map(i => Object.assign({}, i, {
        hangup_cause: this.$te(`callog.hangups.${i.hangup_cause}`)
          ? this.$t(`callog.hangups.${i.hangup_cause}`) : i.hangup_cause,
      }))
      return items
    },
    scenarioRefs() { return this.state.scenarios.refs },
  },
}
</script>

<style lang="sass" scoped>
.root
  display: flex
  flex-direction: column
  align-items: center

  .record-play-icon
    cursor: pointer
</style>

import { render, staticRenderFns } from "./ViewInvited.vue?vue&type=template&id=057f520b&scoped=true&"
import script from "./ViewInvited.vue?vue&type=script&lang=js&"
export * from "./ViewInvited.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "057f520b",
  null
  
)

export default component.exports
<template>
  <LayoutPlan v-if="step === steps[1]">
    <OnboardingStepDataplan @next="next" />
  </LayoutPlan>

  <LayoutAuth v-else class="root" :title="titles[step] || ''" width="410px">
    <AppLoader v-if="state.isInitializing" :style="{ padding: '50px 0' }" />
    <OnboardingStepWelcome v-if="step === steps[0] && !state.isInitializing" @next="next"/>
    <template v-slot:actions v-if="step === steps[0]">
      <PaymentProviders />
    </template>
    <OnboardingStepDidNumber v-if="step === steps[2] && !state.isInitializing" @next="next" />
    <OnboardingStepSipUrl v-if="step === steps[3] && !state.isInitializing" @next="next" />
    <slot />
  </LayoutAuth>
</template>

<script>

import { state } from '@/store/state'
import LayoutAuth from '@/components/shared/layout/Auth.vue'
import OnboardingStepWelcome from '@/components/shared/onboarding/StepWelcome'
import OnboardingStepDataplan from '@/components/shared/onboarding/StepDataplan'
import OnboardingStepDidNumber from '@/components/shared/onboarding/StepDidNumber'
import OnboardingStepSipUrl from '@/components/shared/onboarding/StepSipUrl'
import PaymentProviders from '@/components/shared/PaymentProviders'
import { userService } from '@/services/UserService'
import LayoutPlan from '@/components/shared/layout/Plan'

const steps = ['welcome', 'dataPlan', 'didNumber', 'sipUrl']

export default {
  name: 'Onboarding',

  components: {
    LayoutPlan,
    LayoutAuth,
    OnboardingStepWelcome,
    OnboardingStepDataplan,
    OnboardingStepDidNumber,
    OnboardingStepSipUrl,
    PaymentProviders,
  },

  data() {
    return {
      state,
      steps,
      step: state.account.profile.onboarding,
      titles: {
        [steps[0]]: this.$t('onboarding.titleWelcome'),
        [steps[1]]: this.$t('onboarding.titleDataplan'),
        [steps[2]]: this.$t('onboarding.titleDidNumber'),
        [steps[3]]: this.$t('onboarding.titleSipUrl'),
      },
    }
  },

  methods: {
    async next(step) {
      if (step === 'finish') {
        await userService.updateProfile({ onboarding: '' })
      } else {
        userService.updateProfile({ onboarding: step }).catch(window.logger.error)
        this.step = step
      }
    },
  },
}
</script>

<style lang="sass" scoped>
</style>

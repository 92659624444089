<template>
  <v-form class="root" @submit.prevent="$emit('submit')" novalidate="true">
    <slot name="top" />
    <v-container fluid class="ma-0 pa-0">
      <slot />
    </v-container>
    <slot name="bottom" />
    <div class="actions">
      <slot name="actions" />
    </div>
  </v-form>
</template>

<script>

export default {
  name: 'AppForm',

  components: {
  },

  data() {
    return {
    }
  },
}
</script>

<style lang="sass" scoped>
.root
  .actions
    display: flex
    justify-content: flex-end
</style>

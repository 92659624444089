import { state } from '@/store/state'
import { getItems, remove } from '@/api/FaxApi'
import { appService } from '@/services/AppService'

class FaxService {
  public state = state

  /**
   */
  public async loadItems(page?: number, size?: number): Promise<void> {
    this.state.faxes.list.isLoading = true
    if (page) { this.state.faxes.list.options.page = page }
    if (size) { this.state.faxes.list.options.itemsPerPage = size }
    try {
      const sortField = this.state.faxes.list.options.sortBy[0]
      const sortDir = this.state.faxes.list.options.sortDesc[0]
      const { error, data: { items, total } } = await getItems(
        this.state.faxes.list.options.page,
        this.state.faxes.list.options.itemsPerPage,
        sortField,
        sortDir ? 'desc' : 'asc',
        this.state.faxes.list.options.search,
      )
      if (error) {
        appService.showError(error)
      } else {
        this.state.faxes.list.items = items
        this.state.faxes.list.total = total
        this.state.faxes.list.totalPages = Math.ceil(total / this.state.faxes.list.options.itemsPerPage)
      }
    } catch (e) {
      window.logger.error(e)
      appService.showError()
    }
    this.state.faxes.list.isLoading = false
  }

  /**
   */
   public remove = async (id: string): Promise<{ error: null | string }> => {
    const { error } = await remove(id)
    return { error }
  }

}

const faxService = new FaxService()
export { faxService, FaxService }

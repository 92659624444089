import { request, IResponse } from '@/lib/Request'
import { config } from '@/config/config'
import { map as mapAccount, map, TAccount, TAccountProfile } from '@/models/Account'
import { validateResponse } from '@/lib/validate'

const { apiUrl } = config
const UNKNOWN_ERROR = 'Unknown Error'

interface ISignIn {
  account: null | TAccount
  stripe_pk: string
}

/**
 */
export const signIn = async (email: string, password: string): Promise<IResponse<ISignIn>> => {
  const response = await request.post<ISignIn>(`${apiUrl}/session`, { email, password })
  validateResponse(response.data, ['account', 'stripe_pk'])

  if (response.data.account?.email) {
    response.data.account = map(response.data.account)
  } else {
    response.error = response.error || UNKNOWN_ERROR
  }

  return response
}

interface ISignUp {
  account: null | TAccount
}

/**
 */
export const signUp = async (email: string, password: string, profile: TAccountProfile): Promise<IResponse<ISignUp>> => {
  const response = await request.post(`${apiUrl}/account`, { email, password, profile })
  validateResponse(response.data, ['account'])

  if (response.data.account?.email) {
    response.data.account = map(response.data.account)
  } else {
    response.error = response.error || UNKNOWN_ERROR
  }

  return response as IResponse<ISignUp>
}

interface ISignUpVerify {
  account: null | TAccount
}

/**
 */
export const signUpVerify = async (email: string, code: string): Promise<IResponse<ISignUpVerify>> => {
  const response = await request.get<ISignUpVerify>(`${apiUrl}/account/verification`, { email, code })
  validateResponse(response.data, ['account'])

  if (response.data.account?.email) {
    response.data.account = map(response.data.account)
  } else {
    response.error = response.error || UNKNOWN_ERROR
  }

  return response
}

/**
 */
export const logout = async (): Promise<void> => {
  await request.delete(`${apiUrl}/session`)
}

/**
 */
export const recoverPassword = async (email: string): Promise<IResponse> => {
  return await request.post(`${apiUrl}/account/recover`, { email })
}

interface IRecoverPasswordVerify {
  account: null | TAccount
}

/**
 */
export const recoverPasswordVerify = async (email: string, code: string): Promise<IResponse<IRecoverPasswordVerify>> => {
  const response = await request.get<IRecoverPasswordVerify>(`${apiUrl}/account/recover`, { email, code })
  validateResponse(response.data, ['account'])

  if (response.data.account?.email) {
    response.data.account = map(response.data.account)
  } else {
    response.error = response.error || UNKNOWN_ERROR
  }

  return response
}

interface IGetAccountInvited {
  account: null | TAccount
}

/**
 */
export const getAccountInvited = async (token: string): Promise<IResponse<IGetAccountInvited>> => {
  const headers = request.headers
  request.headers = { ...headers, Authorization: token }
  const response = await request.get(`${apiUrl}/account/invite`, {})
  validateResponse(response.data, ['account'])
  request.headers = headers

  const result: IResponse<IGetAccountInvited> = {
    status: response.status,
    error: response.error,
    data: {
      account: response.data.account?.email ? mapAccount(response.data.account || {}) : null,
    },
  }

  if (!result.data.account?.email) {
    result.error = result.error || UNKNOWN_ERROR
  }

  return result
}

interface IJoinInvited {
  account: null | TAccount
}

/**
 */
export const joinInvited = async (token: string, email: string, password: string, profile: TAccountProfile): Promise<IResponse<IJoinInvited>> => {
  const response = await request.post<IJoinInvited>(`${apiUrl}/account`, { token, email, password, profile })
  validateResponse(response.data, ['account'])

  if (response.data.account?.email) {
    response.data.account = map(response.data.account)
  } else {
    response.error = response.error || UNKNOWN_ERROR
  }

  return response
}

import Vue from 'vue'
import Router from 'vue-router'
import * as paths from '@/config/paths'
import ViewNotFound from '@/components/views/ViewNotFound.vue'
import ViewSignIn from '@/components/views/ViewSignIn.vue'
import ViewSignUp from '@/components/views/ViewSignUp.vue'
import ViewForgot from '@/components/views/ViewForgot.vue'
import ViewInvited from '@/components/views/ViewInvited.vue'
import ViewHome from '@/components/views/ViewHome.vue'
import ViewNumbers from '@/components/views/ViewNumbers.vue'
import ViewNumbersDetail from '@/components/views/ViewNumbersDetail.vue'
import ViewScenarios from '@/components/views/ViewScenarios.vue'
import ViewScenariosDetail from '@/components/views/ViewScenariosDetail.vue'
import ViewCallHistory from '@/components/views/ViewCallHistory.vue'
import ViewBilling from '@/components/views/ViewBilling.vue'
import ViewUsers from '@/components/views/ViewUsers.vue'
import ViewProfile from '@/components/views/ViewProfile.vue'
import ViewTerms from '@/components/views/ViewTerms.vue'
import ViewPrivacy from '@/components/views/ViewPrivacy.vue'
import ViewFaxes from '@/components/views/ViewFaxes.vue'

import { state } from '@/store/state'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: paths.PATH_PAGE_TERMS,
      name: 'Terms And Conditions',
      component: ViewTerms,
      meta: {
        requiresAuth: false,
        admin: false,
      },
    },
    {
      path: paths.PATH_PAGE_PRIVACY,
      name: 'Privacy Policy',
      component: ViewPrivacy,
      meta: {
        requiresAuth: false,
        admin: false,
      },
    },
    {
      path: paths.PATH_PAGE_HOME,
      name: 'Home',
      component: ViewHome,
      meta: {
        requiresAuth: true,
        admin: true,
      },
    },
    {
      path: paths.PATH_PAGE_NUMBERS,
      name: 'Numbers',
      component: ViewNumbers,
      meta: {
        requiresAuth: true,
        admin: false,
      },
    },
    {
      path: `${paths.PATH_PAGE_NUMBERS}/:id`,
      name: 'Numbers Detail',
      component: ViewNumbersDetail,
      meta: {
        requiresAuth: true,
        admin: false,
      },
    },
    {
      path: paths.PATH_PAGE_SCENARIOS,
      name: 'Scenarios',
      component: ViewScenarios,
      meta: {
        requiresAuth: true,
        admin: false,
      },
    },
    {
      path: `${paths.PATH_PAGE_SCENARIOS}/:id`,
      name: 'Scenarios Detail',
      component: ViewScenariosDetail,
      meta: {
        requiresAuth: true,
        admin: false,
      },
    },
    {
      path: paths.PATH_PAGE_CALLSTATS,
      name: 'Call History',
      component: ViewCallHistory,
      meta: {
        requiresAuth: true,
        admin: false,
      },
    },
    {
      path: paths.PATH_PAGE_FAXES,
      name: 'Faxes',
      component: ViewFaxes,
      meta: {
        requiresAuth: true,
        admin: false,
      },
    },
    {
      path: paths.PATH_PAGE_BILLING,
      name: 'Billing',
      component: ViewBilling,
      meta: {
        requiresAuth: true,
        admin: true,
      },
    },
    {
      path: paths.PATH_PAGE_PROFILE,
      name: 'Profile',
      component: ViewProfile,
      meta: {
        requiresAuth: true,
        admin: false,
      },
    },
    {
      path: paths.PATH_PAGE_USERS,
      name: 'Users',
      component: ViewUsers,
      meta: {
        requiresAuth: true,
        admin: true,
      },
    },
    {
      path: paths.PATH_PAGE_SIGN_IN,
      name: 'SignIn',
      component: ViewSignIn,
    },
    {
      path: paths.PATH_PAGE_SIGN_UP,
      name: 'SignUp',
      component: ViewSignUp,
    },
    {
      path: paths.PATH_PAGE_FORGOT,
      name: 'Forgot',
      component: ViewForgot,
    },
    {
      path: paths.PATH_PAGE_INVITED,
      name: 'Invite',
      component: ViewInvited,
    },
    {
      path: '*',
      name: 'NotFound',
      component: ViewNotFound,
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to?.meta?.requiresAuth) {
    if (state.auth) {
      if (!to?.meta?.admin || state.account.admin) {
        next()
      } else {
        next({ path: state.account.admin ? paths.PATH_PAGE_HOME : paths.PATH_PAGE_CALLSTATS })
      }
    } else {
      next({ path: paths.PATH_PAGE_SIGN_IN })
    }
  } else {
    next()
  }
})

export default router

<template>
  <div v-if="(trial || isExpired) && !devAccount" class="root">
    <v-icon small>{{icons.mdiAlertCircle}}</v-icon>
    <template v-if="!short">
      <div v-if="trial && !isExpired">
        {{$t('common.trialUntil', { time: untilTime, date: untilDate })}}
      </div>
      <div v-else-if="isExpired" style="cursor: pointer" @click="$router.push('/billing')">
        {{$t(trial ? 'dataplan.expiredTrial' : 'dataplan.expired')}}
      </div>
    </template>
  </div>

  <div v-else-if="expireSoonAndNoFunds && !devAccount" class="root">
    <v-icon small>{{icons.mdiAlertCircle}}</v-icon>
    <template v-if="!short">
      <div style="cursor: pointer" @click="$router.push('/billing')">
        {{$t('dataplan.expireSoonAndNoFunds')}}
      </div>
    </template>
  </div>

  <div v-else-if="noFunds && !devAccount" class="root">
    <v-icon small>{{icons.mdiAlertCircle}}</v-icon>
    <template v-if="!short">
      <div style="cursor: pointer" @click="$router.push('/billing')">
        {{$t('dataplan.addFunds')}}
      </div>
    </template>
  </div>
</template>

<script>

import { state } from '@/store/state'
import { formatDate, formatTime } from '@/lib/utils'
import { config } from '@/config/config'
import { mdiAlertCircle } from '@mdi/js'

export default {
  name: 'MainDataplanStatus',

  props: {
    short: Boolean,
  },

  components: {
  },

  computed: {
    noFunds() { return this.state.billing.balance < 20 },
    trial() { return this.state.billing.trial },
    expiration() { return this.state.billing.paid_till },
    isExpired() { return this.expiration && this.expiration.getTime() < Date.now() },
    untilTime() { return this.expiration && formatTime(this.expiration) },
    untilDate() { return this.expiration && formatDate(this.expiration) },
    limitAccounts() { return this.state.billing.dataplan.accounts },
    limitMinutesIn() { return this.state.billing.dataplan.minutes_inbound },
    limitMinutesOut() { return this.state.billing.dataplan.minutes_outbound },
    limitCalls() { return this.state.billing.dataplan.calls_incoming },
    limitDids() { return this.state.billing.dataplan.dids },
    devAccount() { return this.state.billing.dev },
    expireSoonAndNoFunds() {
      const invoice = this.state.billing.invoice
      const noFunds = invoice && invoice.charge_total > this.state.billing.balance
      const remainDays = this.expiration && (this.expiration.getTime() - Date.now()) / 86400000
      const expireSoon = remainDays <= config.insufficientFundsRemainDays
      return expireSoon && noFunds
    },
  },

  data() {
    return {
      state,
      icons: {
        mdiAlertCircle,
      },
    }
  },
}
</script>

<style lang="sass" scoped>
.root
  background: var(-v-primary)
  line-height: 1
  padding: 10px 10px 10px 0px
  display: flex
  color: #FF902A
  background: rgba(255, 144, 42, 0.12)

.root
  .v-icon
    color: #FF902A
    margin: 0 10px 0 18px

</style>

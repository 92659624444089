<template>
  <div>
    <p>{{$t('onboarding.textSipUrl')}}</p>

    <div v-if="number" class="sip">
      {{number.label}}@{{domain}}
    </div>

    <v-card-actions class="mt-8 pr-6 pl-6 pb-4">
      <v-spacer></v-spacer>
      <AppButton
          type="primary"
          @click="onClick"
      >
        {{$t('common.finish')}}
      </AppButton>
      <v-spacer></v-spacer>
    </v-card-actions>
  </div>
</template>

<script>

import { state } from '@/store/state'

export default {
  name: 'OnboardingStepSipUrl',

  components: {
  },

  computed: {
    domain() { return this.state.company.domain },
    number() { return this.state.dids.refs[0] },
  },

  data() {
    return {
      state,
    }
  },

  methods: {
    async onClick() {
      this.$emit('next', 'finish')
    },
  },
}
</script>

<style lang="sass" scoped>
.sip
  margin: 20px 0
  font-weight: bold
  font-size: 1.4rem
</style>

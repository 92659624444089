import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import './styles/index.sass'
import '@/lib/Logger'
import App from '@/components/App.vue'
import { options } from '@/config/vue'
import { Bootstrap } from '@/lib/Bootstrap'
import { config } from '@/config/config'

if (config.env === 'production') {
  Sentry.init({
    Vue: Vue,
    dsn: config.sentryDsn,
    environment: `frontend:${config.env}`,
  })
}

Bootstrap.init().then(() => {
  window.$vm = new Vue({
    ...options,
    render: h => h(App),
  }).$mount('#app')
}).catch(window.logger.error)


<template>
  <div>
    <div class="data-table" v-if="state.faxes.list.items.length">
      <v-container>
        <v-row>
          <v-col cols="4" xs12>
            <v-form @submit.prevent="onSearch">
              <v-text-field
                outlined
                hide-details
                :label="$t('common.searchNumber')"
                :append-icon="icons.mdiMagnify"
                v-model="query"
                @input="updateQuery"
                @click:append="onSearch"
              />
            </v-form>
          </v-col>
        </v-row>
      </v-container>
      <v-data-table
          class="root mt-2 row-hover"
          :item-key="'id'"
          :headers="headers"
          :items="state.faxes.list.items"
          :options.sync="state.faxes.list.options"
          :server-items-length="state.faxes.list.total"
          :no-data-text="$t('common.empty')"
          :loading="state.faxes.list.isLoading"
          :page.sync="state.faxes.list.options.page"
          hide-default-footer
      >
          <template v-slot:[`item.created_at`]="{ item }">
              {{ formatDate(item.created_at, true) }}
          </template>
          <template v-slot:[`item.from_number`]="{ item }">
              {{ formatPhoneNumber(item.from_number) }}
          </template>
          <template v-slot:[`item.to_number`]="{ item }">
              {{ formatPhoneNumber(item.to_number) }}
          </template>
          <template v-slot:[`item.direction`]="{ item }">
              <v-chip
                  :color="(item.direction === 'inbound') ? 'green' : 'orange'"
                  text-color="white"
                  small
              >
                  {{item.direction}}
              </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="list-actions">
              <AppListingAction
                :title="$t('faxes.download')"
                @click="open(item.location)"
              >
                {{icons.mdiDownload}}
              </AppListingAction>
              <AppListingAction
                :title="$t('faxes.delete')"
                @click="onDelete(item)"
              >
                {{icons.mdiDelete}}
              </AppListingAction>
            </div>
          </template>
      </v-data-table>
      <v-pagination
        v-model="state.faxes.list.options.page"
        @input="loadItems"
        :length="state.faxes.list.totalPages"
        :total-visible="7"
      />
      <AppDialog ref="dialog">
      </AppDialog>
    </div>
    <div v-else class="d-flex justify-center align-center ">
      <img class="ma-2" :src="icons.faxIcon" />
      <h2 class="ma-2 empty-message">{{$t('faxes.noFaxes')}}</h2>
    </div>
  </div>
</template>

<script>

import { state } from '@/store/state'
import { appService } from '@/services/AppService'
import { faxService } from '@/services/FaxService'
import { formatDate, formatPhoneNumber } from '@/lib/utils'
import { create } from '@/models/Fax'
import { mdiDownload, mdiDelete, mdiMagnify } from '@mdi/js'
import faxIcon from '@/assets/fax.png'

export default {
  name: 'ViewFaxesList',

  components: {
  },

  computed: {
  },

  data() {
    return {
      icons: {
        mdiDownload,
        mdiDelete,
        mdiMagnify,
        faxIcon,
      },
      state,
      query: state.faxes.list.options.search,
      headers: [
        {
          text: this.$t('faxes.received'),
          value: 'created_at',
          sortable: true,
        },
        {
          text: this.$t('faxes.from_number'),
          value: 'from_number',
          sortable: true,
        },
        {
          text: this.$t('faxes.to_number'),
          value: 'to_number',
          sortable: true,
        },
        {
          text: this.$t('faxes.direction'),
          value: 'direction',
          sortable: true,
        },
        {
          text: this.$t('faxes.page_count'),
          align: 'center',
          value: 'page_count',
          sortable: true,
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ],
      deleteItem: create(),
    }
  },

  methods: {
    formatDate,
    formatPhoneNumber,

    async loadItems(page) {
      await faxService.loadItems(page)
    },

    updateQuery(query) {
      this.state.faxes.list.options.search = query
    },

    async onSearch() {
      this.state.faxes.list.options.search = this.query
      this.state.faxes.list.options.page = 1
      await faxService.loadItems()
    },

    open(location) {
        window.open(location, '_blank')
    },

    async onDelete(item) {
      this.deleteItem = item
      const dialogSettings = {
        title: this.$t('faxes.dialogDeleteTitle'),
        html: `<span class="text-no-wrap">${this.$t('faxes.dialogDeleteText')}
          <b>${this.formatPhoneNumber(item.from_number)}</b>?</span>`,
        actionText: this.$t('common.delete'),
        maxWidth: 500,
      }

      if (await this.$refs.dialog.show(dialogSettings)) {
        try {
          const { error } = await faxService.remove(item.id)
          if (!error) {
            appService.showSuccess()
            await this.loadItems()
          } else {
            appService.showError()
          }
        } catch (e) {
          window.logger.error(e)
          appService.showError()
        }
      }
    },
  },

  mounted() {
    this.loadItems().catch(window.logger.error)
  },

}
</script>

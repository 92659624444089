<template>
  <LayoutMain class="root">
    <AppCard :title="$t('dids.titleDetail')">
      <ViewNumbersDetailForm :id="$router.history.current.params.id" />
    </AppCard>
  </LayoutMain>
</template>

<script>
import LayoutMain from '@/components/shared/layout/Main.vue'
import ViewNumbersDetailForm from '@/components/views/viewNumbersDetail/Form'

export default {
  name: 'ViewNumbersDetail',

  components: {
    LayoutMain,
    ViewNumbersDetailForm,
  },

  data() {
    return {
    }
  },
}
</script>

<style lang="sass" scoped>
</style>

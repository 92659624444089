<template>
  <v-form @submit.prevent="onSearch">
    <v-text-field
        outlined
        hide-details
        :label="$t('common.search')"
        v-model="query"
        @input="updateQuery"
        :append-icon="icons.mdiMagnify"
        @click:append="onSearch"
    />
  </v-form>
</template>

<script>

import { mdiMagnify } from '@mdi/js'
import { state } from '@/store/state'
import { userService } from '@/services/UserService'

export default {
  name: 'ViewUsersSearch',

  components: {
  },

  data() {
    return {
      icons: { mdiMagnify },
      query: state.users.list.options.search,
      state,
    }
  },

  methods: {
    updateQuery(query) {
      this.state.users.list.options.search = query
    },

    async onSearch() {
      this.state.users.list.options.search = this.query
      this.state.users.list.options.page = 1
      await userService.loadItems()
    },
  },
}
</script>

<style lang="sass" scoped>
</style>

import { request, IResponse } from '@/lib/Request'
import { config } from '@/config/config'
import { validateResponse } from '@/lib/validate'
import { map as mapFaxItem, TFax } from '@/models/Fax'

const { apiUrl } = config

interface IGetItems {
  items: TFax[]
  total: number
}

export const getItems = async (
    page = 1,
    size = 20,
    sortField = 'created_at',
    sortDir: 'asc' | 'desc' = 'desc',
    search?: string,
  ): Promise<IResponse<IGetItems>> => {
  const payload: TObject = {
    page,
    limit: size,
    order_by: sortField,
    order: sortDir,
  }
  if (search) {
    payload.search = search
  }

  const response = await request.get(`${apiUrl}/fax`, payload)
  validateResponse(response.data, ['faxes'])
  validateResponse(response.data.faxes || {}, ['data'])
  validateResponse(response.data.faxes?.pagination || {}, ['total'])

  const rawItems: TFax[] = response.data.faxes?.data || []
  const total = parseInt(response.data.faxes?.pagination?.total) || 0

  return {
    status: response.status,
    error: response.error,
    data: {
      total,
      items: rawItems.map(mapFaxItem),
    },
  }
}

/**
 */
export const remove = async (id: string): Promise<IResponse> => {
  return await request.delete(`${apiUrl}/fax`, { id })
}

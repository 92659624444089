<template>
  <div v-if="show" class="cookie-banner">
    <div class="content">
      {{$t('cookie.banner')}}
    </div>
    <div class="actions">
      <AppButton type="warning" @click="accept" class="btn">{{$t('common.ok')}}</AppButton>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CookieBanner',

  components: {
  },

  data() {
    return {
      show: false,
    }
  },

  beforeMount() {
    this.show = !localStorage.getItem('accept-cookies')
  },

  methods: {
    accept() {
      localStorage.setItem('accept-cookies', true)
      this.show = false
    },
  },
}
</script>

<style lang="sass" scoped>
@mixin cookie-banner
  position: fixed
  left: 0
  bottom: 0
  width: 100%
  padding: 20px
  z-index: 1000
  display: flex
  align-items: center
  justify-content: center
  gap: 80px
  background: rgba(255, 255, 255, 0.9) !important
  box-shadow: 0px 0px 70px rgba(12, 37, 74, 0.12)

@mixin content
  text-align: justify

.cookie-banner
  @include cookie-banner
  .content
    @include content
    font-size: 0.9rem

@media (max-width: 600px)
  .cookie-banner
    @include cookie-banner
    flex-direction: column
    background: #FFF
    gap: 10px

  .content
    @include content

  .actions
    width: 100%
    text-align: center
</style>

<template>
  <LayoutMain class="root" :title="$t('navigation.scenarios')">
    <span slot="controls">
      <v-btn
        fab
        dark
        small
        elevation="0"
        color="primary"
        v-if="hasAccess('admin')"
        slot="actions"
        @click="$router.push(urlCreate)"
      >
        <v-icon dark small>
          {{icons.mdiPlus}}
        </v-icon>
      </v-btn>
    </span>
    <AppCard>
      <ViewScenariosList />
    </AppCard>
  </LayoutMain>
</template>

<script>
import LayoutMain from '@/components/shared/layout/Main.vue'
import ViewScenariosList from '@/components/views/viewScenarios/List'
import { PATH_PAGE_SCENARIOS } from '@/config/paths'
import { hasAccess } from '@/lib/access'
import { mdiPlus } from '@mdi/js'

export default {
  name: 'ViewScenarios',

  components: {
    LayoutMain,
    ViewScenariosList,
  },

  computed: {
    urlCreate: () => `${PATH_PAGE_SCENARIOS}/new`,
  },

  data() {
    return {
      icons: {
        mdiPlus,
      },
    }
  },

  methods: {
    hasAccess,
  },
}
</script>

<style lang="sass" scoped>
</style>

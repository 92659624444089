import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Vuelidate from 'vuelidate'
// @ts-ignore
import { mask } from 'vue-the-mask'
import AppLoader from '@/components/shared/AppLoader.vue'
import AppError from '@/components/shared/AppError.vue'
import AppButton from '@/components/shared/AppButton.vue'
import ActionButton from '@/components/shared/ActionButton.vue'
import TableButton from '@/components/shared/TableButton.vue'
import AppDialog from '@/components/shared/AppDialog.vue'
import AppCard from '@/components/shared/AppCard.vue'
import AppForm from '@/components/shared/AppForm.vue'
import AppFormRow from '@/components/shared/appForm/Row.vue'
import AppListingAction from '@/components/shared/AppListingAction.vue'
import router from '../lib/Router'
import vuetify from '../plugins/vuetify'
import { messages } from './messages'

Vue.directive('mask', mask)
Vue.use(Vuelidate)
Vue.use(VueI18n)

Vue.component('AppLoader', AppLoader)
Vue.component('AppError', AppError)
Vue.component('AppButton', AppButton)
Vue.component('ActionButton', ActionButton)
Vue.component('TableButton', TableButton)
Vue.component('AppDialog', AppDialog)
Vue.component('AppCard', AppCard)
Vue.component('AppForm', AppForm)
Vue.component('AppFormRow', AppFormRow)
Vue.component('AppListingAction', AppListingAction)

Vue.config.productionTip = false
Vue.config.ignoredElements = ['field', 'block', 'category', 'xml', 'mutation', 'value', 'sep']

export const i18n = new VueI18n({ locale: 'en', messages })
export const options = { i18n, vuetify, router }

<template>
  <v-btn
    small
    elevation="0"
    :disabled="disabled"
    :title="title"
    class="root"
    @click="$emit('click', $event)"
  >
    <slot />
  </v-btn>
</template>

<script>

export default {
  name: 'TableButton',

  props: {
    disabled: Boolean,
    isLoading: Boolean,
    title: String,
  },

  data() {
    return {
    }
  },
}
</script>

<style lang="sass" scoped>
  .root
    max-height: 30px !important
    color: #999 !important
</style>

import { request, IResponse } from '@/lib/Request'
import { config } from '@/config/config'
import { validateResponse } from '@/lib/validate'
import { TPrompt } from '@/models/Prompt'

const { apiUrl } = config

interface IGetItems {
  items: TPrompt[]
  total: number
}

export const getItems = async (
    page = 1,
    size = 20,
    sortField = 'created_at',
    sortDir: 'asc' | 'desc' = 'desc',
    search?: string,
  ): Promise<IResponse<IGetItems>> => {
  const payload: TObject = {
    page,
    limit: size,
    order_by: sortField,
    order: sortDir,
  }
  if (search) {
    payload.search = search
  }

  const response = await request.get(`${apiUrl}/prompt/list`, payload)
  validateResponse(response.data, ['prompts'])
  validateResponse(response.data.prompts || {}, ['data'])
  validateResponse(response.data.prompts?.pagination || {}, ['total'])

  const items: TPrompt[] = response.data.prompts?.data || []
  const total = parseInt(response.data.prompts?.pagination?.total) || 0

  return {
    status: response.status,
    error: response.error,
    data: {
      total,
      items,
    },
  }
}

/**
 */
export const remove = async (id: string): Promise<IResponse> => {
  return await request.delete(`${apiUrl}/prompt/${id}`)
}

/**
 */
 export const upload = async (formdata: HTMLFormElement, onUploadProgress?: void): Promise<IResponse> => {
  return await request.post(`${apiUrl}/prompt`, formdata, { onUploadProgress })
}

<template>
  <div class="nav-root">
    <v-btn icon @click="logout">
      <v-icon class="icon">{{icons.mdiExitToApp}}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mdiExitToApp } from '@mdi/js'
import { authService } from '@/services/AuthService'

export default {
  name: 'NavLogout',

  components: {
  },

  data() {
    return {
      icons: { mdiExitToApp },
    }
  },

  methods: {
    logout() {
      authService.logout().catch(window.logger.error)
    },
  },
}
</script>

<style lang="sass" scoped>
.nav-root
  cursor: pointer
  width: 40px
  height: 40px
  border-radius: 20px
  display: flex
  align-items: center
  justify-content: center
  transition: all 0.2s

  .icon
    color: rgba(0, 0, 0, 0.54)
</style>

import { state } from '@/store/state'
import { getAccountInvited, joinInvited, recoverPassword, recoverPasswordVerify, signIn, signUp, signUpVerify, logout } from '@/api/AuthApi'
import { getAccount, getCompany } from '@/api/UserApi'
import { PATH_PAGE_HOME, PATH_PAGE_SIGN_IN } from '@/config/paths'
import { TAccount, TAccountProfile, TAccountType, create } from '@/models/Account'
import { userService } from '@/services/UserService'
import { normalizePhoneNumber } from '@/lib/utils'

class AuthService {
  public state = state
  private onAuthHandler: () => Promise<void> = async () => {}

  /**
   */
  public async loadAccount(): Promise<boolean> {
    const { error, data: { account } } = await getAccount()
    if (!error) {
      return this.authAccount(account)
    }
    return false
  }

  /**
   */
  public async loadCompany(): Promise<boolean> {
    const { error, data: { company } } = await getCompany()
    if (!error && company) {
      this.state.company = company
      if (company.created_by === this.state.account.id) {
        this.state.account.creator = true
      }
      return true
    }
    return false
  }

  /**
   */
  public async signIn(email: string, password: string): Promise<{ error: string | null }> {
    const { error, data: { account } } = await signIn(email, password)
    if (this.authAccount(account)) {
      await window.$vm.$router.push(PATH_PAGE_HOME)
    }
    return { error }
  }

  /**
   */
  public async signUp(email: string, password: string, firstName: string, lastName: string, numberStr: string): Promise<{ error: string | null }> {
    const timeZone = this.getCurrentTimezone()
    const number = normalizePhoneNumber(numberStr)
    const profile: TAccountProfile = { ...create().profile, firstName, lastName, number, timeZone, onboarding: 'welcome' }
    const { error } = await signUp(email, password, profile)
    return { error }
  }

  /**
   */
  public async signUpVerify(email: string, code: string): Promise<boolean> {
    const { data: { account } } = await signUpVerify(email, code)
    if (this.authAccount(account)) {
      await window.$vm.$router.push(PATH_PAGE_HOME)
      return true
    }
    return false
  }

  /**
   */
  public async recoverPassword(email: string): Promise<{ error: string | null }> {
    const { error } = await recoverPassword(email)
    return { error }
  }

  /**
   */
  public async recoverPasswordVerify(email: string, code: string, password: string): Promise<boolean> {
    const { data: { account } } = await recoverPasswordVerify(email, code)
    if (account && this.authAccount(account)) {
      userService.update(account.id, account.profile, password).catch(window.logger.error)
      await window.$vm.$router.push(PATH_PAGE_HOME)
      return true
    }
    return false
  }

  /**
   */
  public async getInvitedAccount(token: string): Promise<{ error: null | string, account: null | TAccount }> {
    const { error, data: { account } } = await getAccountInvited(token)
    if (account && account.type === TAccountType.TYPE_REGULAR) {
      if (this.authAccount(account)) {
        await window.$vm.$router.push(PATH_PAGE_HOME)
      }
    }
    return { error, account }
  }

  /**
   */
  public async joinInvited(invitedToken: string, email: string, password: string, firstName: string, lastName: string, numberStr: string): Promise<boolean> {
    const timeZone = this.getCurrentTimezone()
    const number = normalizePhoneNumber(numberStr)
    const profile: TAccountProfile = { ...create().profile, firstName, lastName, number, timeZone, onboarding: '' }
    const { data: { account } } = await joinInvited(invitedToken, email, password, profile)
    if (account && account.type === TAccountType.TYPE_REGULAR) {
      if (this.authAccount(account)) {
        await window.$vm.$router.push(PATH_PAGE_HOME)
        return true
      }
    }
    return false
  }

  /**
   */
  public async logout(reload = true): Promise<void> {
    await logout()
    if (reload) {
      document.location.href = PATH_PAGE_SIGN_IN
    }
  }

  /**
   */
  public onAuth(onAuthHandler: () => Promise<void>): void {
    this.onAuthHandler = onAuthHandler
  }

  /**
   */
  private authAccount = (account: TAccount | null): boolean => {
    const isAuth = this.state.auth
    if (account && account.type !== TAccountType.TYPE_UNCONFIRMED) {
      if (this.state.company.created_by === account.id) {
        account.creator = true
      }
      this.state.account = account
      this.state.auth = true
      if (!isAuth && this.state.auth) {
        this.onAuthHandler().catch(window.logger.error)
      }
      return true
    }
    return false
  }

  /**
   */
  private getCurrentTimezone = (): string => {
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    return this.state.timezonesLoaded && this.state.timezones.includes(browserTimezone)
      ? browserTimezone
      : ''
  }
}

const authService = new AuthService()
export { authService, AuthService }

<template>
  <LayoutError>
    <div class="root">
      <h1 class="heading">404</h1>
      <p class="sub-heading">{{$t('notFound.subHeading')}}</p>
      <div class="bottom">
        <v-btn
            large
            class="btn"
            elevation="0"
            color="primary"
            @click="$router.push(urlHome)"
        >
          {{$t('notFound.backHome')}}
        </v-btn>
      </div>
    </div>
  </LayoutError>
</template>

<script>

import LayoutError from '@/components/shared/layout/Error'
import { PATH_PAGE_HOME } from '@/config/paths'

export default {
  name: 'ViewNotFound',

  components: {
    LayoutError,
  },

  data() {
    return {
      urlHome: PATH_PAGE_HOME,
    }
  },
}
</script>

<style lang="sass" scoped>
  .root
    display: flex
    flex-direction: column
    align-items: center
    .heading
      font-weight: 600
      font-size: 4rem
    .sub-heading
      text-align: center
      line-height: 1.4
      font-weight: 600
      font-size: 1.8rem
    .text
      text-align: center
      line-height: 1.4
      font-size: 1.4rem
    .btn
      margin: 16px 0 24px 0
      width: 150px
      text-transform: uppercase
</style>

<template>
  <LayoutMain class="root" :title="$t('navigation.users')">
    <AppCard>
      <v-container fluid class="pt-0">
        <v-row>
          <v-col xs="12" sm="12" md="4" class="pt-0">
            <ViewUserSearch />
          </v-col>
          <v-col xs="12" sm="12" md="8" class="pt-0">
            <ViewUsersInvite />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pl-0 pr-0">
            <ViewUsersList />
          </v-col>
        </v-row>
      </v-container>
    </AppCard>
  </LayoutMain>
</template>

<script>
import LayoutMain from '@/components/shared/layout/Main.vue'
import ViewUserSearch from '@/components/views/viewUsers/Search.vue'
import ViewUsersInvite from '@/components/views/viewUsers/Invite.vue'
import ViewUsersList from '@/components/views/viewUsers/List.vue'

export default {
  name: 'ViewUsers',

  components: {
    LayoutMain,
    ViewUserSearch,
    ViewUsersInvite,
    ViewUsersList,
  },

  data() {
    return {
    }
  },
}
</script>

<style lang="sass" scoped>
</style>

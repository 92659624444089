<template>
  <div :class="['root', wide && 'wide']">
    <div class="plans">
      <BillingPlanBlock
          v-for="dataplan of visiblePlans"
          :key="dataplan.id"
          :wide="wide"
          :dataplan="dataplan"
          :loading="loading"
          :allowAll="allowAll"
          :preSelect="dataplan.name.toLowerCase() === `${prePlan}`.toLowerCase()"
          @select="$emit('select', $event)"
      />
    </div>
    <slot></slot>
  </div>
</template>

<script>
import BillingPlanBlock from '@/components/shared/billingPlan/Block'
import { state } from '@/store/state'
import { billingService } from '@/services/BillingService'

export default {
  name: 'BillingPlans',

  components: {
    BillingPlanBlock,
  },

  props: {
    prePlan: String,
    loading: Boolean,
    allowAll: Boolean,
    hideTrial: Boolean,
  },

  data() {
    return {
      state,
    }
  },

  computed: {
    wide() { return this.state.screen.width >= 1000 },
    dataplans() { return this.state.billing.dataplans },
    visiblePlans() {
      return this.hideTrial
          ? this.dataplans.filter(i => i.name.toLowerCase() !== 'trial')
          : this.dataplans
    },
  },

  mounted () {
    billingService.loadDataplans().catch(window.logger.error)
  },
}
</script>

<style lang="sass" scoped>
.root
  padding: 10px

  &.wide
    padding: 20px

  .plans
    display: flex
    justify-content: center
    flex-wrap: wrap
    row-gap: 15px
</style>

<template>
  <v-app light>
    <v-main>
      <v-container>
        <div class="root">
          <v-card class="root__box pa-8" elevation="0">
            <slot></slot>
          </v-card>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'LayoutError',

  data() {
    return {
    }
  },
}
</script>

<style lang="sass" scoped>
.root
  display: flex
  flex-direction: column
  align-items: center

  &__box
    width: 640px
    max-width: calc(100% - 20px)
    margin-top: 200px

</style>

<template>
  <Onboarding v-if="currentUser.profile.onboarding" />
  <LayoutMain v-else class="root" :title="$t('navigation.dashboard')">
    <template slot="controls">
      <Balance />
    </template>
    <Dashboard v-if="currentUser.admin" />
  </LayoutMain>
</template>

<script>
import { state } from '@/store/state'
import Onboarding from '@/components/shared/Onboarding'
import LayoutMain from '@/components/shared/layout/Main.vue'
import Dashboard from '@/components/views/viewHome/Dashboard'
import Balance from '@/components/views/viewBilling/balanceAndPlan/Balance.vue'

export default {
  name: 'ViewHome',

  components: {
    Dashboard,
    Onboarding,
    LayoutMain,
    Balance,
  },

  computed: {
    currentUser() { return this.state.account },
  },

  data() {
    return {
      state,
    }
  },

  methods: {
  },
}
</script>

<style lang="sass" scoped>
</style>

<template>
  <router-view />
</template>

<script>

import { state } from '@/store/state'

export default {
  name: 'App',

  data() {
    return { state }
  },
}
</script>

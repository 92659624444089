<template>
  <LayoutAuth class="root" :title="$t('invited.title')">
    <AppLoader v-if="isAccountLoading" />

    <AppError v-if="!email" :error="authError" />

    <FormSignUp
        v-if="email"
        :isLoading="isLoading"
        :userEmail="email"
        :userNumber="number"
        :authError="authError"
        :buttonLabel="$t('invited.join')"
        @submit="onSubmit"
    />
  </LayoutAuth>
</template>

<script>
import LayoutAuth from '@/components/shared/layout/Auth.vue'
import FormSignUp from '@/components/shared/FormSignUp'
import * as paths from '@/config/paths'
import { authService } from '@/services/AuthService'

export default {
  name: 'ViewInvited',

  components: {
    LayoutAuth,
    FormSignUp,
  },

  data() {
    return {
      paths,
      email: '',
      number: '',
      formData: {},
      authError: null,
      isLoading: false,
      isAccountLoading: true,
    }
  },

  beforeMount() {
    authService.logout(false).catch(window.logger.error)
  },

  mounted() {
    const token = this.getQueryToken()
    authService.getInvitedAccount(token)
      .then(({ error, account }) => {
        if (error) {
          this.authError = error
        } else if (!account) {
          this.authError = 'User not found'
        } else {
          this.email = account.email
          this.number = account.profile.number
        }
      })
      .catch(window.logger.error)
      .finally(() => this.isAccountLoading = false)
  },

  methods: {
    async onSubmit(formData) {
      const UNKNOWN_ERROR = 'Unknown error'
      const token = this.getQueryToken()

      this.isLoading = true
      this.formData = formData
      try {
        if (!await authService.joinInvited(token, this.email, formData.password, formData.firstName, formData.lastName, formData.number)) {
          this.authError = UNKNOWN_ERROR
        }
      } catch (e) {
        this.authError = UNKNOWN_ERROR
        window.logger.error(e)
      }
      this.isLoading = false
    },

    getQueryToken() {
      return `${this.$router.history.current.query.token || ''}`
    },
  },
}
</script>

<style lang="sass" scoped>
</style>

<template>
  <div>
    <v-data-table
        class="root row-hover"
        :item-key="'id'"
        :headers="headers"
        :items="state.dids.list.items"
        :options.sync="state.dids.list.options"
        :server-items-length="state.dids.list.total"
        :loading="state.dids.list.isLoading"
        :no-data-text="$t('common.empty')"
        :page.sync="state.dids.list.options.page"
        hide-default-footer
    >
      <!-- @dblclick:row="($event, data) => onEdit(data.item)" -->

      <template v-slot:[`item.account_profile`]="{ item }">
        {{getUserFullName(item)}}
      </template>

      <template v-slot:[`item.number`]="{ item }">
        {{formatPhoneNumber(item.number)}}
      </template>

      <template v-slot:[`item.toll_free`]="{ item }">
        <v-icon style="width: 1.2rem" v-if="item.toll_free">{{icons.mdiCheck}}</v-icon>
      </template>

      <template v-slot:[`item.sip`]="{ item }">
        <div v-if="item.originator === 'external'">{{item.number}}@{{domain}}</div>
      </template>

      <template v-slot:[`item.scenario_id`]="{ item }">
        <a
          v-if="state.account.admin && !item.fax && !item.transfer"
          :href="`/scenarios/${item.scenario_id}`"
          @click.prevent="$router.history.push(`/scenarios/${item.scenario_id}`)"
          :title="item.scenario_description"
          class="link"
        >
          {{$t('scenarios.scenario')}}: {{item.scenario_name}}
        </a>
        <span v-else-if="item.fax">
          {{`${$t('dids.faxTo')} ${getUserFullName(item)}`}}
        </span>
        <span v-else-if="item.transfer">
          {{`${$t('dids.transferTo')} ${getUserFullName(item)}`}}
        </span>
      </template>

      <template v-slot:[`item.originator`]="{ item }">
        <div v-if="item.originator === 'internal'">
          {{item.toll_free ? $t('dids.tollFree') : $t('dids.local')}}
        </div>
        <div v-else-if="item.originator === 'external'">
          <a
            v-if="item.originator === 'external' && hasAccess('admin')"
            :title="$t('dids.sipUrl')"
            @click="showSipUrl(item)"
          >
            {{$t('dids.sipUrl')}}
          </a>
        </div>
        <div>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="list-actions">
          <AppListingAction
            v-if="hasAccess('admin')"
            :title="$t('dids.changeDialPlan')"
            @click="onEdit(item)"
          >
            {{icons.mdiPencil}}
          </AppListingAction>
          <AppListingAction
            v-if="hasAccess('admin')"
            :title="$t('dids.deleteNumber')"
            @click="onDelete(item)"
          >
            {{icons.mdiDelete}}
          </AppListingAction>
        </div>
      </template>
    </v-data-table>

    <v-pagination
      v-model="state.dids.list.options.page"
      @input="loadItems"
      :length="state.dids.list.totalPages"
      :total-visible="7"
    />

    <AppDialog ref="dialog" />

  </div>
</template>

<script>
import { state } from '@/store/state'
import { appService } from '@/services/AppService'
import { didService } from '@/services/DidService'
import { userService } from '@/services/UserService'
import { PATH_PAGE_NUMBERS } from '@/config/paths'
import { hasAccess } from '@/lib/access'
import { formatPhoneNumber } from '@/lib/utils'
import { mdiPencil,
  mdiDelete,
  mdiCheck,
  mdiAccount,
  mdiAccountMultiple,
  mdiCurrencyUsdOff,
  mdiHomeVariantOutline,
  mdiTextBoxOutline,
  mdiEarth } from '@mdi/js'

export default {
  name: 'ViewNumbersList',

  components: {
  },

  computed: {
    domain() { return this.state.company.domain },
  },

  data() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
        mdiCheck,
        mdiAccount,
        mdiAccountMultiple,
        mdiCurrencyUsdOff,
        mdiHomeVariantOutline,
        mdiTextBoxOutline,
        mdiEarth,
      },
      state,
      headers: [
        {
          text: this.$t('common.user'),
          align: 'start',
          value: 'account_profile',
          sortable: true,
        },
        {
          text: this.$t('common.number'),
          align: 'start',
          value: 'number',
          sortable: true,
        },
        {
          text: this.$t('dids.originator'),
          align: 'start',
          value: 'originator',
          sortable: true,
        },
        {
          text: this.$t('dids.callTreatment'),
          align: 'start',
          value: 'scenario_id',
          sortable: false,
        },
        {
          text: '',
          align: 'end',
          value: 'actions',
          sortable: false,
        },
      ],
    }
  },

  mounted() {
    (async () => {
      await this.loadItems()
    })().catch(window.logger.error)
  },

  methods: {
    hasAccess,
    formatPhoneNumber,
    async loadItems(page) {
      await didService.loadItems(page)
    },

    onEdit(item) {
      this.$router.history.push(`${PATH_PAGE_NUMBERS}/${item.id}`)
    },

    async onDelete(item) {
      const dialogSettings = {
        title: this.$t('dids.dialogDeleteTitle'),
        html: `${this.$t('dids.dialogDeleteText')} <span class="text-no-wrap">${this.formatPhoneNumber(item.number)}?</span>`,
        actionText: this.$t('common.delete'),
      }

      if (await this.$refs.dialog.show(dialogSettings)) {
        try {
          const { error } = await didService.remove(item.id)
          if (!error) {
            appService.showSuccess(this.$t('dids.numberDeleted'))
            await this.loadItems()
          } else {
            appService.showError(error)
          }
        } catch (e) {
          window.logger.error(e)
          appService.showError()
        }
      }
    },

    showSipUrl(item) {
      if (item?.id && item?.number) {
        const url = `${item.number}@${this.domain}`
        navigator.clipboard.writeText(url)
        appService.showInfo(this.$t('common.copiedToClipboard'))
      }
    },

    getUserFullName(item) {
      return userService.getUserFullName(item.account_profile)
    },
  },
}
</script>

<style lang="sass" scoped>
  .root
    .text-block
      cursor: text
</style>

import { parseDate } from '@/lib/utils'
import { validateFields } from '@/lib/validate'

const initialData = {
  id: '',
  scenario_id: '',
  begin: null as null | Date,
  end: null as null | Date,
  duration: 0,
  hangup_cause: '',
  direction: '',
  fromNumber: '',
  toNumber: '',
  transfer_account_uid: '',
  record_id: '',
  created_at: null as null | Date,
}

export type TCallLogItem = typeof initialData

export const create = (data?: Partial<TCallLogItem>): TCallLogItem => {
  return { ...initialData, ...data }
}

export const map = (data: TObject): TCallLogItem => {
  validateFields(data, [
    'uuid', 'scenario_id', 'begin', 'end', 'duration', 'hangup_cause',
    'direction', 'fromNumber', 'toNumber', 'transfer_account_uid', 'created_at',
  ])

  return {
    ...initialData,
    id: `${data.uuid}` || initialData.id,
    scenario_id: data.scenario_id || initialData.scenario_id,
    begin: parseDate(data.begin),
    end: parseDate(data.end),
    duration: data.duration || initialData.duration,
    hangup_cause: data.hangup_cause || initialData.hangup_cause,
    direction: data.direction || initialData.direction,
    fromNumber: data.fromNumber || initialData.fromNumber,
    toNumber: data.toNumber || initialData.toNumber,
    transfer_account_uid: data.transfer_account_uid || initialData.transfer_account_uid,
    record_id: data.record_id || initialData.record_id,
    created_at: parseDate(data.created_at),
  }
}

<template>
  <div class="root">
    <v-dialog
        v-model="dialog"
        max-width="370"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="filter-buttons">
          <AppButton
              v-bind="attrs"
              v-on="on"
          >
            {{$t('common.filters')}}
            <div v-if="count" class="filter-count">{{count}}</div>
          </AppButton>
          <v-btn v-if="count" @click="$emit('reset')" text class="ml-1" color="primary">
            {{$t('common.reset')}}
          </v-btn>
        </div>
      </template>

      <v-card>
        <v-card-title class="headline">
          {{$t('common.filters')}}
        </v-card-title>
        <v-form @submit.prevent="apply" novalidate="true">
          <v-card-text>
            <slot></slot>
          </v-card-text>
          <v-card-actions class="pr-4 pl-4 pb-4">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="cancel"
            >
              {{$t('common.cancel')}}
            </v-btn>
            <v-btn
                color="primary"
                type="submit"
            >
              {{$t('common.apply')}}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <div class="filter-buttons">
    </div>
  </div>
</template>

<script>

export default {
  name: 'Filters',

  components: {
  },

  props: {
    count: { type: Number, required: true },
  },

  data() {
    return {
      dialog: false,
    }
  },

  methods: {
    cancel() {
      this.dialog = false
      this.$emit('cancel')
    },

    apply() {
      this.dialog = false
      this.$emit('apply')
    },
  },
}
</script>

<style lang="sass" scoped>
.filter-buttons
  display: flex
  align-items: center
  line-height: 1

  .filter-count
    line-height: 1
    height: 20px
    min-width: 20px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 10px
    background: var(--v-primary-base)
    font-size: 0.9rem
    color: #fff
    margin-left: 10px
</style>

<template>
  <v-app light class="root">
    <v-main>
      <v-container>
        <div class="auth">
          <Logo class="logo" />
          <v-card class="auth__box" :style="{ width }">
            <v-card-title class="auth__title pt-7 pr-7 pl-7 pb-0 mb-0">
              <h1 class="text-h4 font-weight-bold">{{title}}</h1>
            </v-card-title>
            <v-card-text>
              <div class="pa-6">
                <slot />
              </div>
            </v-card-text>
            <div class="auth__actions d-flex justify-center">
              <slot name="actions" />
            </div>
          </v-card>
          <div class="auth__footer">
            <slot name="footer" />
          </div>
        </div>
      </v-container>
    </v-main>
    <CookieBanner />
  </v-app>
</template>

<script>
import { state } from '@/store/state'
import Logo from '../../../assets/logo.svg'
import CookieBanner from '@/components/shared/CookieBanner'

export default {
  name: 'LayoutAuth',

  components: {
    CookieBanner,
    Logo,
  },

  data() {
    return {
      state,
    }
  },

  props: {
    title: { type: String, required: true },
    width: String,
  },
}
</script>

<style lang="sass" scoped>
.root
  position: relative
  background: #F0F4FA !important

.logo
  height: 35px
  margin-top: 50px

.auth
  display: flex
  flex-direction: column
  align-items: center

  &__box
    width: 400px
    max-width: calc(100% - 20px)
    border-radius: 5px
    box-shadow: 0px 20px 70px rgba(12, 37, 74, 0.15) !important
    margin-top: 25px
    border: 1px solid #DAE0EB
    box-sizing: border-box

  &__actions
    background-color: #F5F5F7 !important

  &__title
    text-align: center
    justify-content: center
    margin-top: 30px

  &__footer
    margin-top: 16px

</style>

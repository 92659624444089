class StorageService {

  /**
   * @param key
   * @param defaultValue
   */
  public get<T>(key: string, defaultValue: T | undefined = undefined): T | undefined {
    const storageValue = localStorage.getItem(key)
    if (storageValue) {
      try {
        return JSON.parse(storageValue) as T
      } catch (e) {
        return defaultValue
      }
    }
    return defaultValue
  }

  /**
   * @param key
   * @param value
   */
  public set<T>(key: string, value: T) {
    const storageValue = JSON.stringify(value)
    localStorage.setItem(key, storageValue)
  }
}

const storageService = new StorageService()
export { storageService }

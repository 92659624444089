<template>
  <div :class="['block', wide && 'wide', preSelect && 'preselected']">
    <div class="block-header">
      <div class="name">{{dataplan.name}}</div>
      <div class="price"><small>$</small>{{dataplan.cost}}
        <span class="price-title"> / {{priceTitle}}</span>
      </div>
    </div>
    <div class="block-content">
      <BlockRow v-if="dataplan.name.toLowerCase() !== 'trial'" :label="$t('dataplan.opt1')" />
      <BlockRow :label="$t('dataplan.opt2')" />
      <BlockRow :label="$t('dataplan.opt3')" />
      <BlockRow :label="$t('dataplan.opt4')" />
      <BlockRow :label="$t('dataplan.opt5')" />
      <BlockRow :label="$t('dataplan.opt6')" />
      <BlockRow :label="$t('dataplan.opt7')" />
      <BlockRow :label="$t('dataplan.opt8')" />
      <BlockRow v-if="dataplan.minutes" :label="$t('dataplan.freeMinutes')" :value="dataplan.minutes" />
      <BlockRow v-if="dataplan.cost_minute" :label="$t('dataplan.callMinutes')" :value="`$${dataplan.cost_minute}`" />
      <BlockRow v-else-if="dataplan.cost_minute === 0" :label="$t('dataplan.unlimitedMinutes')" />
      <BlockRow v-if="dataplan.cost_sms" :label="$t('dataplan.sms')" :value="`$${dataplan.cost_sms}`" />
      <BlockRow v-else-if="dataplan.cost_sms === 0" :label="$t('dataplan.unlimitedSMS')" />
    </div>

    <div class="block-footer">
      <div class="action">
        <AppButton
            :type="preSelect ? 'secondary' : 'primary'"
            @click="$emit('select', dataplan)"
            :disabled="isDisabled()"
            class="action-select"
        >
          {{buttonText(dataplan)}}
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script>
import { state } from '@/store/state'
import BlockRow from '@/components/shared/billingPlan/block/Row'

export default {
  name: 'BillingPlanBlock',

  components: {
    BlockRow,
  },

  props: {
    dataplan: { type: Object, required: true },
    allowAll: Boolean,
    preSelect: Boolean,
    loading: Boolean,
    wide: Boolean,
  },

  computed: {
    activePlan() { return this.state.billing.dataplan },
    isCurrentPlan() { return this.activePlan.id && this.dataplan.id === this.activePlan.id },
    priceMain() { return Math.floor(this.dataplan.cost) },
    pricePart() { return Math.round(this.dataplan.cost % 1 * 100) },
    priceTitle: function () {
      if (this.dataplan.cost) {
        return this.$t('dataplan.monthPerUser')
      } else {
        switch (this.dataplan.duration_days) {
          case 1:
            return this.$t('dataplan.day')
            break
          case 7:
            return this.$t('dataplan.week')
            break
          case 30:
          case 31:
            return this.$t('dataplan.month')
            break
          default:
            return this.$t('dataplan.days')
        }
      }
    },
  },

  data() {
    return {
      state,
    }
  },

  methods: {
    isDisabled() {
      if (this.allowAll) {
        return false
      }
      return this.isCurrentPlan || this.loading
    },

    buttonText() {
      if (this.isCurrentPlan && !this.allowAll) {
        return this.$t('dataplan.current')
      }

      return this.$t('dataplan.select')
    },
  },
}
</script>

<style lang="sass" scoped>
.block
  width: 300px
  height: 600px
  display: flex
  flex-direction: column
  background: linear-gradient(194.04deg, #A1D5F4 0%, #DDF2FE 20.31%, #FFFFFF 100%)
  border-radius: 30px 30px 0 45px
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px

  & + .block
    margin-left: 10px

  &.wide
    & + .block
      margin-left: 20px

  .block-header
    width: 100%
    min-height: 120px
    border-bottom: 1px solid rgba(42, 126, 253, 0.15)
    padding: 30px 0 0 40px
    line-height: 24px

    .name
      font-size: 1.7rem
      font-weight: 600
      letter-spacing: 0.02em

    .price
      font-size: 2.2rem
      line-height: 30px

      .price-title
        font-size: 1rem
        font-weight: 300

      small
        font-size: 1.4rem

  .block-content
    height: 100%
    padding: 30px 40px 0px 40px

  .block-footer
    margin: 0 40px 40px 40px

  .action
    display: flex
    align-items: center
    justify-content: center
    margin-top: 10px

.block:hover,
.preselected
  background: $secondary-color !important
  color: #FFF !important

.block:hover
  .action-select,
  .action-select:hover
    background: #FFF !important
    color: $secondary-color !important

.action-select
  width: 100%
</style>

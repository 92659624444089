import { request, IResponse } from '@/lib/Request'
import { config } from '@/config/config'
import { map as mapPaymentMethod, TPaymentMethod } from '@/models/PaymentMethod'
import { IBillingHistoryFilter, IBillingSettings, ICallLogFilter } from '@/services/BillingService'
import { map as mapHistoryItem, THistoryItem } from '@/models/HistoryItem'
import { map as mapDataplan, TDataplan } from '@/models/Dataplan'
import { map as mapCallLogItem, TCallLogItem } from '@/models/CallLogItem'
import { validateResponse } from '@/lib/validate'
import { getUTCEndOfDate, getUTCStartOfDate, parseDate } from '@/lib/utils'
import { TStats } from '@/models/Stats'
import { map as mapInvoice, TInvoice } from '@/models/Invoice'

const { apiUrl } = config

interface IGetStripePk {
  stripe_pk: string
}

/**
 */
export const getStripePk = async (): Promise<IResponse<IGetStripePk>> => {
  const response = await request.get<IGetStripePk>(`${apiUrl}/session/stripe/key`, {})
  validateResponse(response.data, ['stripe_pk'])

  return response
}

interface IGetBalance {
  balance: number
}

/**
 */
export const getBalance = async (): Promise<IResponse<IGetBalance>> => {
  const response = await request.get<IGetBalance>(`${apiUrl}/billing/balance`, {})
  validateResponse(response.data, ['balance'])

  response.data.balance = parseFloat(`${response.data.balance}`) || 0
  return response
}

interface IGetInvoice {
  invoice: null | TInvoice
}

/**
 */
export const getInvoice = async (): Promise<IResponse<IGetInvoice>> => {
  const response = await request.get<TObject>(`${apiUrl}/billing/invoice/incoming`, {})
  validateResponse(response.data, ['invoice'])

  response.data.invoice = response.data.invoice ? mapInvoice(response.data.invoice) : null
  return response as IResponse<IGetInvoice>
}

interface IGetPaymentMethods {
  items: TPaymentMethod[]
  default_payment_method: string
}

/**
 */
export const getPaymentMethods = async (): Promise<IResponse<IGetPaymentMethods>> => {
  const response = await request.get(`${apiUrl}/billing/payment_methods`, {})
  validateResponse(response.data, ['data', 'default_payment_method'])

  const data: TObject[] = response.data.data || []
  response.data.items = data.map(mapPaymentMethod)
  return response as IResponse<IGetPaymentMethods>
}

interface IAddPaymentMethod {
  uid: string
  source: TPaymentMethod
}

/**
 */
export const addPaymentMethod = async (cardToken: TObject): Promise<IResponse<IAddPaymentMethod>> => {
  const response = await request.post<IAddPaymentMethod>(`${apiUrl}/billing/payment_methods/add`, { token: cardToken })
  validateResponse(response.data, ['uid', 'source'])
  if (response.data.source?.id) {
    response.data.source = mapPaymentMethod({ id: response.data.source.id, card: response.data.source })
  }
  return response as IResponse<IAddPaymentMethod>
}

/**
 */
export const deletePaymentMethod = async (id: string): Promise<IResponse> => {
  return request.delete(`${apiUrl}/billing/payment_methods`, { id })
}

/**
 */
export const defaultPaymentMethod = async (id: string): Promise<IResponse> => {
  return request.post(`${apiUrl}/billing/payment_methods/default`, { id })
}

interface IAddFunds {
  uid: string
  id: string
  amount: number
  currency: string
  created: number
  status: string
}

/**
 */
export const addFunds = async (amount: number, cardId?: string, currency = 'usd'): Promise<IResponse<IAddFunds>> => {
  const payload: TObject = { amount, currency }
  if (cardId) { payload.card_id = cardId }

  const response = await request.post(`${apiUrl}/billing/balance`, payload)
  validateResponse(response.data, ['uid', 'id', 'amount', 'currency', 'created', 'status'])
  return response as IResponse<IAddFunds>
}

export interface IGetActiveDataplan {
  dataplan?: TDataplan
}

/**
 */
export const getActiveDataplan = async (): Promise<IResponse<IGetActiveDataplan>> => {
  const response = await request.get<IGetActiveDataplan>(`${apiUrl}/billing/dataplan`)

  if (response.data.dataplan) {
    response.data.dataplan = mapDataplan(response.data.dataplan)
  }

  return response as IResponse<IGetActiveDataplan>
}

export interface IGetDataplans {
  dataplans: TDataplan[]
}

/**
 */
export const getDataplans = async (): Promise<IResponse<IGetDataplans>> => {
  const response = await request.get<IGetDataplans>(`${apiUrl}/billing/dataplans`)
  validateResponse(response.data, ['dataplans'])

  response.data.dataplans = response.data.dataplans.map(mapDataplan)
  response.data.dataplans.sort((a, b) => a.cost - b.cost)
  return response as IResponse<IGetDataplans>
}

export interface IChangeDataplan {
  dataplan?: TDataplan
  ok: boolean
  message: string
}

/**
 */
export const changeDataplan = async (dataplanId: string): Promise<IResponse<IChangeDataplan>> => {
  const response = await request.put<IChangeDataplan>(`${apiUrl}/billing/dataplan`, {
    dataplan_id: dataplanId,
  })

  if (response.data.dataplan) {
    response.data.dataplan = mapDataplan(response.data.dataplan)
  }

  return response as IResponse<IChangeDataplan>
}

export interface IGetBillingSettings {
  billing: {
    settings: IBillingSettings
    payment: {
      stripe: {
        customerId: string
      }
      trial?: { fingerprint?: string }
    }
    trial: boolean
    dev: boolean,
    paid_till: Date | null
  }
}

/**
 */
export const getBillingSettings = async (): Promise<IResponse<IGetBillingSettings>> => {
  const response = await request.get<IGetBillingSettings>(`${apiUrl}/billing`, {})
  validateResponse(response.data, ['billing'])
  validateResponse(response.data.billing || {}, ['settings', 'payment', 'trial', 'paid_till'])
  validateResponse(response.data.billing?.payment || {}, ['stripe'])
  validateResponse(response.data.billing?.payment?.stripe || {}, ['customerId'])
  if (response.data?.billing?.settings) {
    response.data.billing.settings.next_dataplan_id = `${response.data.billing.settings.next_dataplan_id || ''}`
    response.data.billing.settings.cancel_at_paid_till = !!response.data?.billing?.settings?.cancel_at_paid_till
  }

  response.data.billing.paid_till = parseDate(response.data.billing.paid_till as string | null)
  return response
}

type ISaveBillingSettings = IGetBillingSettings

export const saveBillingSettings = async (formData: IBillingSettings): Promise<IResponse<ISaveBillingSettings>> => {
  const response = await request.put<ISaveBillingSettings>(`${apiUrl}/billing`, formData)
  validateResponse(response.data, ['billing'])
  validateResponse(response.data.billing || {}, ['settings', 'payment', 'trial', 'paid_till'])
  validateResponse(response.data.billing?.payment || {}, ['stripe'])
  validateResponse(response.data.billing?.payment?.stripe || {}, ['customerId'])

  response.data.billing.paid_till = parseDate(response.data.billing.paid_till as string | null)
  return response
}

interface IGetHistory {
  items: THistoryItem[]
  total: number
}

/**
 */
export const getHistory = async (filter: IBillingHistoryFilter, page: number, limit: number, sortField = 'timestamp', sortDir: 'asc' | 'desc' = 'desc'): Promise<IResponse<IGetHistory>> => {
  const payload: TObject = {
    page,
    limit,
    order_by: sortField,
    order: sortDir,
  }
  if (filter.start) { payload.start = new Date(filter.start).toISOString() }
  if (filter.end) { payload.end = new Date(filter.end).toISOString() }
  const response = await request.get(`${apiUrl}/billing/history`, payload)

  validateResponse(response.data, ['transactions'])
  validateResponse(response.data.transactions || {}, ['data'])
  validateResponse(response.data.transactions?.pagination || {}, ['total'])

  const rawItems: TObject[] = response.data.transactions?.data || []
  const total = parseInt(response.data.transactions?.pagination?.total) || 0

  return {
    status: response.status,
    error: response.error,
    data: {
      total,
      items: rawItems.map(mapHistoryItem),
    },
  }
}

interface IGetStats {
  stats: TStats
}

/**
 */
export const getStats = async (): Promise<IResponse<IGetStats>> => {
  return await request.get<IGetStats>(`${apiUrl}/billing/stats`)
}

interface IGetCallLog {
  items: TCallLogItem[]
  stats: { duration_sum: number, duration_toll_free_sum: number }
  total: number
}

/**
 */
export const getCallLog = async (filter: ICallLogFilter, page: number, limit: number, sortField?: string, sortDir?: string): Promise<IResponse<IGetCallLog>> => {
  const payload: TObject = {
    page,
    limit,
    order_by: sortField,
    order: sortDir,
  }
  if (filter.start) { payload.start = getUTCStartOfDate(new Date(filter.start)).toISOString() }
  if (filter.end) { payload.end = getUTCEndOfDate(new Date(filter.end)).toISOString() }
  const response = await request.get(`${apiUrl}/billing/log`, payload)
  validateResponse(response.data, ['callog'])
  validateResponse(response.data.callog || {}, ['data'])
  validateResponse(response.data.callog || {}, ['stats'])
  validateResponse(response.data.callog?.pagination || {}, ['total'])

  const rawItems: TObject[] = response.data.callog?.data || []
  const total = parseInt(response.data.callog?.pagination?.total) || 0

  return {
    status: response.status,
    error: response.error,
    data: {
      total,
      items: rawItems.map(mapCallLogItem),
      stats: response.data.callog.stats,
    },
  }
}

import { request, IResponse } from '@/lib/Request'
import { config } from '@/config/config'
import { map as mapDid, TDid } from '@/models/Did'
import { validateResponse } from '@/lib/validate'

const { apiUrl } = config

/**
 */
export const remove = async (id: string): Promise<IResponse> => {
  return await request.delete(`${apiUrl}/did`, { did_id: id })
}

interface ISave {
  did: TDid | null
}

/**
 */
export const save = async (item: TDid, accountId?: string, fax?: boolean): Promise<IResponse<ISave>> => {
  const payload: TObject = {
    number: item.number,
    originator: item.originator,
    fax,
  }

  if (accountId) {
    payload.account_uid = accountId
  } else {
    payload.scenario_id = item.scenario_id
  }

  if (item.id) {
    const response = await request.put<ISave>(`${apiUrl}/did`, {
      did_id: item.id,
      scenario_id: item.scenario_id,
      account_uid: accountId,
      fax,
    })
    validateResponse(response.data, ['did'])
    response.data.did = response.data.did ? mapDid(response.data.did) : null
    return response as IResponse<ISave>

  } else {
    const response = await request.post<ISave>(`${apiUrl}/did`, payload)
    validateResponse(response.data, ['did'])
    response.data.did = response.data.did ? mapDid(response.data.did) : null
    return response as IResponse<ISave>
  }
}

interface IGetById {
  did: null | TDid
}

/**
 */
export const getById = async (id: string): Promise<IResponse<IGetById>> => {
  const response = await request.get<IGetById>(`${apiUrl}/did/${id}`)
  validateResponse(response.data, ['did'])
  response.data.did = response.data.did ? mapDid(response.data.did) : null
  return response
}

interface IGetItems {
  items: TDid[]
  total: number
}

/**
 */
export const getItems = async (page = 1, size = 20, sortField = 'created_at', sortDir: 'asc' | 'desc' = 'desc'): Promise<IResponse<IGetItems>> => {
  const payload: TObject = {
    page,
    limit: size,
    order_by: sortField,
    order: sortDir,
  }

  const response = await request.get(`${apiUrl}/did`, payload)
  validateResponse(response.data, ['dids'])
  validateResponse(response.data.dids || {}, ['data'])
  validateResponse(response.data.dids?.pagination || {}, ['total'])

  const rawItems: TObject[] = response.data.dids?.data || []
  const total = parseInt(response.data.dids?.pagination?.total) || 0

  return {
    status: response.status,
    error: response.error,
    data: {
      total,
      items: rawItems.map(mapDid),
    },
  }
}

interface ISearch {
  numbers: string[]
}

export const search = async (tollFree = false, areaCode?: string, contains?: string): Promise<IResponse<ISearch>> => {
  const payload: TObject = {}
  if (tollFree) { payload.toll_free = true }
  if (areaCode) { payload.npa = areaCode }
  if (contains) { payload.contains = contains }
  const response = await request.get(`${apiUrl}/did/search`, payload)
  response.data.numbers = response.data.dids?.data || []
  return response as IResponse<ISearch>
}

import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
    values: {},
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        // 2A7FFE
        // 5BD5EF
        blue: '#2A7FFE',
        primary: '#2A7FFE',
        secondary: '#5BD5EF',
        accent: '#ff5722',
        error: '#f44336',
        warning: '#ff9800',
        info: '#607d8b',
        success: '#4caf50',
      },
    },
  },
})

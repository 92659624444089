var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.state.faxes.list.items.length)?_c('div',{staticClass:"data-table"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"4","xs12":""}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSearch.apply(null, arguments)}}},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","label":_vm.$t('common.searchNumber'),"append-icon":_vm.icons.mdiMagnify},on:{"input":_vm.updateQuery,"click:append":_vm.onSearch},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)],1)],1)],1),_c('v-data-table',{staticClass:"root mt-2 row-hover",attrs:{"item-key":'id',"headers":_vm.headers,"items":_vm.state.faxes.list.items,"options":_vm.state.faxes.list.options,"server-items-length":_vm.state.faxes.list.total,"no-data-text":_vm.$t('common.empty'),"loading":_vm.state.faxes.list.isLoading,"page":_vm.state.faxes.list.options.page,"hide-default-footer":""},on:{"update:options":function($event){return _vm.$set(_vm.state.faxes.list, "options", $event)},"update:page":function($event){return _vm.$set(_vm.state.faxes.list.options, "page", $event)}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at, true))+" ")]}},{key:"item.from_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPhoneNumber(item.from_number))+" ")]}},{key:"item.to_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPhoneNumber(item.to_number))+" ")]}},{key:"item.direction",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":(item.direction === 'inbound') ? 'green' : 'orange',"text-color":"white","small":""}},[_vm._v(" "+_vm._s(item.direction)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"list-actions"},[_c('AppListingAction',{attrs:{"title":_vm.$t('faxes.download')},on:{"click":function($event){return _vm.open(item.location)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")]),_c('AppListingAction',{attrs:{"title":_vm.$t('faxes.delete')},on:{"click":function($event){return _vm.onDelete(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])],1)]}}],null,true)}),_c('v-pagination',{attrs:{"length":_vm.state.faxes.list.totalPages,"total-visible":7},on:{"input":_vm.loadItems},model:{value:(_vm.state.faxes.list.options.page),callback:function ($$v) {_vm.$set(_vm.state.faxes.list.options, "page", $$v)},expression:"state.faxes.list.options.page"}}),_c('AppDialog',{ref:"dialog"})],1):_c('div',{staticClass:"d-flex justify-center align-center "},[_c('img',{staticClass:"ma-2",attrs:{"src":_vm.icons.faxIcon}}),_c('h2',{staticClass:"ma-2 empty-message"},[_vm._v(_vm._s(_vm.$t('faxes.noFaxes')))])])])}
var staticRenderFns = []

export { render, staticRenderFns }
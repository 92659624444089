<template>
  <Filters
      :count="Object.values(filter).filter(i => i).length"
      @apply="apply"
      @reset="reset"
  >
    <FieldDate :max="form.end" v-model="form.start" :label="$t('common.dateFrom')" />
    <FieldDate :min="form.start" class="mt-4" v-model="form.end" :label="$t('common.dateTo')" />
  </Filters>
</template>

<script>

import Filters from '@/components/shared/Filters'
import FieldDate from '@/components/shared/FieldDate'
import cloneDeep from 'clone-deep'
export default {
  name: 'HistoryFilters',

  components: {
    FieldDate,
    Filters,
  },

  data() {
    return {
      filter: {},
      form: {
        start: '',
        end: '',
      },
    }
  },

  methods: {
    reset() {
      this.form.start = ''
      this.form.end = ''
      this.filter = cloneDeep(this.form)
      this.$emit('change', this.filter)
    },

    apply() {
      this.filter = cloneDeep(this.form)
      this.$emit('change', this.filter)
    },
  },
}
</script>

<style lang="sass" scoped>
</style>

<template>
  <LayoutMain class="root" :title="$t('scenarios.titleDetail')">
    <AppCard>
      <ViewScenariosDetailForm :id="$router.history.current.params.id" />
    </AppCard>
  </LayoutMain>
</template>

<script>
import LayoutMain from '@/components/shared/layout/Main.vue'
import ViewScenariosDetailForm from '@/components/views/viewScenariosDetail/Form'

export default {
  name: 'ViewScenariosDetail',

  components: {
    LayoutMain,
    ViewScenariosDetailForm,
  },

  data() {
    return {
    }
  },
}
</script>

<style lang="sass" scoped>
</style>

const initialData = {
  charge_accounts: 0,
  charge_dids: 0,
  charge_dids_toll_free: 0,
  charge_total: 0,
  count_accounts: 0,
  count_dids: 0,
  count_dids_toll_free: 0,
}

export type TInvoice = typeof initialData

export const create = (data?: Partial<TInvoice>): TInvoice => {
  return { ...initialData, ...data }
}

export const map = (data: TObject): TInvoice => {
  return {
    ...initialData,
    charge_accounts: data.charge?.accounts || initialData.charge_accounts,
    charge_dids: data.charge?.dids || initialData.charge_dids,
    charge_dids_toll_free: data.charge?.dids_toll_free || initialData.charge_dids_toll_free,
    charge_total: data.charge?.total || initialData.charge_total,
    count_accounts: data.count?.accounts || initialData.count_accounts,
    count_dids: data.count?.dids || initialData.count_dids,
    count_dids_toll_free: data.count?.dids_toll_free || initialData.count_dids_toll_free,
  }
}

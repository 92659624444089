<template>
  <input
      title=""
      :class="['root', error && 'error']"
      type="text"
      autocomplete="new"
      :autofocus="autofocus"
      :value="value"
      @input="$emit('input', $event)"
      @keyup="$emit('keyup', $event)"
      @keydown="$emit('keydown', $event)"
  >
</template>

<script>

export default {
  name: 'CodeVerifyInput',

  components: {
  },

  props: {
    autofocus: Boolean,
    value: { type: String, required: true },
    error: Boolean,
  },

  data() {
    return {
    }
  },
}
</script>

<style lang="sass" scoped>
.root
  flex: 0 0 auto
  padding: 8px 0
  width: 45px
  margin: 0 4px
  font-size: 1.4rem
  border: 1px solid #777
  border-radius: 4px
  text-align: center

  ::v-deep input
    text-align: center

  &.error
    border-color: var(--v-error-base)
    background: #fff !important
</style>

import { state } from '@/store/state'
import { remove, save, getById, getItems, search } from '@/api/DidApi'
import { appService } from '@/services/AppService'
import { create as createDid, TDid } from '@/models/Did'
import { normalizePhoneNumber } from '@/lib/utils'
import { billingService } from '@/services/BillingService'

class DidService {
  public state = state

  /**
   */
  public async init() {
    this.loadRefs().catch(window.logger.error)
  }

  /**
   */
  public remove = async (id: string): Promise<{ error: null | string }> => {
    const { error } = await remove(id)
    this.loadRefs().catch(window.logger.error)
    billingService.loadStats().catch(window.logger.error)
    return { error }
  }

  /**
   */
  public save = async (item: TDid, accountId?: string, fax?: boolean): Promise<{ error: null | string, did: null | TDid }> => {
    item.number = normalizePhoneNumber(item.number)
    const { error, data: { did } } = await save(item, accountId, fax)

    this.loadRefs().catch(window.logger.error)
    billingService.loadStats().catch(window.logger.error)
    return { error, did }
  }

  /**
   */
  public loadItem = async (id?: string) => {
    this.state.dids.detail.isLoading = true
    if (id) {
      const { data: { did } } = await getById(id)
      this.state.dids.detail.item = did || createDid({})
    } else {
      this.state.dids.detail.item = createDid({})
    }
    this.state.dids.detail.isLoading = false
  }

  /**
   */
  public async loadItems(page?: number, size?: number): Promise<void> {
    this.state.dids.list.isLoading = true
    if (page) { this.state.dids.list.options.page = page }
    if (size) { this.state.dids.list.options.itemsPerPage = size }
    try {
      const sortField = this.state.dids.list.options.sortBy[0]
      const sortDir = this.state.dids.list.options.sortDesc[0]
      const { error, data: { items, total } } = await getItems(
        this.state.dids.list.options.page,
        this.state.dids.list.options.itemsPerPage,
        sortField,
        sortDir ? 'desc' : 'asc',
      )
      if (error) {
        appService.showError(error)
      } else {
        this.state.dids.list.items = items
        this.state.dids.list.total = total
        this.state.dids.list.totalPages = Math.ceil(total / this.state.dids.list.options.itemsPerPage)
      }
    } catch (e) {
      window.logger.error(e)
      appService.showError()
    }
    this.state.dids.list.isLoading = false
  }

  /**
   */
  public async search(tollFree = false, areaCode?: string, contains?: string): Promise<string[]> {
    const response = await search(tollFree, areaCode, contains)
    return response.data.numbers || []
  }

  /**
   */
  private async loadRefs(): Promise<void> {
    const { data: { items } } = await getItems(1, 1000)
    if (items) {
      this.state.dids.refs = items.map(i => ({ value: i.id, label: i.number, scenario_id: i.scenario_id }))
    }
  }
}

export interface TDidOption extends TOption {
  scenario_id: string
}

const didService = new DidService()
export { didService, DidService }

import { request, IResponse } from '@/lib/Request'
import { config } from '@/config/config'
import { validateResponse } from '@/lib/validate'
import { TRecord } from '@/models/Record'
import axios from 'axios'

const { apiUrl } = config

interface IGetItems {
  items: TRecord[]
  total: number
}

export const getItems = async (
    page = 1,
    size = 20,
    sortField = 'created_at',
    sortDir: 'asc' | 'desc' = 'desc',
    search?: string,
  ): Promise<IResponse<IGetItems>> => {
  const payload: TObject = {
    page,
    limit: size,
    order_by: sortField,
    order: sortDir,
  }
  if (search) {
    payload.search = search
  }

  const response = await request.get(`${apiUrl}/record/list`, payload)
  validateResponse(response.data, ['records'])
  validateResponse(response.data.records || {}, ['data'])
  validateResponse(response.data.records?.pagination || {}, ['total'])

  const items: TRecord[] = response.data.records?.data || []
  const total = parseInt(response.data.records?.pagination?.total) || 0

  return {
    status: response.status,
    error: response.error,
    data: {
      total,
      items,
    },
  }
}

export const download = async (id: string): Promise<any> => {
  const options = {
    withCredentials: true,
    responseType: 'blob' as 'text',
  }
  const response = await axios.get(`${apiUrl}/record/${id}`, options)
  return {
    status: response.status,
    headers: response.headers,
    data: response.data,
  }
}

/**
 */
export const remove = async (id: string): Promise<IResponse> => {
  return await request.delete(`${apiUrl}/record/${id}`)
}

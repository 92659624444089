<template>
  <LayoutMain class="root" :title="$t('billing.callHistory')">
    <AppCard>
      <CallLog />
    </AppCard>
  </LayoutMain>
</template>

<script>
import LayoutMain from '@/components/shared/layout/Main.vue'
import CallLog from '@/components/shared/CallLog'

export default {
  name: 'ViewCallHistory',

  components: {
    LayoutMain,
    CallLog,
  },

  data() {
    return {
    }
  },
}
</script>

<style lang="sass" scoped>
</style>

<template>
  <div>
    <v-tooltip v-if="hint" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            :disabled="disabled"
            @click="$emit('click')"
        >
          <v-icon
            :small="small"
          >
            <slot />
          </v-icon>
        </v-btn>
      </template>
      <span>{{hint}}</span>
    </v-tooltip>
    <v-btn
        v-else
        icon
        :disabled="disabled"
        :title="title"
        @click="$emit('click')"
    >
      <v-icon
        :small="small"
      >
        <slot />
      </v-icon>
    </v-btn>

  </div>
</template>

<script>

export default {
  name: 'AppListingAction',

  components: {
  },

  props: {
    hint: String,
    title: String,
    disabled: Boolean,
    small: Boolean,
  },

  data() {
    return {
    }
  },
}
</script>

<style lang="sass" scoped>
</style>

import * as Sentry from '@sentry/vue'
import { config } from '@/config/config'

/**
 * Wrapper for console.log
 * Can be extended with special logging libs or services like Sentry
 * Prefer to use window.logger.error() instead of console.error()
 */
const logger = {
  debug: (...args: unknown[]): void => console.debug(new Date().toISOString(), ...args),
  info: (...args: unknown[]): void => console.log(new Date().toISOString(), ...args),
  warn: (...args: unknown[]): void => console.log(new Date().toISOString(), ...args),
  error: (...args: unknown[]): void => {
    if (config.env === 'production') {
      try {
        const e = args[0]
        if (e instanceof Error) {
          Sentry.captureException(args[0])
        } else {
          Sentry.captureMessage(JSON.stringify(args), Sentry.Severity.Error)
        }
      } catch (e) {
        // tslint:disable-next-line:no-console
        console.error(e)
      }
    }
    console.error(new Date().toISOString(), ...args)
  },
}

window.logger = logger
export { logger }

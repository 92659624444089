<template>
  <v-form class="root" @submit.prevent="onSubmit" novalidate="true">
    <v-text-field
        outlined
        hide-details
        autofocus
        type="text"
        :label="$t('common.email')"
        :disabled="!!userEmail"
        class="root__input"
        :error="$v.email.$anyError"
        v-model.trim="email"
        @change="setField('email', $event)"
    />

    <v-text-field
        outlined
        hide-details
        type="text"
        :label="$t('common.firstName')"
        class="root__input"
        :error="$v.firstName.$anyError"
        v-model.trim="firstName"
        @change="setField('firstName', $event)"
    />

    <v-text-field
        outlined
        hide-details
        type="text"
        :label="$t('common.lastName')"
        class="root__input"
        :error="$v.lastName.$anyError"
        v-model.trim="lastName"
        @change="setField('lastName', $event)"
    />

    <v-text-field
        outlined
        hide-details
        type="text"
        :label="$t('common.accountNumber')"
        class="root__input"
        :error="$v.number.$anyError || !number"
        v-model.trim="formattedNumber"
        @input="setPhoneNumber($event)"
    />

    <v-text-field
        outlined
        hide-details
        :label="$t('common.password')"
        type="password"
        class="root__input"
        autocomplete="new-password"
        :error="$v.password.$anyError"
        v-model.trim="password"
        @change="setField('password', $event)"
    />

    <div class="agree">
      <v-checkbox
          :error="$v.agree.$anyError"
          v-model.trim="agree"
          @change="setField('agree', $event)"
      />
      <div class="_text">
        I agree to <a class="link" href="/terms" target="_blank">Terms and Conditions</a> and
        <a class="link" href="/privacy" target="_blank">Privacy Policy</a>
      </div>
    </div>

    <AppError :error="authError" />

    <AppButton
        block
        submit
        type="primary"
        :dense="false"
        :isLoading="isLoading"
    >
      {{buttonLabel}}
    </AppButton>
    <AppButton
        v-if="cancelUrl"
        block
        :dense="false"
        class="root__cancel"
        type="gray"
        @click="$router.push(cancelUrl)"
    >
      {{$t('common.cancel')}}
    </AppButton>
  </v-form>
</template>

<script>

import { required, minLength, email } from 'vuelidate/lib/validators'
import { config } from '@/config/config'
import { phone, name, isTrue } from '@/lib/validate'
import { createPhoneNumber } from '@/lib/utils'

export default {
  name: 'FormSignUp',

  components: {
  },

  props: {
    isLoading: Boolean,
    authError: String,
    cancelUrl: String,
    userEmail: String,
    userNumber: String,
    buttonLabel: { type: String, required: true },
  },

  data() {
    return {
      email: this.userEmail,
      firstName: '',
      lastName: '',
      number: '',
      password: '',
      agree: false,
      formattedNumber: '',
    }
  },

  watch: {
    userEmail() { this.email = this.userEmail },
    userNumber() { this.number = this.userNumber },
    number: function(val) {
      this.formattedNumber = this.createPhoneNumber(val)?.formatted|| val
    },
  },

  validations: {
    email: {
      required,
      email,
    },
    firstName: {
      name,
    },
    lastName: {
      name,
    },
    number: {
      required,
      phone,
    },
    password: {
      required,
      minLength: minLength(config.minPasswordLength),
    },
    agree: {
      required,
      isTrue,
    },
  },

  methods: {
    createPhoneNumber,

    setPhoneNumber(value) {
      const phone = this.createPhoneNumber(value)
      this.setField('number', phone?.phone?.number || value)
      console.log(this.number)
    },

    setField(field, value) {
      this[field] = value
      this.$v[field].$touch()
      this.authError = null
    },

    async onSubmit() {
      this.$v.$touch()
      if (!this.$v.$anyError) {
        const formData = {
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          number: this.number,
          password: this.password,
        }
        this.$emit('submit', formData)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.root
  display: flex
  flex-direction: column
  gap: 10px

  .agree
    display: flex
    align-items: center

    ._text
      line-height: 1.4
</style>

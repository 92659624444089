var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"root"},[_c('v-container',[(!['xs', 'sm'].includes(_vm.$vuetify.breakpoint.name))?_c('v-row',[_c('v-col',[_c('DashboardStats')],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('Widget',{attrs:{"title":_vm.$t('navigation.users'),"flex":true}},[_c('template',{slot:"controls"},[_c('ActionButton',{on:{"click":function($event){return _vm.$router.push('/users')}}},[_vm._v(" "+_vm._s(_vm.$t('common.more'))+" ")])],1),_c('template',{slot:"content"},_vm._l((_vm.users),function(item){return _c('UserCard',{key:item.id,attrs:{"admin":item.admin,"firstName":item.profile.firstName,"lastName":item.profile.lastName,"avatar":item.profile.avatar,"email":item.email,"number":_vm.formatPhoneNumber(item.profile.number)}})}),1)],2)],1)],1),_c('v-row',[_c('v-col',[_c('Widget',{attrs:{"title":_vm.$t('navigation.numbers')}},[_c('template',{slot:"controls"},[_c('ActionButton',{on:{"click":function($event){return _vm.$router.push('/numbers')}}},[_vm._v(" "+_vm._s(_vm.$t('common.more'))+" ")])],1),_c('template',{slot:"content"},[_c('v-data-table',{staticClass:"row-hover header",attrs:{"item-key":'id',"headers":_vm.headersDIDs,"items":_vm.state.dids.list.items,"options":_vm.state.dids.list.options,"server-items-length":_vm.state.dids.list.total,"no-data-text":_vm.$t('common.empty'),"disable-sort":true,"loading":_vm.state.dids.list.isLoading,"loading-text":_vm.$t('common.loading'),"hide-default-footer":""},on:{"update:options":function($event){return _vm.$set(_vm.state.dids.list, "options", $event)}},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.transfer || item.fax) ? _vm.$t('dids.direct') : _vm.$t('dids.main'))+" ")]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPhoneNumber(item.number))+" ")]}},{key:"item.scenario_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getScenarioName(item))+" ")]}}],null,true)})],1)],2)],1),_c('v-col',[_c('Widget',{attrs:{"title":_vm.$t('navigation.scenarios')}},[_c('template',{slot:"controls"},[_c('ActionButton',{on:{"click":function($event){return _vm.$router.push('/scenarios')}}},[_vm._v(" "+_vm._s(_vm.$t('common.more'))+" ")])],1),_c('template',{slot:"content"},[_c('v-data-table',{staticClass:"row-hover header",attrs:{"item-key":'id',"headers":_vm.headersScenarios,"items":_vm.state.scenarios.list.items,"options":_vm.state.scenarios.list.options,"server-items-length":_vm.state.scenarios.list.total,"no-data-text":_vm.$t('common.empty'),"disable-sort":true,"loading":_vm.state.scenarios.list.isLoading,"loading-text":_vm.$t('common.loading'),"hide-default-footer":""},on:{"update:options":function($event){return _vm.$set(_vm.state.scenarios.list, "options", $event)}}})],1)],2)],1)],1),_c('v-row',[_c('v-col',[_c('Widget',{attrs:{"title":_vm.$t('navigation.callHistory')}},[_c('template',{slot:"controls"},[_c('ActionButton',{on:{"click":function($event){return _vm.$router.push('/callstats')}}},[_vm._v(" "+_vm._s(_vm.$t('common.more'))+" ")])],1),_c('template',{slot:"content"},[_c('v-data-table',{staticClass:"row-hover header",attrs:{"item-key":'id',"headers":_vm.headersCallLog,"items":_vm.logItems,"options":_vm.state.billing.callLog.options,"server-items-length":_vm.state.billing.callLog.total,"no-data-text":_vm.$t('common.empty'),"disable-sort":true,"loading":_vm.state.billing.callLog.isLoading,"loading-text":_vm.$t('common.loading'),"hide-default-footer":""},on:{"update:options":function($event){return _vm.$set(_vm.state.billing.callLog, "options", $event)}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at, true))+" ")]}},{key:"item.begin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.begin, true))+" ")]}},{key:"item.record_id",fn:function(ref){
var item = ref.item;
return [(item.record_id)?[_c('img',{staticClass:"record-play-icon",attrs:{"src":_vm.icons.playIcon},on:{"click":function($event){return _vm.downloadRecordItem(item.record_id)}}})]:_vm._e()]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.duration)+" ")]}},{key:"item.direction",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":(item.direction === 'inbound') ? 'green' : 'orange',"text-color":"white","small":""}},[_vm._v(" "+_vm._s(item.direction)+" ")])]}},{key:"item.scenario_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.scenarioRefs.find(function (i) { return i.value === ("" + (item.scenario_id)); }) || {}).label || item.scenario_id)+" ")]}},{key:"item.fromNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.createPhoneNumber(item.fromNumber).formatted || item.fromNumber)+" ")]}},{key:"item.toNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.createPhoneNumber(item.toNumber).formatted || item.toNumber)+" ")]}},{key:"item.transfer_account_uid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTransferAccountName(item.transfer_account_uid))+" ")]}}],null,true)})],1)],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { request } from '@/lib/Request'
import { state } from '@/store/state'
import { authService } from '@/services/AuthService'
import { billingService } from '@/services/BillingService'
import { appService } from '@/services/AppService'
import { scenarioService } from '@/services/ScenarioService'
import { didService } from '@/services/DidService'
import { userService } from '@/services/UserService'

export const Bootstrap = {
  /**
   * @public
   * @return {Promise<void>}
   */
  async init(): Promise<void> {
    appService.init().catch(window.logger.error)
    request.setHeaders({ 'Accept-Language': 'en' })

    authService.onAuth(async () => {
      state.isInitializing = true
      if (await authService.loadCompany()) {
        await this.initAppAfterAuth()
      }
      state.isInitializing = false
    })

    await userService.loadTimezones()
    await userService.loadLanguages()
    await authService.loadAccount()
  },

  /**
   */
  async initAppAfterAuth(): Promise<void | void[]> {
    return Promise.all([
      userService.init().catch(window.logger.error),
      billingService.init().catch(window.logger.error),
      scenarioService.init().catch(window.logger.error),
      didService.init().catch(window.logger.error),
    ]).catch(window.logger.error)
  },
}

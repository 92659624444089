<template>
  <div class="root pa-5 pb-0">
    <BillingCard :title="$t('billing.titleHistory')">
      <template v-slot:content>
        <v-data-table
            class="row-hover"
            :headers="headers"
            :items="state.billing.history.items"
            :options.sync="state.billing.history.options"
            :server-items-length="state.billing.history.total"
            :loading="state.billing.history.isLoading"
            :no-data-text="$t('common.empty')"
            :page.sync="state.billing.history.options.page"
            @update:options="loadItems()"
            hide-default-footer
        >
          <template v-slot:[`item.date`]="{ item }">
            {{ formatDate(item.date, true) }}
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <div class="amount">
              <v-chip :class="item.amount >= 0 ? 'plus' : 'minus'">
                {{ formatMoneyUsd(item.amount) }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.type`]="{ item }">
              {{item.type}}
          </template>
        </v-data-table>
        <v-pagination
          v-model="state.billing.history.options.page"
          :length="state.billing.history.totalPages"
          :total-visible="7"
        />
      </template>
      <template v-slot:actions>
        <!-- <HistoryFilters @change="loadItems" /> -->
      </template>
    </BillingCard>
  </div>
</template>

<script>

import { billingService } from '@/services/BillingService'
import { formatDate, formatMoneyUsd } from '@/lib/utils'
import { state } from '@/store/state'
import { i18n } from '@/config/vue'
import HistoryFilters from './history/Filters'
import BillingCard from '@/components/shared/BillingCard'

export default {
  name: 'ViewBillingHistory',

  components: {
    HistoryFilters,
    BillingCard,
  },

  data() {
    return {
      state,
      headers: [
        {
          text: i18n.t('common.date'),
          value: 'date',
          sortable: false,
        },
        {
          text: i18n.t('billing.fieldAmount'),
          value: 'amount',
          sortable: false,
        },
        {
          text: i18n.t('billing.fieldType'),
          value: 'type',
          sortable: false,
        },
      ],
    }
  },

  mounted() {
    this.loadItems().catch(window.logger.error)
  },

  methods: {
    formatMoneyUsd,
    formatDate,

    async loadItems(filter) {
      await billingService.loadHistory(filter)
    },
  },
}
</script>

<style lang="sass" scoped>
.root
  .amount
    font-weight: 600
    .plus,
    .minus
      padding: 0px 10px
      color: white
      font-size: 12px
      background: var(--v-primary-base)
    .minus
      background: red
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"root"},[(_vm.items.length)?_c('div',{attrs:{"lass":"log"}},[_c('CallLogFilters',{on:{"change":_vm.loadItems}}),_c('v-data-table',{staticClass:"mt-5",attrs:{"item-key":'id',"headers":_vm.headers,"items":_vm.items,"options":_vm.state.billing.callLog.options,"server-items-length":_vm.state.billing.callLog.total,"loading":_vm.state.billing.callLog.isLoading,"no-data-text":_vm.$t('common.empty'),"page":_vm.state.billing.callLog.options.page,"hide-default-footer":""},on:{"update:options":[function($event){return _vm.$set(_vm.state.billing.callLog, "options", $event)},function($event){return _vm.loadItems()}],"update:page":function($event){return _vm.$set(_vm.state.billing.callLog.options, "page", $event)}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at, true))+" ")]}},{key:"item.begin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.begin, true))+" ")]}},{key:"item.end",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.end))+" ")]}},{key:"item.direction",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":(item.direction === 'inbound') ? 'green' : 'orange',"text-color":"white","small":""}},[_vm._v(" "+_vm._s(item.direction)+" ")])]}},{key:"item.scenario_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.scenarioRefs.find(function (i) { return i.value === ("" + (item.scenario_id)); }) || {}).label || item.scenario_id)+" ")]}},{key:"item.fromNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.createPhoneNumber(item.fromNumber).formatted || item.fromNumber)+" ")]}},{key:"item.toNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.createPhoneNumber(item.toNumber).formatted || item.toNumber)+" ")]}},{key:"item.transfer_account_uid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTransferAccountName(item.transfer_account_uid))+" ")]}}],null,true)}),_c('v-pagination',{attrs:{"length":_vm.state.billing.callLog.totalPages,"total-visible":7},on:{"input":_vm.loadPage},model:{value:(_vm.state.billing.callLog.options.page),callback:function ($$v) {_vm.$set(_vm.state.billing.callLog.options, "page", $$v)},expression:"state.billing.callLog.options.page"}})],1):_c('div',{staticClass:"d-flex justify-center align-center "},[_c('img',{staticClass:"ma-2",attrs:{"src":_vm.icons.logIcon}}),_c('h2',{staticClass:"ma-2 empty-message"},[_vm._v(_vm._s(_vm.$t('callog.noLogs')))])])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template class="root">
  <a href="https://www.stripe.com" target="_blank">
    <div class="logos">
      <VisaLogo />
      <MastercardLogo />
      <DiscoverLogo />
      <AmExLogo />
    </div>
  </a>
</template>

<script>

import VisaLogo from '@/assets/visa.svg'
import MastercardLogo from '@/assets/mastercard.svg'
import DiscoverLogo from '@/assets/discover_financial_services.svg'
import AmExLogo from '@/assets/american_express.svg'

export default {
  name: 'PaymentProviders',

  props: {
  },

  components: {
    VisaLogo,
    MastercardLogo,
    DiscoverLogo,
    AmExLogo,
  },

  data() {
    return {
    }
  },
}
</script>

<style lang="sass">
.root
  .logos
    display: flex
    justify-content: center
    align-items: center
    gap: 20px
    padding: 20px
    height: 60px
</style>

<template>
  <div class="root">
    <div class="root__icon">
      <IconVisa v-if="item.brand === 'visa'" />
      <IconMC v-if="item.brand === 'mastercard'" />
      <IconAmex v-if="item.brand === 'amex'" />
      <IconDiners v-if="item.brand === 'diners'" />
      <IconDiscover v-if="item.brand === 'discover'" />
      <IconUnionPay v-if="item.brand === 'unionpay'" />
      <IconJCB v-if="item.brand === 'jcb'" />
    </div>
    <div class="root__info">
      <div>
        <span v-if="item.brand === 'visa'">Visa</span>
        <span v-if="item.brand === 'mastercard'">Mastercard</span>
        <span v-if="item.brand === 'amex'">American Express</span>
        <span v-if="item.brand === 'diners'">Diners Club</span>
        <span v-if="item.brand === 'discover'">Discover</span>
        <span v-if="item.brand === 'unionpay'">Union Pay</span>
        <span v-if="item.brand === 'jcb'">JCB</span>
        xxxx-{{item.last4}}
      </div>
      <div>
        {{$t('billing.expires')}}
        <span>{{expires}}</span>
      </div>
    </div>
    <div class="root__spacer" />

    <v-chip
        v-if="item.isDefault"
        class="root__label"
        color="primary"
    >
      {{$t('billing.default')}}
    </v-chip>

    <div v-if="showDefault || showDelete" class="root__menu">
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>{{icons.mdiDotsHorizontal}}</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-if="showDefault" @click="onDefault">
            <v-list-item-title>{{$t('billing.setDefault')}}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="showDelete" @click="onDelete">
            <v-list-item-title>{{$t('billing.delete')}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <AppDialog ref="dialog" :isLoading="isLoading">
      Are you sure you want to delete card:<br/>
      <b>{{this.item.brand}} xxxx-{{this.item.last4}}</b> ?
    </AppDialog>
  </div>
</template>

<script>

import { format } from 'date-fns'
import { mdiDotsHorizontal } from '@mdi/js'
import IconVisa from '../../../../assets/icon_visa.svg'
import IconMC from '../../../../assets/icon_mc.svg'
import IconAmex from '../../../../assets/icon_amex.svg'
import IconDiners from '../../../../assets/icon_diners.svg'
import IconDiscover from '../../../../assets/icon_discover.svg'
import IconUnionPay from '../../../../assets/icon_unionpay.svg'
import IconJCB from '../../../../assets/icon_jcb.svg'
import { billingService } from '@/services/BillingService'
import { appService } from '@/services/AppService'
import { state } from '@/store/state'

export default {
  name: 'CardsItem',

  components: {
    IconVisa,
    IconMC,
    IconAmex,
    IconDiners,
    IconDiscover,
    IconUnionPay,
    IconJCB,
  },

  computed: {
    expires() { return format(new Date(this.item.expireYear, this.item.expireMonth - 1, 1), 'MMM yyyy') },
    showDelete() { return this.state.billing.paymentMethods.length > 1 },
    showDefault() { return !this.item.isDefault },
  },

  props: {
    item: { type: Object, required: true },
  },

  data() {
    return {
      state,
      icons: { mdiDotsHorizontal },
      isLoading: false,
    }
  },

  methods: {
    async onDefault() {
      const { error } = await billingService.defaultPaymentMethod(this.item.id)
      error
          ? appService.showError(error)
          : appService.showSuccess()
      if (!error) {
        await billingService.loadPaymentMethods()
      }
    },

    async onDelete() {
      const dialogSettings = {
        title: 'Delete Payment Method',
        actionText: 'Delete',
        onAction: async () => {
          this.isLoading = true
          try {
            const { error } = await billingService.deletePaymentMethod(this.item.id)
            error
                ? appService.showError(error)
                : appService.showSuccess()
            if (!error) {
              await billingService.loadPaymentMethods()
            }
          } catch (e) {
            window.logger.error(e)
          }
          this.isLoading = false
        },
      }

      this.$refs.dialog.show(dialogSettings)
    },
  },
}
</script>

<style lang="sass" scoped>
.root
  display: flex
  flex-direction: row !important
  align-items: center
  padding: 0px
  border: none
  width: 380px

  &__icon
    flex: 0 0 auto
    width: 35px
    height: 35px
    overflow: hidden
    margin-right: 16px
    background: #eee
    border-radius: 2px

  &__info
    flex: 0 0 auto
    font-size: .8rem
    white-space: nowrap
    span
      font-weight: 600

  &__spacer
    width: 100%

  &__label
    flex: 0 0 auto
    align-self: flex-start
    height: 22px
    margin: 0px 16px 0 10px
    text-transform: uppercase
    font-size: .6rem
    font-weight: 600
    border-radius: 12px
    padding: 10px

.root:nth-child(odd)
  padding-bottom: 8px

.root:nth-child(even)
  margin-top: 5px
  padding-top: 18px
  border-top: 1px solid rgba(0, 0, 0, 0.12)
</style>

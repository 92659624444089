<template>
  <v-btn
      :small="dense"
      elevation="0"
      :block="block"
      :type="submit ? 'submit' : 'button'"
      :disabled="disabled || isLoading"
      :class="['root', type, block && 'block', dense && 'dense']"
      :outlined="type === 'outlined'"
      @click="$emit('click', $event)"
  >
    <div v-if="showIsLoading" class="lds-ellipsis">
      <div /><div /><div /><div />
    </div>
    <slot v-else></slot>
  </v-btn>
</template>

<script>

export default {
  name: 'AppButton',

  components: {
  },

  props: {
    submit: Boolean,
    block: Boolean,
    disabled: Boolean,
    isLoading: Boolean,
    dense: { type: Boolean, default: true },
    type: {
      validator: v => ['primary', 'warning', 'gray', 'outlined'].includes(v),
      default: 'primary',
    },
  },

  watch: {
    isLoading() {
      clearTimeout(this.timeout)
      if (this.isLoading) {
        this.timeout = setTimeout(() => this.showIsLoading = true, 500)
      } else {
        this.showIsLoading = false
      }
    },
  },

  data() {
    return {
      timeout: 0,
      showIsLoading: false,
    }
  },
}
</script>

<style lang="sass" scoped>
.root
  height: 40px !important
  font-size: 1rem
  min-width: 100px !important
  padding-left: 24px
  padding-right: 24px
  border-radius: 3px
  &.block
    width: 100%
  &.dense
    height: 40px !important
  &.primary
    background-color: var(--v-primary-base) !important
    transition: all 0.2s
    color: #fff
    &:hover
      background-color: var(--v-primary-darken2) !important
  &.warning
    background-color: #e61610 !important
    transition: all 0.2s
    color: #fff
    &:hover
      background-color: #b70f0a !important


.lds-ellipsis
  display: inline-block
  position: relative
  width: 100%
  height: 20px

.lds-ellipsis div
  position: absolute
  top: calc(50% - 5px)
  width: 10px
  height: 10px
  border-radius: 50%
  background: #fff
  animation-timing-function: cubic-bezier(0, 1, 1, 0)

.lds-ellipsis div:nth-child(1)
  left: 8px
  animation: lds-ellipsis1 0.6s infinite

.lds-ellipsis div:nth-child(2)
  left: 8px
  animation: lds-ellipsis2 0.6s infinite

.lds-ellipsis div:nth-child(3)
  left: 32px
  animation: lds-ellipsis2 0.6s infinite

.lds-ellipsis div:nth-child(4)
  left: 56px
  animation: lds-ellipsis3 0.6s infinite

@keyframes lds-ellipsis1
  0%
    transform: scale(0)
  100%
    transform: scale(1)

@keyframes lds-ellipsis3
  0%
    transform: scale(1)
  100%
    transform: scale(0)

@keyframes lds-ellipsis2
  0%
    transform: translate(0, 0)
  100%
    transform: translate(24px, 0)
</style>

<template>
  <div class="root pa-5 pb-0">
    <BillingCard :title="$t('billing.plan')">
      <template v-slot:content :title="$t('billing.titleSettings')">
        <AppError class="mt-5 mb-0" :error="state.billing.serverError" />
          <v-form class="ml-2" @submit.prevent="onSubmit" novalidate="true">
            <div class="root__row">
              <v-switch
                  inset
                  hide-details
                  class="ma-0"
                  color="blue"
                  :label="$t('billing.enableSubsText')"
                  :disabled="devAccount"
                  :false-value="true"
                  :true-value="false"
                  v-model="form.cancel_at_paid_till"
                  @change="setField('cancel_at_paid_till', $event)"
              />
            </div>
            <div class="root__row">
              <v-switch
                  inset
                  hide-details
                  class="ma-0 mt-5"
                  :label="$t('billing.enableText')"
                  :disabled="devAccount"
                  v-model="form.auto_recharge"
                  @change="setField('auto_recharge', $event)"
              />
            </div>
            <div v-if="form.auto_recharge">
              <div class="root__row ml-14">
                {{$t('billing.triggerText')}}
                <div class="root__input">
                  <v-text-field
                      hide-details
                      type="number"
                      prefix="$"
                      v-model.trim="form.trigger_amount"
                      :error="$v.form.trigger_amount.$invalid"
                      @input="setField('trigger_amount', $event)"
                  />
                </div>
              </div>
              <div class="root__row mb-2 ml-14">
                {{$t('billing.topupText')}}
                <div class="root__input">
                  <v-text-field
                      hide-details
                      type="number"
                      prefix="$"
                      v-model.trim="form.topup_amount"
                      :error="$v.form.topup_amount.$invalid"
                      @input="setField('topup_amount', $event)"
                  />
                </div>
                <span v-if="form.topup_amount < MIN_AMOUNT">min: ${{MIN_AMOUNT}}</span>
              </div>
              <div class="root__row mb-4">
                <v-switch
                    inset
                    hide-details
                    class="ma-0"
                    :label="$t('billing.notifyText')"
                    :input-value="form.notify === 'email'"
                    @change="setField('notify', form.notify === 'email' ? '' : 'email')"
                />
              </div>
            </div>
          </v-form>
      </template>
      <template v-slot:actions>
        <ActionButton
            type="primary"
            :disabled="isLoading || !$v.$anyDirty || $v.$invalid || !isFormChanged"
            @click="onSubmit()"
        >
          {{$t('common.save')}}
        </ActionButton>
      </template>
    </BillingCard>
    <AppDialog ref="dialog" />
  </div>
</template>

<script>

import { state } from '@/store/state'
import { config } from '@/config/config'
import { minValue, maxValue, required } from 'vuelidate/lib/validators'
import { billingService } from '@/services/BillingService'
import { appService } from '@/services/AppService'
import { formatDate } from '@/lib/utils'
import BillingCard from '@/components/shared/BillingCard'

const MIN_AMOUNT = config.minBalanceAmount

export default {
  name: 'ViewBillingSettings',

  components: {
    BillingCard,
  },

  data() {
    return {
      state,
      MIN_AMOUNT,
      isLoading: false,
      form: { ...state.billing.settings },
      clearForm: { ...state.billing.settings },
      notify: false,
    }
  },

  computed: {
    isFormChanged() { return JSON.stringify(this.form) !== JSON.stringify(this.clearForm) },
    devAccount() { return this.state.billing.dev },
  },

  validations() {
    return this.form.auto_recharge
        ? {
          form: {
            cancel_at_paid_till: {},
            auto_recharge: {},
            trigger_amount: {
              required,
              minValue: minValue(0),
              maxValue: maxValue(10000),
            },
            topup_amount: {
              required,
              minValue: minValue(MIN_AMOUNT),
              maxValue: maxValue(10000),
            },
            notify: {},
          },
        }
        : {
          form: {
            cancel_at_paid_till: {},
            auto_recharge: {},
          },
        }
  },

  methods: {
    setField(field, value) {
      if (field === 'cancel_at_paid_till' && value === true && this.state.billing.paid_till.getTime() > Date.now() && !this.clearForm.cancel_at_paid_till) {
        this.cancelAtPaidTill()
        return
      }
      this.form[field] = value
      this.$v.form[field].$touch()
      state.billing.serverError = null
    },

    async cancelAtPaidTill() {
      const confirmDialogSettings = {
        title: this.$t('billing.cancelSubscription'),
        html: this.$t('billing.confirmCancel', { date: formatDate(this.state.billing.paid_till) }),
        actionText: this.$t('common.confirm'),
      }

      this.$nextTick(() => { this.form.cancel_at_paid_till = false })

      if (await this.$refs.dialog.show(confirmDialogSettings)) {
        this.form.cancel_at_paid_till = true
        this.$v.form.cancel_at_paid_till.$touch()
        state.billing.serverError = null
      }
    },

    async onSubmit() {
      this.$v.$touch()
      if (!this.$v.$anyError) {
        this.isLoading = true
        const formData = {
          cancel_at_paid_till: this.form.cancel_at_paid_till,
          auto_recharge: this.form.auto_recharge,
          trigger_amount: parseFloat(this.form.trigger_amount) || 0,
          topup_amount: parseFloat(this.form.topup_amount) || 0,
          notify: `${this.form.notify || ''}`,
        }
        const { error } = await billingService.saveSettings(formData)
        if (!error) {
          appService.showSuccess()
          this.form = { ...state.billing.settings }
          this.clearForm = { ...state.billing.settings }
          this.$v.$reset()
        } else {
          appService.showError(error)
        }
        this.isLoading = false
      }
    },
  },
}

</script>

<style lang="sass" scoped>
  .root
    &__row
      display: flex
      align-items: center

    &__input
      width: 80px
      margin: 0 8px

      .v-input
        display: flex
        align-items: center
        margin-top: 5px
        padding: 5px 0 0 5px
        height: 40px !important

    &__select
      width: 250px
      margin: 0 8px
</style>

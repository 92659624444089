<template>
    <div class="root">
      <div class="item" @click="$emit('selectItem', `${id}.${ext}`)">
        <mp3Icon class="file-icon" v-if="ext === 'mp3'" />
        <wavIcon class="file-icon" v-else-if="ext === 'wav'" />
        <div class="item-data noselect">
          <span class="item-name">{{name}}</span>
          <span class="item-meta">{{created_at}} [{{prettyBytes(size)}}]</span>
        </div>
      </div>
      <v-img
        class="delete-icon"
        :src="icons.deleteIcon"
        max-width="24px"
        @click="$emit('deleteItem', id)"
      />
    </div>
</template>

<script>
import prettyBytes from 'pretty-bytes'
import deleteIcon from '@/assets/delete.png'
import mp3Icon from '@/assets/mp3.svg'
import wavIcon from '@/assets/wav.svg'

export default {
  name: 'FlowSpeakFile',
  components: {
    mp3Icon,
    wavIcon,
  },
  props: {
    type: {
      type: String,
      default: 'common',
    },
    name: {
      type: String,
      default: '[empty]',
    },
    ext: {
      type: String,
    },
    size: {
      type: Number,
    },
    created_at: {
      type: String,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        deleteIcon,
      },
    }
  },
  methods: {
    prettyBytes,
  },
}
</script>

<style lang="sass" scoped>
.root
  display: flex
  align-items: center
  gap: 10px

  .item
    cursor: pointer
    padding: 10px
    color: #DEA922
    width: 342px
    height: 58px
    background: #FCF3D8
    border-radius: 5px
    display: flex
    gap: 10px
    transition: all 0.2s

    &:hover
      box-shadow: #FFF 0px 2px 5px -1px, #FEFEFE 0px 1px 3px -1px

    &-data
      display: flex
      flex-direction: column

    &-meta
      color: #E2D0A7
      font-size: 0.9em
      font-weight: 500

  .file-icon
    width: 38px

  .delete-icon
    cursor: pointer
</style>
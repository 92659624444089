<template>
  <v-form class="root" @submit.prevent="onVerify" novalidate="true">
    <p class="text-center">
      {{$t('common.verifyText')}}
    </p>

    <div ref="inputs" class="root__inputs">
      <CodeVerifyInput id="v0" :error="error || verifyError" :value="values[0]" @input="onChange(0, $event)" @keydown="onKey(0, $event)" />
      <CodeVerifyInput id="v1" :error="error || verifyError" :value="values[1]" @input="onChange(1, $event)" @keydown="onKey(1, $event)" />
      <CodeVerifyInput id="v2" :error="error || verifyError" :value="values[2]" @input="onChange(2, $event)" @keydown="onKey(2, $event)" />
      <CodeVerifyInput id="v3" :error="error || verifyError" :value="values[3]" @input="onChange(3, $event)" @keydown="onKey(3, $event)" />
      <CodeVerifyInput id="v4" :error="error || verifyError" :value="values[4]" @input="onChange(4, $event)" @keydown="onKey(4, $event)" />
      <CodeVerifyInput id="v5" :error="error || verifyError" :value="values[5]" @input="onChange(5, $event)" @keydown="onKey(5, $event)" />
    </div>

    <AppButton
        v-if="count < 3"
        block
        submit
        type="primary"
        :disabled="code.length !== 6 || error"
        :isLoading="isLoading"
    >
      {{buttonLabel}}
    </AppButton>
    <v-alert
        v-else
        border="left"
        colored-border
        type="error"
        elevation="2"
    >
      {{$t('common.verifyLimit')}}
    </v-alert>

    <AppButton
        block
        class="root__cancel"
        type="gray"
        @click="$emit('cancel')"
    >
      {{$t('common.cancel')}}
    </AppButton>
  </v-form>
</template>

<script>
import { setCursorPosition } from '@/lib/utils'
import CodeVerifyInput from './codeVerify/Input.vue'

export default {
  name: 'CodeVerify',

  components: {
    CodeVerifyInput,
  },

  props: {
    isLoading: Boolean,
    verifyError: Boolean,
    buttonLabel: { type: String, required: true },
  },

  data() {
    return {
      values: ['', '', '', '', '', ''],
      count: 0,
      error: false,
      code: '',
    }
  },

  mounted() {
    const inputs = this.$refs['inputs']
    const input = inputs && inputs.querySelector('input')
    input && input.focus()
  },

  methods: {
    onChange(pos, e) {
      const { value } = e.target
      const values = value.replace(/[^0-9]+/g, '').split('').slice(0, 6)
      e.target.value = values[0] || ''
      this.error = false

      if (pos === 0 && values.length === 6) {
        this.values = values
        const el = document.querySelector('#v5')
        el && el.focus()
        setCursorPosition(el, 0)
        this.code = this.values.join('')
        return
      }
      this.$set(this.values, pos, values[0] || '')

      if (value[0] && pos < 5) {
        const el = document.querySelector(`#v${pos + 1}`)
        if (el) {
          el.focus()
          setCursorPosition(el, 0)
        }
      }

      this.code = this.values.join('')
    },

    onKey(pos, e) {
      let nextPos = pos
      let onStart = true
      if (e.key === 'ArrowLeft' && pos > 0) {
        nextPos = pos - 1
      }
      if (e.key === 'ArrowRight' && pos < 5) {
        nextPos = pos + 1
      }
      if (e.key === 'Backspace') {
        e.preventDefault()
        e.stopPropagation()
        if (e.target.value) {
          this.values[pos] = ''
          e.target.value = ''
        } else if (pos > 0) {
          nextPos = pos - 1
          onStart = false
        }
      }

      if (nextPos !== pos) {
        e.preventDefault()
        e.stopPropagation()
        const el = document.querySelector(`#v${nextPos}`)
        if (el) {
          el.focus()
          onStart && setCursorPosition(el, 0)
        }
      }

      this.code = this.values.join('')
    },

    onVerify() {
      this.count += 1
      this.$emit('verify', this.code)
    },
  },
}
</script>

<style lang="sass" scoped>
.root
  &__inputs
    display: flex
    justify-content: center
    margin-bottom: 24px

  &__input
    flex: 0 0 auto
    padding: 0
    width: 45px
    margin: 0 4px
    font-size: 1.2rem
    ::v-deep input
      text-align: center

  &__cancel
    margin-top: 8px
</style>

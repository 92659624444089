import { render, staticRenderFns } from "./Row.vue?vue&type=template&id=f62a28b4&scoped=true&"
import script from "./Row.vue?vue&type=script&lang=js&"
export * from "./Row.vue?vue&type=script&lang=js&"
import style0 from "./Row.vue?vue&type=style&index=0&id=f62a28b4&lang=sass&scoped=true&"
import style1 from "./Row.vue?vue&type=style&index=1&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f62a28b4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VChip,VIcon})

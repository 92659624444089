import { request, IResponse } from '@/lib/Request'
import { config } from '@/config/config'
import { map as mapScenario, TScenario } from '@/models/Scenario'
import { validateResponse } from '@/lib/validate'
import { map as mapTemplate, TScenarioTemplate } from '@/models/ScenarioTemplate'

const { apiUrl } = config

interface IGetBlocks {
  blocks: {
    data: TObject[]
  }
}

/**
 */
export const getBlocks = async (): Promise<IResponse<IGetBlocks>> => {
  const response = await request.get<IGetBlocks>(`${apiUrl}/scenario/blocks`)
  validateResponse(response.data, ['blocks'])
  validateResponse(response.data.blocks, ['data'])
  return response
}

/**
 */
export const remove = async (id: string): Promise<IResponse> => {
  return await request.delete(`${apiUrl}/scenario`, { scenario_id: id })
}

interface ISave {
  scenario: TScenario
}

/**
 */
export const save = async (item: TScenario): Promise<IResponse<ISave>> => {
  const payload: TObject = {
    name: item.name,
    description: item.description,
    blockly_scheme: item.scheme,
    meta: item.meta,
  }

  if (item.id) {
    const response = await request.put<ISave>(`${apiUrl}/scenario`, { scenario_id: item.id, ...payload })
    validateResponse(response.data, ['scenario'])
    if (response.data.scenario) {
      response.data.scenario = mapScenario(response.data.scenario)
    }
    return response as IResponse<ISave>

  } else {
    const response = await request.post<ISave>(`${apiUrl}/scenario`, payload)
    validateResponse(response.data, ['scenario'])
    if (response.data.scenario) {
      response.data.scenario = mapScenario(response.data.scenario)
    }
    return response as IResponse<ISave>
  }
}

interface IGetById {
  scenario: null | TScenario
}

/**
 */
export const getById = async (id: string): Promise<IResponse<IGetById>> => {
  const response = await request.get<IGetById>(`${apiUrl}/scenario/${id}`)
  validateResponse(response.data, ['scenario'])
  response.data.scenario = response.data.scenario ? mapScenario(response.data.scenario) : null
  return response
}

interface IGetItems {
  items: TScenario[]
  total: number
}

/**
 */
export const getItems = async (page = 1, size = 20, sortField = 'created_at', sortDir: 'asc' | 'desc' = 'desc'): Promise<IResponse<IGetItems>> => {
  const payload: TObject = {
    page,
    limit: size,
    order_by: sortField,
    order: sortDir,
  }

  const response = await request.get(`${apiUrl}/scenario`, payload)
  validateResponse(response.data, ['scenarios'])
  validateResponse(response.data.scenarios || {}, ['data'])
  validateResponse(response.data.scenarios?.pagination || {}, ['total'])

  const rawItems: TObject[] = response.data.scenarios?.data || []
  const total = parseInt(response.data.scenarios?.pagination?.total) || 0

  return {
    status: response.status,
    error: response.error,
    data: {
      total,
      items: rawItems.map(mapScenario),
    },
  }
}

interface IGetTemplates {
  templates: TScenarioTemplate[]
}

/**
 */
export const getTemplates = async (): Promise<IResponse<IGetTemplates>> => {
  const response = await request.get<IGetTemplates>(`${apiUrl}/scenario/templates`)
  validateResponse(response.data, ['templates'])
  response.data.templates = (response.data.templates || []).map(mapTemplate)
  return response
}

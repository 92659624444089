<template>
  <div>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="value"
        offset-y
        min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            outlined
            hide-details
            dense
            :label="label"
            readonly
            v-model="date"
            v-bind="attrs"
            v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
          v-model="date"
          :min="min"
          :max="max"
          no-title
          scrollable
          @change="apply"
      >
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>

export default {
  name: 'FieldDate',

  components: {
  },

  props: {
    label: { type: String, required: true },
    value: { type: String, required: true },
    min: String,
    max: String,
  },

  watch: {
    value() { this.date = this.value },
  },

  data() {
    return {
      date: this.value,
      menu: false,
    }
  },

  methods: {
    cancel() {
      this.menu = false
      this.date = this.value
    },

    apply() {
      this.$refs.menu.save(this.date)
      this.$emit('input', this.date)
    },
  },
}
</script>

<style lang="sass" scoped>
</style>

<template>
  <div>
    <p class="welcome-text">{{$t('onboarding.textWelcome')}}</p>

    <div id="card-number-element" class="cardInput"></div>
    <div id="card-expiry-element" class="cardInput"></div>
    <div id="card-cvc-element" class="cardInput"></div>

    <AppError class="mt-5 mb-0" :error="serverError" />

    <AppButton
          block
          class="mt-2"
          type="primary"
          :disabled="!cardComplete || isLoading"
          :isLoading="isLoading"
          @click="onSubmit()"
      >
        {{$t('onboarding.addCard')}}
    </AppButton>

  </div>

</template>

<script>

import { billingService } from '@/services/BillingService'
import { appService } from '@/services/AppService'
import { state } from '@/store/state'

export default {
  name: 'OnboardingStepWelcome',

  components: {
  },

  computed: {
    cardComplete() { return this.cardNumberComplete && this.cardExpiryComplete && this.cardCvcComplete },
  },

  data() {
    return {
      state,
      isLoading: false,
      cardNumberElement: null,
      cardExpiryElement: null,
      cardCvcElement: null,
      cardNumberComplete: false,
      cardExpiryComplete: false,
      cardCvcComplete: false,
      serverError: null,
    }
  },

  mounted() {
    if (billingService.stripe) {
      // @see https://stripe.com/docs/js/elements_object/create
      const elements = billingService.stripe.elements()

      this.cardNumberElement = elements.create('cardNumber')
      this.cardExpiryElement = elements.create('cardExpiry')
      this.cardCvcElement = elements.create('cardCvc')

      this.cardNumberElement.mount('#card-number-element')
      this.cardExpiryElement.mount('#card-expiry-element')
      this.cardCvcElement.mount('#card-cvc-element')

      this.cardNumberElement.on('change', ({ complete, error }) => {
        this.cardNumberComplete = complete && !error
      })
      this.cardExpiryElement.on('change', ({ complete, error }) => {
        this.cardExpiryComplete = complete && !error
      })
      this.cardCvcElement.on('change', ({ complete, error }) => {
        this.cardCvcComplete = complete && !error
      })

    } else {
      window.logger.error('Stripe is null')
      // TODO redirect to home?
    }
  },

  destroyed() {
    if (this.cardNumberElement) { this.cardNumberElement.destroy() }
    if (this.cardExpiryElement) { this.cardExpiryElement.destroy() }
    if (this.cardCvcElement) { this.cardCvcElement.destroy() }
  },

  methods: {
    async onSubmit() {

      if (billingService.stripe && this.cardNumberElement) {
        this.isLoading = true
        this.serverError = null

        try {
          // TODO refactor: extract method from here and AddPayment.vue
          // @see https://stripe.com/docs/js/payment_methods/create_payment_method
          const result = await billingService.stripe.createPaymentMethod({
            type: 'card',
            card: this.cardNumberElement,
            billing_details: {},
          })
          window.logger.debug('createPaymentMethod', { result })
          if (result.error) {
            this.serverError = result.error.message || 'Error'
            this.isLoading = false
          }

          const id = result?.paymentMethod?.id
          if (id) {
            // @see https://stripe.com/docs/js/tokens_sources/create_token?type=cardElement
            const result = await billingService.stripe.createToken(this.cardNumberElement)
            window.logger.debug('createToken', { result })
            if (result.error) {
              this.serverError = result.error.message || 'Error'
            }

            const { error, source } = await billingService.addPaymentMethod(result.token)
            if (error || !source) {
              this.serverError = error
              window.logger.error(error)

            } else {
              await billingService.defaultPaymentMethod(source.id)
              const { error } = await billingService.loadPaymentMethods()
              if (error) {
                window.logger.error(error)
                appService.showError(error)

              } else {
                this.$emit('next', 'dataPlan')

                // // add funds
                // const addFunds = this.dataplanPrice || config.defaultBalanceAmount
                // const { error } = await billingService.addFunds(addFunds)
                // if (error) {
                //   window.logger.error(error)
                //   appService.showError(error)
                //
                // } else {
                //   if (this.dataplanId && this.dataplanPeriod) {
                //     await billingService.changeDataplan(this.dataplanId, this.dataplanPeriod)
                //   }
                //   billingService.loadBalance().catch(window.logger.error)
                //   billingService.loadHistory().catch(window.logger.error)
                //   authService.loadCompany().catch(window.logger.error)
                //   this.$emit('next', 'dataPlan')
                // }
              }
            }
            this.isLoading = false
          }

        } catch (e) {
          appService.showError()
          window.logger.error(e)
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.welcome-text
  text-align: center

.input
  margin-bottom: 8px

.row2
  display: flex
  align-items: center
  justify-content: space-between
  & > *
    width: calc(50% - 4px)

.cardInput
  border: 1px solid #aaa
  padding: 20px 24px
  margin: 8px 0 0 0
  border-radius: 4px

.a-top
  align-items: flex-start

</style>

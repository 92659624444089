<template>
  <div class="root pa-5 pb-0">
    <BillingCard :title="$t('billing.paymentMethod')">
      <template v-slot:content>
        <CardsItem
            v-for="item in state.billing.paymentMethods"
            :key="item.id"
            :item="item"
        />
      </template>
      <template v-slot:actions>
        <v-dialog
            v-model="dialog"
            max-width="640"
            @close="onClose"
        >
          <template v-slot:activator="{ on, attrs }">
            <ActionButton
                @click="onOpen"
                v-bind="attrs"
                v-on="on"
            >
              {{$t('billing.addCard')}}
            </ActionButton>
          </template>

          <v-card>
            <v-card-title class="headline">
              {{$t('billing.addCard')}}
            </v-card-title>
            <v-card-text>
              <div id="card-number-element" class="cardInput"></div>
              <div class="row2">
                <div id="card-expiry-element" class="cardInput"></div>
                <div id="card-cvc-element" class="cardInput"></div>
              </div>

              <AppError class="mt-5 mb-0" :error="serverError" />

            </v-card-text>
            <v-card-actions class="pr-6 pl-6 pb-4 a-top">
              <a href="https://www.stripe.com" target="_blank">
                <img
                    class="secure"
                    title="Stripe Secure Payments"
                    alt="Stripe"
                    :src="secure"
                />
              </a>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  text
                  @click="onClose"
              >
                {{$t('common.cancel')}}
              </v-btn>
              <v-btn
                  color="primary"
                  :disabled="!cardComplete || isLoading"
                  @click="onAddCard"
              >
                {{$t(isLoading ? 'common.loading' : 'billing.addCard')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </BillingCard>
  </div>
</template>

<script>

import { billingService } from '@/services/BillingService'
import { appService } from '@/services/AppService'
import { state } from '@/store/state'
import BillingCard from '@/components/shared/BillingCard'
import CardsItem from '@/components/views/viewBilling/cards/Item'
import secure from '@/assets/stripe.png'

export default {
  name: 'ViewBillingAddPayment',

  components: {
    BillingCard,
    CardsItem,
  },

  computed: {
    cardComplete() { return this.cardNumberComplete && this.cardExpiryComplete && this.cardCvcComplete },
  },

  data() {
    return {
      state,
      secure,
      isLoading: false,
      dialog: false,
      serverError: null,
      cardNumberElement: null,
      cardExpiryElement: null,
      cardCvcElement: null,
      cardNumberComplete: false,
      cardExpiryComplete: false,
      cardCvcComplete: false,
    }
  },

  methods: {
    onOpen() {
      setTimeout(() => {
        if (billingService.stripe) {
          // @see https://stripe.com/docs/js/elements_object/create
          const elements = billingService.stripe.elements()

          this.cardNumberElement = elements.create('cardNumber')
          this.cardExpiryElement = elements.create('cardExpiry')
          this.cardCvcElement = elements.create('cardCvc')

          this.cardNumberElement.mount('#card-number-element')
          this.cardExpiryElement.mount('#card-expiry-element')
          this.cardCvcElement.mount('#card-cvc-element')

          this.cardNumberElement.on('change', ({ complete, error }) => {
            this.cardNumberComplete = complete && !error
          })
          this.cardExpiryElement.on('change', ({ complete, error }) => {
            this.cardExpiryComplete = complete && !error
          })
          this.cardCvcElement.on('change', ({ complete, error }) => {
            this.cardCvcComplete = complete && !error
          })

        } else {
          window.logger.error('Stripe is null')
        }
      }, 300)
    },

    onClose() {
      if (this.cardNumberElement) { this.cardNumberElement.destroy() }
      if (this.cardExpiryElement) { this.cardExpiryElement.destroy() }
      if (this.cardCvcElement) { this.cardCvcElement.destroy() }
      this.dialog = false
    },

    async onAddCard() {
      if (billingService.stripe && this.cardNumberElement) {
        this.isLoading = true
        this.serverError = null
        const isFirstCard = !this.state.billing.paymentMethods.length

        try {
          // @see https://stripe.com/docs/js/payment_methods/create_payment_method
          const result = await billingService.stripe.createPaymentMethod({
            type: 'card',
            card: this.cardNumberElement,
            billing_details: {},
          })
          window.logger.debug('createPaymentMethod', { result })
          if (result.error) {
            this.serverError = result.error.message || 'Error'
            this.isLoading = false
          }

          const id = result?.paymentMethod?.id
          if (id) {
            // @see https://stripe.com/docs/js/tokens_sources/create_token?type=cardElement
            const result = await billingService.stripe.createToken(this.cardNumberElement)
            window.logger.debug('createToken', { result })
            if (result.error) {
              this.serverError = result.error.message || 'Error'
            }

            const { error } = await billingService.addPaymentMethod(result.token)
            if (error) {
              appService.showError(error)
            } else {
              if (isFirstCard) {
                // await billingService.defaultPaymentMethod(id)
              }
              const { error } = await billingService.loadPaymentMethods()
              if (error) {
                appService.showError(error)
              } else {
                appService.showSuccess()
              }
            }
            this.isLoading = false
            this.onClose()
          }
        } catch (e) {
          appService.showError()
          window.logger.error(e)
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.root
  &__main
    overflow: hidden

.cardInput
  border: 1px solid #aaa
  padding: 20px 24px
  margin: 8px 0 0 0
  border-radius: 4px

.row2
  display: flex
  align-items: center
  justify-content: space-between
  & > *
    width: calc(50% - 4px)

.a-top
  align-items: flex-start

.secure
  height: 80px
  transform: translateY(-7px)
</style>

<template>
  <AppCard>
    <AppForm class="root" @submit="onSubmit">
      <v-row>
        <v-col cols="12" sm="4" md="3" lg="3" flex>
          <div class="avatar" @click="changeAvatar">
            <v-avatar class="avatar-icon" size="160">
              <v-img v-if="!avatar" :src="DefaultAvatar" />
              <v-img v-else :src="avatar" />
            </v-avatar>
            <div class="avatar-cap">{{$t('common.upload').toUpperCase()}}</div>
              <input
                ref="inputAvatar"
                type="file"
                accept="image/png, image/jpeg"
                name="avatar"
                @change="onChangeAvatar"
                hidden
              />
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            outlined
            hide-details
            disabled
            type="text"
            :label="$t('common.email')"
            class="root__input"
            :value="state.account.email"
          />
          <v-text-field
            outlined
            hide-details
            type="text"
            :label="$t('common.firstName')"
            class="root__input"
            :error="$v.firstName.$anyError"
            v-model.trim="firstName"
            @input="setField('firstName', $event)"
          />
          <v-text-field
            outlined
            hide-details
            type="text"
            :label="$t('common.lastName')"
            class="root__input"
            :error="$v.lastName.$anyError"
            v-model.trim="lastName"
            @input="setField('lastName', $event)"
          />
          <v-text-field
            outlined
            hide-details
            :label="$t('common.newPassword')"
            type="password"
            autocomplete="new-password"
            :error="$v.password.$anyError"
            v-model.trim="password"
            class="root__input"
            @input="setField('password', $event)"
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            outlined
            hide-details
            type="text"
            :label="$t('common.accountNumber')"
            class="root__input"
            :error="$v.number.$anyError || !number"
            v-model.trim="formattedNumber"
            @input="setPhoneNumber($event)"
          />
          <v-select
            outlined
            :items="timezones"
            hide-details
            item-text="label"
            item-value="value"
            class="root__input"
            :label="$t('common.timezone')"
            :error="$v.timeZone.$anyError || !timeZone"
            v-model="timeZone"
            @input="setField('timeZone', $event)"
          ></v-select>
          <v-select
            outlined
            :items="[{ label: 'AM/PM', value: undefined }, { label: '24H', value: '24H' }]"
            hide-details
            item-text="label"
            item-value="value"
            class="root__input"
            :label="$t('common.timeformat')"
            :error="$v.timeFormat.$anyError || !timeFormat"
            v-model="timeFormat"
            @input="setField('timeFormat', $event)"
          ></v-select>
          <v-select
            outlined
            :items="numbers"
            hide-details
            item-text="label"
            item-value="value"
            class="root__input"
            :label="$t('common.callerID')"
            v-model="callerID"
            @input="setField('callerID', $event)"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" md="3" lg="3"></v-col>
        <v-col cols="12" md="4">
          <AppButton
            class="root__input root__btn"
            submit
            type="primary"
            :disabled="!$v.$anyDirty || $v.$invalid"
            :isLoading="isLoading"
          >
            {{$t('common.save')}}
          </AppButton>
        </v-col>
        <v-col cols="12" md="4">
          <AppButton
            v-if="$v.$anyDirty"
            class="reset root__input root__btn"
            type="gray"
            @click="onReset"
          >
            {{$t('common.reset')}}
          </AppButton>
        </v-col>
      </v-row>
      <AppError class="error-box" slot="bottom" :error="serverError" />
    </AppForm>
  </AppCard>
</template>

<script>
import { state } from '@/store/state'
import { config } from '@/config/config'
import { minLength, required } from 'vuelidate/lib/validators'
import { userService } from '@/services/UserService'
import { appService } from '@/services/AppService'
import { authService } from '@/services/AuthService'
import { phone, name } from '@/lib/validate'
import { createPhoneNumber } from '@/lib/utils'
import DefaultAvatar from '@/assets/avatar.png'

export default {
  name: 'ViewSettingsProfile',

  components: {
  },

  computed: {
    currentUser() { return this.state.account },
    timezones() { return this.state.timezones.map(i => ({ value: i, label: i })) },
    avatar() { return state.account.profile.avatar },
    phoneNumber() {
      const number = this.state.account.profile.number
      const formatted = this.createPhoneNumber(number)?.formatted || number
      return formatted
    },
  },

  data() {
    return {
      state,
      DefaultAvatar,
      isLoading: false,
      serverError: null,
      firstName: state.account.profile.firstName,
      lastName: state.account.profile.lastName,
      number: state.account.profile.number,
      formattedNumber: this.createPhoneNumber(state.account.profile.number)?.formatted,
      timeZone: state.account.profile.timeZone,
      timeFormat: state.account.profile.timeFormat,
      callerID: state.account.profile.callerID,
      password: '',
      maxUploadFileSize: 1024 * 1024,
      numbers: [],
    }
  },

  validations: {
    firstName: {
      name,
      required,
    },
    lastName: {
      name,
    },
    number: {
      phone,
      required,
    },
    timeZone: {
      required,
    },
    timeFormat: {
      required,
    },
    callerID: {},
    password: {
      minLength: minLength(config.minPasswordLength),
    },
  },

  async mounted() {
    this.numbers = (await userService.loadAccountNumbers())
      .map(i => ({
        label: this.createPhoneNumber(i.number)?.formatted || '',
        value: i.number,
      }))
  },

  methods: {
    createPhoneNumber,

    setField(field, value) {
      this[field] = value
      this.$v[field].$touch()
      this.serverError = null
    },

    setPhoneNumber(value) {
      const phone = this.createPhoneNumber(value)
      this.setField('number', phone?.phone?.number || value)
    },

    onReset() {
      this.firstName = this.currentUser.profile.firstName
      this.lastName = this.currentUser.profile.lastName
      this.number = this.currentUser.profile.number
      this.timeZone = this.currentUser.profile.timeZone
      this.timeFormat = this.currentUser.profile.timeFormat
      this.password = ''
      this.$v.$reset()
    },

    async onSubmit() {
      this.$v.$touch()
      if (!this.$v.$anyError) {
        this.isLoading = true
        try {
          const profile = {
            firstName: this.firstName,
            lastName: this.lastName,
            number: this.number,
            timeZone: this.timeZone,
            timeFormat: this.timeFormat,
            onboarding: '',
            callerID: this.callerID,
          }
          const { error } = await userService.update(this.currentUser.id, profile, this.password)
          if (error) {
            appService.showError(error)
          } else {
            authService.loadAccount().catch(window.logger.error)
          }

        } catch (e) {
          window.logger.error(e)
          appService.showError()
        }
        this.isLoading = false
        this.$v.$reset()
      }
    },

    async onChangeAvatar(e) {
      const form = new FormData()
      const file = e.target.files[0]

      if (file.size > this.maxUploadFileSize) {
        const size = Math.ceil(this.maxUploadFileSize / 1024 / 1024)
        return appService.showError(this.$t('common.uploadFileTooBig',{ size }), 10000)
      }

      form.append('avatar', file)
      const { error, data } = await userService.updateAvatar(form)
      if (error) {
        return appService.showError(error)
      }

      this.state.account.profile.avatar = data?.location
    },

    changeAvatar() {
      this.$refs.inputAvatar.click()
    },
  },

  watch: {
    number: function(val) {
      this.formattedNumber = this.createPhoneNumber(val)?.formatted || val
    },
  },
}
</script>

<style lang="sass" scoped>
.root
  .avatar
    position: relative
    cursor: pointer
    width: 180px
    height: 180px
    display: flex
    padding: 8px
    justify-content: center
    align-items: center
    overflow: hidden
    border: 1px solid #DAE0EB

    &-cap
      position: relative
      display: flex
      justify-content: center
      align-items: center
      opacity: 0
      transition: .5s ease
      font-size: 1.2em
      color: #999
      font-weight: 700
      background: rgba(242, 245, 250, 0.8)
      width: 100%
      height: 100%

    &-icon
      position: absolute
      transition: .5s ease

    &:hover .avatar-cap
      opacity: 1

  .avatar,
  .avatar-cap
    border-radius: 50%

  .error-box
    margin-top: 24px

.root__input
  width: 100%
  height: 50px
  margin: 30px 30px 0 0

  &.root__btn
    min-height: 50px
    margin-top: 10px
</style>

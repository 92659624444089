import { validateFields } from '@/lib/validate'
import { parseDate } from '@/lib/utils'

const initialData = {
  id: '',
  name: '',
  description: '',
  scheme: '',
  account_uid: null as null | string,
  account_profile: null as null | any,
  system: false,
  fax: false,
  transfer: false,
  meta: {},
  created_at: null as null | Date,
  updated_at: null as null | Date,
}

export type TScenario = typeof initialData

export const create = (data?: Partial<TScenario>): TScenario => {
  return { ...initialData, ...data }
}

export const map = (data: TObject): TScenario => {
  validateFields(data, [
    'id',
    'name',
    'description',
    'created_at',
    'updated_at',
  ])

  return {
    ...initialData,
    id: `${data.id}` || initialData.id,
    name: data.name || initialData.name,
    description: data.description || initialData.description,
    scheme: data.blockly_scheme || initialData.scheme,
    system: !!data.system,
    fax: !!data.fax,
    transfer: !!data.transfer,
    meta: data.meta || initialData.meta,
    account_uid: data.accoount_uid,
    account_profile: data.account_profile,
    created_at: parseDate(data.created_at),
    updated_at: parseDate(data.updated_at),
  }
}

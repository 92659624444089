import { state } from '@/store/state'
import { getItems, download, remove } from '@/api/RecordApi'
import { appService } from '@/services/AppService'

class RecordService {
  public state = state

  /**
   */
  public async loadItems(page?: number, size?: number): Promise<void> {
    this.state.records.list.isLoading = true
    if (page) { this.state.records.list.options.page = page }
    if (size) { this.state.records.list.options.itemsPerPage = size }
    try {
      const sortField = this.state.records.list.options.sortBy[0]
      const sortDir = this.state.records.list.options.sortDesc[0]
      const { error, data: { items, total } } = await getItems(
        this.state.records.list.options.page,
        this.state.records.list.options.itemsPerPage,
        sortField,
        sortDir ? 'desc' : 'asc',
        this.state.records.list.options.search,
      )
      if (error) {
        appService.showError(error)
      } else {
        this.state.records.list.items = items
        this.state.records.list.total = total
        this.state.records.list.totalPages = Math.ceil(total / this.state.records.list.options.itemsPerPage)
      }
    } catch (e) {
      window.logger.error(e)
      appService.showError()
    }
    this.state.records.list.isLoading = false
  }

  public async downloadItem(id: string): Promise<void> {
    const { data, headers } = await download(id)
    const filename = headers?.['content-disposition']
      ?.split(';')
      ?.find((i: any) => i.indexOf('filename') > -1)
      ?.split(['='])?.[1] || id
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
  }

  public async deleteItem(id: string): Promise<{error: null | string}> {
    let error = null
    this.state.records.list.isLoading = true
    try {
      const result = await remove(id)
      error = result.error
    } catch (e) {
      window.logger.error(e)
      appService.showError()
    }
    this.state.records.list.isLoading = false
    return { error }
  }

}

const recordService = new RecordService()
export { recordService, RecordService }

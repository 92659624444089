<template>
  <div>
    <v-data-table
        class="root row-hover"
        :item-key="'email'"
        :headers="headers"
        :items="state.users.list.items"
        :options.sync="state.users.list.options"
        :server-items-length="state.users.list.total"
        :no-data-text="$t('common.empty')"
        :loading="state.users.list.isLoading"
        :page.sync="state.users.list.options.page"
        hide-default-footer
    >
      <template v-slot:[`item.profile.number`]="{ item }">
        {{formatPhoneNumber(item.profile.number)}}
      </template>

      <template v-slot:[`item.admin`]="{ item }">
        <v-switch
            inset
            dense
            hide-details
            class="switch"
            color="blue"
            :disabled="item.email === currentUser.email || loading || item.id === company.created_by"
            :input-value="item && item.admin"
            @change="onToggleAdmin(item)"
        ></v-switch>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <UserStatus :user="item" />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <template v-if="item.email !== currentUser.email">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{icons.mdiDotsHorizontal}}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                  v-if="item.type === 'pending'"
                  :disabled="state.users.list.inviteSent.includes(item.email)"
                  @click="onReinvite(item)"
              >
                <v-list-item-title>{{$t('users.reinvite')}}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="onDelete(item)">
                <v-list-item-title>
                  {{$t(item.type === 'pending' ? 'users.cancelInvite' : 'common.delete')}}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </template>
    </v-data-table>

    <v-pagination
      v-model="state.users.list.options.page"
      @input="loadItems"
      :length="state.users.list.totalPages"
      :total-visible="7"
    />

    <AppDialog ref="dialog" />
  </div>
</template>

<script>

import { mdiDotsHorizontal } from '@mdi/js'
import { state } from '@/store/state'
import { userService } from '@/services/UserService'
import UserStatus from '@/components/shared/UserStatus'
import { appService } from '@/services/AppService'
import { formatPhoneNumber } from '@/lib/utils'

export default {
  name: 'ViewUsersList',

  components: {
    UserStatus,
  },

  computed: {
    currentUser() { return this.state.account },
    company() { return this.state.company },
  },

  data() {
    return {
      icons: { mdiDotsHorizontal },
      state,
      loading: false,
      headers: [
        {
          text: this.$t('common.email'),
          align: 'start',
          value: 'email',
          sortable: true,
        },
        {
          text: this.$t('common.firstName'),
          value: 'profile.firstName',
          sortable: false,
        },
        {
          text: this.$t('common.lastName'),
          value: 'profile.lastName',
          sortable: false,
        },
        {
          text: this.$t('common.personalNumber'),
          value: 'profile.number',
          sortable: false,
        },
        {
          text: this.$t('common.admin'),
          value: 'admin',
          sortable: true,
        },
        {
          text: this.$t('users.fieldStatus'),
          value: 'type',
          sortable: true,
        },
        {
          text: '',
          align: 'end',
          value: 'actions',
          sortable: false,
        },
      ],
    }
  },

  mounted() {
    this.loadItems().catch(window.logger.error)
  },

  methods: {
    formatPhoneNumber,
    async loadItems(page) {
      await userService.loadItems(page)
    },

    async onReinvite(item) {
      const { error } = await userService.invite([item.email], item.admin)
      if (!error) {
        appService.showSuccess()
        await this.loadItems()
      } else {
        appService.showError(error)
      }
    },

    async onDelete(item) {
      const doSuspend = async () => {
        try {
          const { error } = item.time === 'pending' ?
            await userService.cancelInvite(item.email) :
            await userService.suspend(item.id)
          if (!error) {
            appService.showSuccess()
            await this.loadItems()
          } else {
            appService.showError()
          }
        } catch (e) {
          window.logger.error(e)
          appService.showError()
        }
      }

      if (item.type === 'pending') {
        await doSuspend()

      } else {
        const dialogSettings = {
          title: this.$t('users.dialogDeleteTitle'),
          text: `${item.email} ${this.$t('users.dialogDeleteText')}?`,
          actionText: this.$t('common.delete'),
        }

        if (await this.$refs.dialog.show(dialogSettings)) {
          await doSuspend()
        }
      }
    },

    async onToggleAdmin(item) {
      this.loading = true
      item.admin = !item.admin
      try {
        const { error } = await userService.toggleAdmin(item.id, item.admin).catch(window.logger.error)
        if (error) {
          appService.showError(error)
          item.admin = !item.admin
        }
      } catch (e) {
        appService.showError(this.$t('common.systemError'))
        item.admin = !item.admin
        window.logger.error(e)
      }
      this.loading = false
    },
  },
}
</script>

<style lang="sass" scoped>
  .root
    .switch
      margin: 0
      padding: 0
</style>

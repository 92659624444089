<template>
  <div class="nav">
    <div class="nav__logo">
      <Logo v-if="!collapse" class="nav__logoIcon" />
      <LogoMini v-else class="nav__logoIcon" />
    </div>
    <div :class="['nav__list', collapse && 'collapsed']">
      <router-link v-for="item of allowedItems" :key="item.label" class="nav__item" :to="item.url">
        <v-icon class="nav__icon">{{item.icon}}</v-icon>
        <div v-if="!collapse" class="nav__label">{{item.label}}</div>
      </router-link>
      <MainDataplanStatus v-if="!state.isInitializing && state.account.creator" :short="collapse" />
    </div>
    <div class="nav__list m-bottom">
      <router-link v-for="item of bottomItems" :key="item.label" class="nav__item" :to="item.url">
        <span class="nav__dot"></span>
        <div class="nav__label">{{item.label}}</div>
      </router-link>
    </div>
    <template>
      <AppButton
        class="ml-5 mr-5 mb-5 mt-auto"
        v-if="!collapse"
        @click="logout()"
      >
      Logout
      </AppButton>
      <v-icon v-else class="nav__icon ml-2 mb-5 mt-auto" @click="logout()">{{icons.mdiLogoutVariant}}</v-icon>
    </template>
  </div>
</template>

<script>
import Logo from '../../assets/logo.svg'
import LogoMini from '../../assets/logo2.svg'
import * as paths from '@/config/paths'
import { state } from '@/store/state'
import { i18n } from '@/config/vue'
import { authService } from '@/services/AuthService'
import { hasAccess } from '@/lib/access'
import MainDataplanStatus from './layout/main/DataplanStatus'
import { mdiHome,
  mdiCurrencyUsd,
  mdiAccountMultiple,
  mdiFax,
  mdiPhoneLog,
  mdiTextBoxOutline,
  mdiAccount,
  mdiPostOutline,
  mdiLogoutVariant } from '@mdi/js'

export default {
  name: 'Navigation',

  components: {
    Logo,
    LogoMini,
    MainDataplanStatus,
  },

  props: {
    collapse: Boolean,
  },

  data: () => ({
    state,
    icons: { mdiLogoutVariant },
    topItems: [
      { url: paths.PATH_PAGE_HOME, label: i18n.t('navigation.dashboard'), icon: mdiHome, access: 'admin' },
      { url: paths.PATH_PAGE_NUMBERS, label: i18n.t('navigation.numbers'), icon: mdiPhoneLog },
      { url: paths.PATH_PAGE_SCENARIOS, label: i18n.t('navigation.scenarios'), icon: mdiTextBoxOutline, access: 'admin' },
      { url: paths.PATH_PAGE_USERS, label: i18n.t('navigation.users'), icon: mdiAccountMultiple, access: 'admin' },
      { url: paths.PATH_PAGE_FAXES, label: i18n.t('navigation.faxes'), icon: mdiFax },
      { url: paths.PATH_PAGE_CALLSTATS, label: i18n.t('navigation.callHistory'), icon: mdiPostOutline },
      { url: paths.PATH_PAGE_BILLING, label: i18n.t('navigation.billing'), icon: mdiCurrencyUsd, access: 'creator' },
      { url: paths.PATH_PAGE_PROFILE, label: i18n.t('navigation.settings'), icon: mdiAccount },
    ],
    bottomItems: [],
  }),

  methods: {
    logout() {
      authService.logout().catch(window.logger.error)
    },
  },

  computed: {
    allowedItems() {
      let items = this.topItems.filter(i => hasAccess(i.access))
      if (!state.account.admin) {
        const i = items.map(i => i.url).indexOf(paths.PATH_PAGE_CALLSTATS)
        if (i > -1) {
          top && items.unshift(...items.splice(i, 1))
        }
      }
      return items
    },
  },
}
</script>

<style lang="sass" scoped>
  .nav
    display: flex
    flex-direction: column
    height: 100%
    width: 100%
    border-style: none !important
    background: $default-grey
    font-weight: 500

  .nav__logo
    height: 64px
    display: flex
    align-items: center
    border-bottom: 1px solid #D3D7DE

  .nav__logoIcon
    flex: 0 0 auto
    height: 26px
    margin-left: 14px

  .nav__list
    display: flex
    flex-direction: column

    &.m-bottom
      position: absolute
      bottom: 0
      left: 0
      width: 100%

  .nav__item
    overflow: hidden
    cursor: pointer
    height: 41px
    display: flex
    align-items: center
    text-decoration: none
    color: #000

    &.router-link-exact-active
      color: var(--v-primary-base) !important
      cursor: default
      background: rgba(42, 127, 255, 0.1) !important

    &:hover, &.router-link-exact-active
      background: rgba(42, 127, 255, 0.1) !important
      color: var(--v-primary-base) !important
      .nav__icon
        color: var(--v-primary-base)

  .nav__dot
    height: 11px
    width: 11px
    background-color: var(--v-primary-base)
    border-radius: 50%
    display: inline-block
    margin: 2px 15px 0 20px
    border: 3px solid #FFF
    visibility: hidden

  .nav__item.router-link-exact-active > .nav__dot
    visibility: visible

  .nav__label
    display: flex
    align-items: center
    white-space: nowrap

  .nav__icon
    width: 40px
    color: #9E9E9E
    margin-left: 7px

  .logout
    margin-top: auto

</style>

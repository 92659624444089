<template>
  <v-app light class="app">
    <v-app-bar-nav-icon v-if="!isLarge" class="navIcon" @click="toggle" />

    <v-navigation-drawer app v-model="open" class="sideNav" :miniVariant="collapse">
      <Navigation :collapse="collapse" />
    </v-navigation-drawer>

    <v-main>
      <div class="content">
        <NavToggle
          v-if="isLarge"
          class="sideNavControl"
          :active="!collapse"
          @click="toggle"
        />
        <AppLoader v-if="state.isInitializing" />
        <div v-else :class="['inner', padding && 'pa-8']">
          <div class="topNavBar mt-4 ml-4 pr-2 pb-2">
            <span class="title">
              {{title}}
            </span>
            <slot class="controls" name="controls" />
          </div>
          <slot />
        </div>
      </div>
      <MainSnackbar />
    </v-main>

    <v-footer app inset class="footer">
      <span v-if="version">Version: {{version}}</span>
    </v-footer>
    <CookieBanner />
  </v-app>
</template>

<script>
import { storageService } from '@/services/StorageService'
import { state } from '@/store/state'
import MainDataplanStatus from './main/DataplanStatus'
import MainSnackbar from './main/Snackbar.vue'
import Navigation from '../Navigation.vue'
import NavToggle from '@/components/shared/NavToggle'
import NavLogout from '@/components/shared/NavLogout'
import CookieBanner from '@/components/shared/CookieBanner'

export default {
  name: 'LayoutMain',

  components: {
    CookieBanner,
    NavLogout,
    NavToggle,
    MainDataplanStatus,
    Navigation,
    MainSnackbar,
  },

  props: {
    padding: Boolean,
    title: String,
  },

  data() {
    return {
      state,
      icons: {},
      tablet: false,
      open: true,
      collapse: storageService.get('sideCollapsed', false),
      version: process.env.VUE_APP_VERSION,
    }
  },

  beforeMount () {
    this.tablet = !['lg', 'xl'].includes(this.$vuetify.breakpoint.name)
    this.open = !this.tablet
  },

  methods: {
    toggle() {
      if (this.isLarge) {
        this.open = true
        this.collapse = !this.collapse
      } else {
        this.collapse = false
        this.open = !this.open
      }
      storageService.set('sideCollapsed', this.collapse)
    },
  },

  computed: {
    isLarge: function () {
      return ['lg', 'xl'].includes(this.$vuetify.breakpoint.name)
    },
  },
}
</script>

<style lang="sass" scoped>
  .navIcon
    margin: 10px 0 0 10px
  .topNavBar
    display: flex
    justify-content: space-between
    border-bottom: 1px solid #DAE0EB
    min-height: 50px
    .title
      font-weight: 700
      font-size: 26px !important
      line-height: 32px
      color: #999
  .sideNav
    z-index: 101
    background-color: #FFF
    height: 100%
    widht: 70px
    display: flex
  .sideNavControl
    margin-top: 8px
  .content
    display: flex
    height: 100%
  .inner
    width: 100%
    max-width: 1400px
  .footer
    justify-content: flex-end
    font-size: .8rem !important
    z-index: 100
</style>

<template>
    <div id="root" ref="root" :style="style" tabindex="0" @keydown.esc="$emit('exit')">
      <div
        v-if="settings"
        id="dropzone-settings"
      >
        <input
          type="text"
          :placeholder="$t('scenarios.nameOrDescription')"
          ref="fileName"
          v-model="fileName"
          @keydown.enter="onUploadFile"
        />
        <uploadIcon class="submit" @click="onUploadFile" />
      </div>
      <div
        v-else
        id="dropzone-upload"
        :class="[error && 'error', uploading && 'uploading']"
        @click="triggerUpload"
      >
        <template v-if="!error && !uploading">
          <uploadIcon class="upload-icon"/>
          <span class="noselect">{{$t('common.upload').toUpperCase()}}</span>
        </template>
        <span v-else-if="error">
          {{errorMessage || $t('scenarios.uploadFileTooBig', { size: prettyBytes(maxUploadFileSize)})}}
        </span>
        <div class="progress-bar">
          <v-progress-linear
            v-if="uploading"
            :value="uploadProgress"
            color="#FCF3D8"
            rounded
          />
        </div>
      </div>
      <div v-if="loading" id="loader">
        <v-progress-circular
          :size="48"
          :width="5"
          color="#FCF3D8"
          indeterminate
        />
      </div>
      <div v-else-if="state.prompts.list.items.length" id="list">
        <item
          v-for="item of state.prompts.list.items"
          :key="item.id"
          :name="item.meta.name"
          :id="item.id"
          :ext="item.meta.ext"
          :size="item.meta.size"
          :created_at="formatDate(parseDate(item.created_at), true)"
          @deleteItem="deleteItem"
          @selectItem="selectItem"
        />
      </div>
      <div v-else id="empty-list">
        <emptyIcon id="empty-icon" />
      </div>
      <input
        name="file"
        ref="inputFile"
        type="file"
        accept="audio/mpeg, audio/wav"
        hidden
        @change="onUploadFile"
      />
    </div>
</template>

<script>
import { config } from '@/config/config'
import { state } from '@/store/state'
import { promptService } from '@/services/PromptService'
import { parseDate, formatDate } from '@/lib/utils'
import prettyBytes from 'pretty-bytes'
import Item from './Item.vue'
import emptyIcon from '@/assets/box.svg'
import uploadIcon from '@/assets/upload.svg'

export default {
  name: 'FlowSpeak',

  props: {
    extensionBlock: Object,
    top: Number,
    left: Number,
    background: {
      type: String,
      default: '#AFBF43',
    },
  },

  components: {
    Item,
    uploadIcon,
    emptyIcon,
  },

  data() {
    return {
      state,
      uploading: false,
      settings: false,
      error: false,
      errorMessage: '',
      fileName: '',
      file: null,
      maxUploadFileSize: 1024 * 1024 * 10,
      errorMessageTimeout: 3000,
      uploadProgress: 0,
    }
  },

  methods: {
    async loadItems() {
      await promptService.loadItems()
    },
    async deleteItem(id) {
      const { error } = await promptService.deleteItem(id)
      if (error) {
        this.error = true
        this.errorMessage = error
        setTimeout(() => this.clearError(), this.errorMessageTimeout)
      } else {
        await promptService.loadItems()
      }
    },

    async onUploadFile(e) {
      if (e?.target?.files?.[0]) {
        this.file = e.target.files[0]
        this.settings = true
        this.$nextTick(() => this.$refs.fileName.focus())
        return
      }
      if (!this.file) {
        return
      }
      this.settings = false
      const form = new FormData()
      if (this.file.size > this.maxUploadFileSize) {
        this.error = true
        setTimeout(() => this.clearError(), this.errorMessageTimeout)
        return
      }
      form.append('file', this.file)
      this.fileName && form.append('name', this.fileName)
      this.fileName = ''
      this.uploadProgress = 0
      this.uploading = true
      const { error } = await promptService.uploadItem(form, this.onUploadProgress)
      this.uploading = false
      if (error) {
        this.errorMessage = error
        setTimeout(() => this.clearError(), this.errorMessageTimeout)
        return
      }
      await this.loadItems()
    },
    triggerUpload() {
      if (this.uploading) {
        return
      }
      this.$refs.inputFile.click()
    },
    onUploadProgress(event) {
      if (event?.loaded && event?.total) {
        this.uploadProgress = Math.round(event.loaded * 100 / event.total)
      }
    },
    selectItem(id) {
      const url = `${config.apiUrl}/prompt/${id}`
      this.extensionBlock.loadSRC(url)
      this.$emit('exit')
    },
    clearError() {
      this.error = false
      this.errorMessage = ''
    },
    parseDate,
    formatDate,
    prettyBytes,
  },

  computed: {
    style() {
      return `
        top: ${this.top}px;
        left: ${this.left}px;
        background: ${this.background};
      `
    },
    loading() {
      return this.state.prompts.list.isLoading && !this.uploading
    },
  },

  mounted() {
    this.$refs.root.focus()
    this.loadItems()
  },
}
</script>

<style lang="sass">
$default-border: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px
$default-color: #F9F9F9

#root
  display: flex
  flex-direction: column
  align-items: center
  padding: 10px
  min-height: 170px
  max-height: 400px
  color: $default-color
  overflow: hidden
  -ms-overflow-style: none

  &:focus
    outline: none

  #dropzone
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    margin-bottom: 20px
    font-weight: 700
    width: 380px
    min-height: 58px
    padding: 5px
    border-radius: 5px
    overflow: hidden
    cursor: pointer
    transition: all 0.2s

    &-upload
      @extend #dropzone
      box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -2px 0px inset

      &:hover
        box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.2) 0px 7px 7px -3px, rgba(0, 0, 0, 0.1) 0px -1px 0px inset

      &:active,
      &.uploading
        box-shadow: $default-border

      &.uploading
        cursor: default

      &.error
        background: var(--v-error-lighten1)

      .progress-bar
        width: 80%
        height: 20%

    &-settings
      @extend #dropzone
      flex-direction: row
      gap: 10px
      cursor: default
      box-shadow: $default-border

      .submit
        opacity: .9
        cursor: pointer

      input
        font-weight: 500
        border: 2px solid #CED982
        border-radius: 5px
        padding: 5px 10px
        margin: 3px
        color: $default-color

        &:focus
         outline: none

        &::placeholder
          color: $default-color
          opacity: .4

        &::selection
          color: #DEA922
          background: #FCF3D8

  #list
    display: flex
    flex-direction: column
    align-items: center
    gap: 10px
    overflow-y: scroll
    scrollbar-width: none

    &::-webkit-scrollbar
      display: none

  #empty
    &-list
      opacity: .3
      color: $default-color
      font-weight: 700
    &-icon
      width: 38px
</style>

const initialData = {
  id: '',
  name: '',
  description: '',
  minutes: 0,
  cost: 0,
  charge: null as null | number,
  cost_did: null as null | number,
  cost_sms: null as null | number,
  cost_minute: null as null | number,
  cost_did_toll_free: null as null | number,
  cost_minute_toll_free: 0,
  calls_incoming: 0,
  duration_days: 0,
  duration_months: 0,
}

export type TDataplan = typeof initialData

export const create = (data?: Partial<TDataplan>): TDataplan => {
  return { ...initialData, ...data }
}

export const map = (data: TObject): TDataplan => {
  return {
    ...initialData,
    id: `${data.id}` || initialData.id,
    name: `${data.name}` || initialData.name,
    description: `${data.description}` || initialData.description,
    minutes: data.data && data.data.minutes || initialData.minutes,
    cost: getNullValue(data?.data?.cost, initialData.cost),
    charge: getNullValue(data?.data?.charge, initialData.charge),
    cost_did: getNullValue(data?.data?.cost_did, initialData.cost_did),
    cost_sms: getNullValue(data?.data?.cost_sms, initialData.cost_sms),
    cost_minute: getNullValue(data?.data?.cost_minute, initialData.cost_minute),
    cost_did_toll_free: getNullValue(data?.data?.cost_did_toll_free, initialData.cost_did_toll_free),
    cost_minute_toll_free: data.data && data.data.cost_minute_toll_free || initialData.cost_minute_toll_free,
    calls_incoming: data.data && data.data.calls_incoming || initialData.calls_incoming,
    duration_days: data.data && data.data.duration && data.data.duration.days || initialData.duration_days,
    duration_months: data.data && data.data.duration && data.data.duration.months || initialData.duration_months,
  }
}

function getNullValue(value: any, defValue: any) {
  return value === undefined ? defValue : value
}

<template>
  <v-row>
    <v-col :class="['pt-0 pb-0 mb-5', actions && 'rowActions']" sm="12" md="5">
      <slot />
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'AppFormRow',

  components: {
  },

  props: {
    actions: Boolean,
  },

  data() {
    return {
    }
  },
}
</script>

<style lang="sass" scoped>
.rowActions
  display: flex
  justify-content: flex-end
</style>

import { parseDate } from '@/lib/utils'
import { validateFields } from '@/lib/validate'

const initialData = {
  id: '',
  created_by: '',
  name: '',
  domain: '',
  details: {},
  default_scenario_id: '',
  created_at: null as null | Date,
  updated_at: null as null | Date,
}

export type TCompany = typeof initialData

export const create = (data?: Partial<TCompany>): TCompany => {
  return { ...initialData, details: { ...initialData.details }, ...data }
}

export const map = (data: TObject): TCompany => {
  validateFields(data, ['id', 'created_by', 'name', 'details', 'default_scenario_id'])

  return {
    ...initialData,
    id: data.id || initialData.id,
    created_by: data.created_by || initialData.created_by,
    name: data.name || initialData.name,
    domain: data.domain || initialData.domain,
    details: data.details || {},
    default_scenario_id: data.default_scenario_id,
    created_at: parseDate(data.created_at),
    updated_at: parseDate(data.updated_at),
  }
}

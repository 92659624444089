import { state } from '@/store/state'
import { invite,
  getItems,
  suspend,
  cancelInvite,
  update,
  getTimezones,
  getLanguages,
  avatar,
  getAccountNumbers } from '@/api/UserApi'
import { appService } from '@/services/AppService'
import { TAccount, TAccountNumbers, TAccountProfile } from '@/models/Account'
import { authService } from '@/services/AuthService'
import { normalizePhoneNumber } from '@/lib/utils'
import { storageService } from '@/services/StorageService'
import { billingService } from '@/services/BillingService'

class UserService {
  public state = state

  /**
   */
  public async init() {
    this.loadRefs().catch(window.logger.error)
  }

  /**
   */
  public invite = async (emails: string[], admin: boolean): Promise<{ error: null | string }> => {
    this.state.users.list.inviteSent = [...this.state.users.list.inviteSent, ...emails]
    const { error } = await invite(emails, admin)
    billingService.loadStats().catch(window.logger.error)
    return { error }
  }

  /**
   */
   public async updateAvatar(formdata: HTMLFormElement): Promise<{ error: null | string, data: TObject }> {
    return avatar(formdata)
  }

  /**
   */
  public suspend = async (id: string): Promise<{ error: null | string }> => {
    const { error } = await suspend(id)
    billingService.loadStats().catch(window.logger.error)
    return { error }
  }

  /**
   */
  public cancelInvite = async (email: string): Promise<{ error: null | string }> => {
    const { error } = await cancelInvite(email)
    billingService.loadStats().catch(window.logger.error)
    return { error }
  }

  /**
   */
  public update = async (id: string, profile: TAccountProfile, newPassword?: string): Promise<{ error: null | string }> => {
    const payload: TObject = { profile }
    if (newPassword) {
      payload.password = newPassword
    }
    payload.profile.number = normalizePhoneNumber(payload.profile.number)
    const { error } = await update(id, payload)
    if (!error) {
      this.loadRefs().catch(window.logger.error)
    }
    return { error }
  }

  /**
   */
  public updateProfile = async (profile: Partial<TAccountProfile>): Promise<{ error: null | string }> => {
    const nextProfile: TAccountProfile = {
      ...this.state.account.profile,
      ...profile,
    }

    nextProfile.number = normalizePhoneNumber(nextProfile.number)
    const { error } = await this.update(this.state.account.id, nextProfile)
    if (!error) {
      await authService.loadAccount()
    }
    return { error }
  }

  /**
   */
  public toggleAdmin = async (id: string, admin: boolean): Promise<{ error: null | string, account: TAccount }> => {
    const { error, data: { account } } = await update(id, { admin })
    return { error, account }
  }

  /**
   */
  public async loadItems(page?: number, size?: number): Promise<void> {
    this.state.users.list.isLoading = true
    if (page) { this.state.users.list.options.page = page }
    if (size) { this.state.users.list.options.itemsPerPage = size }
    try {
      const sortField = this.state.users.list.options.sortBy[0]
      const sortDir = this.state.users.list.options.sortDesc[0]
      const { error, data: { items, total } } = await getItems(
        this.state.users.list.options.page,
        this.state.users.list.options.itemsPerPage,
        sortField,
        sortDir ? 'desc' : 'asc',
        this.state.users.list.options.search,
      )
      if (error) {
        appService.showError(error)
      } else {
        this.state.users.list.items = items
        this.state.users.list.total = total
        this.state.users.list.totalPages = Math.ceil(total / this.state.users.list.options.itemsPerPage)
      }
    } catch (e) {
      window.logger.error(e)
      appService.showError()
    }
    this.state.users.list.isLoading = false
  }

  /**
   */
  public async loadTimezones(): Promise<void> {
    const key = 'timezones'
    let timezones: string[] = storageService.get(key, []) || []
    if (!timezones || !timezones.length) {
      const { data, error } = await getTimezones()
      if (error) {
        window.logger.error(error)
        appService.showError(error)
      } else {
        timezones = data.timezones
        storageService.set(key, timezones)
      }
    }
    this.state.timezones = timezones
    this.state.timezonesLoaded = true
  }

  /**
   */
  public async loadLanguages(): Promise<void> {
    const key = 'languages'
    let languages: string[] = storageService.get(key, []) || []
    if (!languages || !languages.length) {
      const { data, error } = await getLanguages()
      if (error) {
        window.logger.error(error)
        appService.showError(error)
      } else {
        languages = data.languages
        storageService.set(key, languages)
      }
    }
    this.state.languages = languages
    this.state.languagesLoaded = true
  }

  /**
   */
  public async loadAccountNumbers(): Promise<TAccountNumbers[]> {
    let numbers: TAccountNumbers[] = []
    const { data, error } = await getAccountNumbers()
    if (error) {
      window.logger.error(error)
      appService.showError(error)
    } else {
      numbers = data.numbers
    }
    return numbers
  }

  /**
   */
  private async loadRefs(): Promise<void> {
    const { data: { items } } = await getItems(1, 100)
    if (items) {
      this.state.users.refs = items
    }
  }

  /**
   */
  public getUserFullName(profile:Partial<TAccountProfile>): string {
    if (profile) {
      return `${profile.firstName || ''} ${profile.lastName || ''}`.trim()
    }
    return ''
  }

}

const userService = new UserService()
export { userService, UserService }

<template>
  <div class="root">
    <div class="badge">
      <v-avatar class="avatar">
        <v-img v-if="!avatar" :src="DefaultAvatar" />
        <v-img v-else :src="avatar" />
      </v-avatar>
      <span :class="['chip', admin && 'admin']">
        {{admin ? $t('common.adminShort') : $t('common.user')}}
      </span>
    </div>
    <div class="content">
      <span class="title">{{username}}</span>
      <span class="subtitle">{{email}}</span>
      <span class="footer">{{number}}</span>
    </div>
  </div>
</template>

<script>
import DefaultAvatar from '@/assets/avatar.png'

export default {
  name: 'WidgetUserCard',

  components: {
  },

  props: {
    firstName: { type: String, required: true },
    lastName: { type: String, required: true },
    email: { type: String, required: true },
    number: { type: String, required: true },
    admin: { type: Boolean, required: true, default: false },
    avatar: { type: String },
  },

  data() {
    return {
      DefaultAvatar,
    }
  },

  computed: {
    initials: function() {
      return `${this.firstName[0]}${this.lastName[0]}`
    },
    username: function() {
      return `${this.firstName} ${this.lastName}`
    },
  },
}
</script>

<style lang="sass" scoped>
.root
  min-width: 250px
  height: 90px
  display: flex
  flex-direction: row !important
  background: #FFFFFF
  border: 1px solid #DAE0EB
  border-radius: 3px
  color: #000
  overflow: hidden

  .badge
    display: flex
    flex-direction: column
    justify-content: space-between
    margin-right: 10px

    .avatar
      width: 46px
      height: 46px
      margin: 10px 18px 10px 10px
      color: #FFF

    .chip
      text-align: center
      background: #BCC3D1
      color: #FFF
      font-size: 12px
      font-weight: 600 !important
      border-radius: 0px 3px !important
      width: 62px
      height: 24px
      position: relative

      &.admin
        background: var(--v-anchor-base)

  .content
    display: flex
    flex-direction: column

    .subtitle
      font-style: italic
      font-size: 0.7rem
      line-height: 5px
      color: #999

    .footer
      margin-top: 30px
      font-size: 0.8rem
</style>
